import { Drawer, Spin } from "antd"
import { useGetCertificate } from "../network/useMedia"

export const CertificateDrawer = ({ visible, onClose, enrolment, isLoadingEnrolment = false }) => {
  const certificateQuery = useGetCertificate({ enrolmentUid: visible ? enrolment?.uid : false })

  return (
    <Drawer
      width={window.innerWidth > 900 ? 800 : window.innerWidth}
      visible={visible}
      onClose={(e) => {
        e.preventDefault()
        onClose()
      }}
      title="Certificate"
      placement="right"
    >
      {certificateQuery.isLoading ? (
        <Spin />
      ) : (
        <embed
          title="Completion Certificate"
          style={{ width: "100%", height: "100%" }}
          src={"data:application/pdf;base64," + certificateQuery.data}
          alt="certificate base 64 url"
        />
      )}
    </Drawer>
  )
}

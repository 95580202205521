import { Card, Col, ConfigProvider, Row, Skeleton, Space, Spin } from "antd"
import React, { useEffect } from "react"
import { useGetPerson } from "../network/usePerson"
import { Container } from "./Container"

export function ThemeProvider({ children }) {
  const IWIN_PRIMARY_COLOR = "#294F6D"
  const personQuery = useGetPerson({
    authenticated: !window.location.pathname.includes("auth"),
  })

  useEffect(() => {
    if (!personQuery.isLoading && personQuery.data) {
      const favEl = document.getElementById("favicon")
      if (personQuery?.data?.company?.imageUrl && favEl.href !== personQuery?.data?.company?.imageUrl) {
        favEl.href = personQuery?.data?.company?.imageUrl
      }
      if (
        personQuery.data?.company?.configuration?.products &&
        Object.prototype.hasOwnProperty.call(personQuery.data?.company?.configuration?.products, window.location.origin)
      ) {
        ConfigProvider.config({
          theme: {
            primaryColor:
              personQuery.data?.company?.configuration?.products?.[window.location.origin]?.primaryColour ||
              IWIN_PRIMARY_COLOR,
          },
        })
      } else {
        ConfigProvider.config({
          theme: {
            primaryColor: personQuery.data?.company?.configuration?.primaryColour || IWIN_PRIMARY_COLOR,
          },
        })
      }
    }
  }, [personQuery.isLoading, personQuery.data])

  return <>{children}</>
}

import { Card, Col, Row, Skeleton, Tabs } from "antd"
import { useMemo, useState } from "react"
import { Route, Switch, useParams } from "react-router-dom"
import { Container } from "../../components/Container"
import { TeamActivityPane } from "../../components/TeamActivityPane"
import { TeamEnrolmentsPane } from "../../components/TeamEnrolmentsPane"
import { TeamForumPane } from "../../components/TeamForumPane"
import { TeamLeaderboardPane } from "../../components/TeamLeaderboardPane"
import { TeamPeoplePane } from "../../components/TeamPeoplePane"
import { tabFactory } from "../../helpers/tabsFactory"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { useGetPerson } from "../../network/usePerson"
import { useGetSingleTeam } from "../../network/useTeams"
import { TeamBooking } from "./TeamBooking"
import { TeamCommunication } from "./TeamCommunication"
import { TeamEnrolmentModal } from "./TeamEnrolmentModal"
import { TeamForumPost } from "./TeamForumPost"
import { TeamMemberModal } from "./TeamMemberModal"
import { useIsThungela } from "../../hooks/useIsThungela"
import { useTranslation } from "react-i18next"

export function Team() {
  const { teamUid } = useParams()
  const { width } = useWindowDimensions()
  const { t } = useTranslation()

  const teamQuery = useGetSingleTeam({ teamUid })
  const personQuery = useGetPerson({ authenticated: true })
  const isThungela = useIsThungela(personQuery?.data?.company?.tenantId)

  const amIAdmin = useMemo(() => {
    if (teamQuery.data && !teamQuery.isLoading && personQuery.data && !personQuery.isLoading) {
      return teamQuery.data?.admin?.companyPersonUid === personQuery?.data?.companyPerson?.uid
    }
    return false
  }, [teamQuery.data, teamQuery.isLoading, personQuery.data, personQuery.isLoading])

  return (
    <>
      {!teamQuery.isLoading && teamQuery.data ? (
        <>
          <Container style={{ marginTop: "6rem" }}>
            <Row gutter={32}>
              <Col xs={24}>
                <Tabs
                  defaultActiveKey={tabFactory().get() || "enrolments"}
                  onChange={(keyValue) => tabFactory().onChange(keyValue)}
                >
                  {!isThungela && (
                    <Tabs.TabPane tab={t("Forum")} key={"forum"}>
                      <TeamForumPane team={teamQuery.data} />
                    </Tabs.TabPane>
                  )}
                  {amIAdmin && width > 992 ? (
                    <Tabs.TabPane tab={isThungela ? "Training" : t("Enrolments")} key="enrolments">
                      <TeamEnrolmentsPane team={teamQuery?.data} />
                    </Tabs.TabPane>
                  ) : null}

                  {!isThungela && (
                    <Tabs.TabPane tab={t("Leaderboard")} key="leaderboard">
                      <TeamLeaderboardPane team={teamQuery?.data} isAdmin={amIAdmin} />
                    </Tabs.TabPane>
                  )}
                  {/* {!isThungela && (
                    <Tabs.TabPane tab={t("team.tabs.people.people")} key="2">
                      <TeamPeoplePane team={teamQuery?.data} isAdmin={amIAdmin} />
                    </Tabs.TabPane>
                  )} */}
                  <Tabs.TabPane tab={t("Activity")} key="3">
                    <TeamActivityPane team={teamQuery?.data} isAdmin={amIAdmin} />
                  </Tabs.TabPane>
                </Tabs>
              </Col>
            </Row>
          </Container>
          <Switch>
            <Route
              exact
              path="/teams/:teamUid/enrolments/:enrolmentUid/person/:personUid"
              component={TeamEnrolmentModal}
            />
            <Route exact path="/teams/:teamUid/people/:personUid" component={TeamMemberModal} />
            <Route exact path="/teams/:teamUid/booking" component={TeamBooking} />
            <Route exact path="/teams/:teamUid/communication" component={TeamCommunication} />
            <Route exact path="/teams/:teamUid/posts/:postId" component={TeamForumPost} />
          </Switch>
        </>
      ) : (
        <>
          <Skeleton.Input style={{ width: window.width, height: "45vh" }} active />
          <Container style={{ marginTop: "2.5rem" }}>
            <Row gutter="32">
              {[1, 1, 1].map((_, i) => (
                <Col xs={24} md={12} lg={8} key={i}>
                  <Card size="small" bordered={false} className="smallCard">
                    <Skeleton active />
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
    </>
  )
}

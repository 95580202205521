import { sortEnrolments } from "../hooks/sortEnrolments"

function topicSorter(enrolments, value) {
  if (!value) {
    return enrolments
  }
  const sortedEnrolments = enrolments?.filter((x) => {
    return x?.publication?.topics?.includes(value)
  })

  return sortedEnrolments
}

export function learningReducer(state, action) {
  const { type, field, payload } = action
  switch (type) {
    case "initialize": {
      return {
        ...state,
        ...payload,
        initialized: true,
      }
    }
    case "sort": {
      const filteredData = sortEnrolments(
        state.enrolments,
        payload.by,
        state.sort.by === payload.by && payload.orderIdx < 1
          ? state.sort.orderIdx + 1
          : 0
      )
      return {
        ...state,
        sort: {
          by:
            state.sort.by === payload.by && payload.orderIdx < 1
              ? payload.by
              : state.sort.by !== payload.by
              ? payload.by
              : null,
          orderIdx:
            state.sort.by === payload.by && payload.orderIdx < 1
              ? state.sort.orderIdx + 1
              : 0,
        },
        enrolments: filteredData,
      }
    }
    case "topic-sort": {
      return {
        ...state,
        enrolments: topicSorter(state?.initialData, payload),
      }
    }
    case "page": {
      let offset = state?.filters?.limit * payload - state?.filters?.limit
      if (state?.total < offset) {
        offset -= offset - state?.total
      }
      return {
        ...state,
        page: payload,
        filters: {
          ...state.filters,
          offset,
        },
      }
    }
    case "search": {
      return {
        ...state,
        filters: {
          offset: 0,
          limit: 10,
          q: payload,
        },
      }
    }
    case "clear": {
      return {
        ...state,
        sort: {
          by: null,
          orderIdx: 0,
        },
      }
    }
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    case "batch": {
      return {
        ...state,
        ...payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

import { useAuthSignOut } from "@react-query-firebase/auth"
import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { auth } from "../firebase"

export const getPerson = async (queryClient) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/people/me`, {
      headers: {
        Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
        "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
      },
    })
    .then((res) => res.data.data)

export const useGetPerson = ({ authenticated }) => {
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const queryClient = useQueryClient()
  return useQuery("me", () => getPerson(queryClient), {
    staleTime: 1000 * 60 * 30,
    enabled: !!authenticated,
    retry: false,
    onError: async (error) => {
      message.error(`Failed to fetch person: ${error.response?.data?.message}`)
      window.localStorage.clear()
      window.sessionStorage.clear()
      await signOutMutation()
    },
  })
}
export const usePersonMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .put(`${process.env.REACT_APP_API_URL}/people/me`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      onSuccess: () => queryClient.invalidateQueries("me"),
      onError: (error) => {
        message.error(`Failed to update person: ${error.response?.data?.message}`)
      },
    }
  )
}
export const useGetPersonCounts = ({ personUid, key }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["person-counts", key],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/people/${personUid}/count/${key}`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      retry: false,
      enabled: !!personUid,
      staleTime: 1000 * 60 * 30,
    }
  )
}

export const useSigninMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/signin`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("me")
        // message.success('Successfully signed in')
      },
      onError: (error) => {
        message.error(`Failed to sign in: ${error.response.data.message}`)
      },
    }
  )
}
export const useLinkWithMobileMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/linkWithMobile`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("me")
        // message.success('Successfully signed in')
      },
      onError: (error) => {
        message.error(`Failed to sign in: ${error?.response?.data?.message || error?.message}`)
      },
    }
  )
}
export const useLinkWithIdentificationNumber = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/linkWithIdentificationNumber`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("me")
        // message.success('Successfully signed in')
      },
      onError: (error) => {
        message.error(`Failed to sign in: ${error?.response?.data?.message || error?.message}`)
      },
    }
  )
}
export const useLinkWithEmailMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/linkWithEmail`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("me")
        // message.success('Successfully signed in')
      },
      onError: (error) => {
        message.error(`Failed to sign in: ${error.response.data.message}`)
      },
    }
  )
}
export const useSelfRegisterWithEmailAndPassword = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/signupWithEmail`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("me")
        // message.success('Successfully signed in')
      },
      onError: (error) => {
        message.error(`Failed to register in: ${error.response.data.message}`)
      },
    }
  )
}

export const usePreAuthMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/auth/signinWithAd`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onError: (error) => {
        message.error(`Failed to run pre-auth: ${error.response.data.message}`)
      },
    }
  )
}

export const useGetPeople = ({ companyUid, filters }) => {
  let params = {}

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key],
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()
  const queryClient = useQueryClient()
  return useQuery(
    ["people", params],
    async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/companies/${companyUid}/people${urlParams.length ? `?${urlParams}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
              "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            },
          }
        )
        .then((res) => res.data.data),
    {
      enabled: !!(companyUid && filters.q),
      staleTime: 1000 * 60 * 30,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch people: ${error.response?.data?.message}`)
      },
    }
  )
}

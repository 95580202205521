import React, { useEffect, useMemo, useState } from "react"
import { Button, Layout, Space, Tooltip, Typography } from "antd"
import { Link, useParams, useHistory } from "react-router-dom"
import { BarsOutlined, ProjectOutlined } from "@ant-design/icons"
import { Cards } from "./Cards"
import { Page } from "./Page"
import { Scorm } from "./Scorm"
import { useQueryParam } from "../../../../hooks/useQueryParam"
import { useLesson } from "../../../../hooks/lesson-context"
import dayjs from "dayjs"
import { useQueryClient } from "react-query"
import { Pdf } from "./Pdf"
import { useTranslation } from "react-i18next"

const { Content } = Layout

export function Lessons({ moduleQuery, lessonQuery, enrolmentQuery }) {
  const { enrolmentUid, moduleUid, enrolmentType } = useParams()
  const { dispatch } = useLesson()
  const [actions, setActions] = useState([])
  const queryParam = useQueryParam()
  const isAdmin = queryParam.get("isAdmin")
  const isFacilitate = queryParam.get("facilitate")
  const adminUrl = queryParam.get("adminUrl")
  const { push } = useHistory()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  function getModuleReturnUrl(publication) {
    if (publication?.type === "program") {
      const section = publication.material.find((m) => m.modules.find((m) => m.uid === moduleUid))
      return `/${enrolmentType}/${enrolmentUid}?moduleUid=${moduleUid}&sectionUid=${section?.uid}`
    } else {
      return `/${enrolmentType}/${enrolmentUid}?moduleUid=${moduleUid}`
    }
  }

  const isPreviewing = useMemo(() => {
    if (isAdmin === "true" || isFacilitate === "true") return true
    const { activity } = enrolmentQuery?.data || { activity: [] }
    const lessonUid = lessonQuery?.data?.current?.uid || ""

    return (
      enrolmentQuery?.data?.currentState === "completed" ||
      activity.some(({ typeUid, status }) => typeUid === lessonUid && status === "completed")
    )
  }, [enrolmentQuery, lessonQuery])

  useEffect(() => {
    const activity = enrolmentQuery?.data?.activity ?? []

    const uidsOfLessonsInModule = moduleQuery?.data?.learning?.map((x) => x?.uid)
    const allLessonUidsExistInActivty = uidsOfLessonsInModule.every((uid) =>
      activity.some((item) => item.typeUid === uid && item.status === "completed")
    )
    const isModuleCompleted = !!activity?.find((x) => x?.typeUid === moduleUid && x?.status === "completed")
    if (!isModuleCompleted && allLessonUidsExistInActivty) {
      dispatch({
        type: "field",
        field: "actionToTrack",
        payload: {
          publicationEnrolmentUid: enrolmentQuery?.data?.uid,
          typeUid: moduleUid,
          type: "module",
          status: "completed",
          context: {
            completedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            badgeUid: moduleQuery?.data?.badge?.uid,
          },
        },
      })
    }
  }, [moduleQuery?.data])

  return (
    <Layout style={{ background: "#f8f8f881", position: "relative" }}>
      <header id="lesson-header" className="leasson__header hide--onMobile">
        <div className="content">
          <Space size={0} direction="vertical">
            <p className="badge--meta"> {lessonQuery?.data?.current?.kind}</p>
            <Typography.Title
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: <Typography.Link style={{ fontSize: "1rem" }}>More</Typography.Link>,
              }}
              style={{ margin: 0 }}
              level={3}
            >
              {lessonQuery?.data?.current?.title}
            </Typography.Title>
          </Space>
          <Space>
            {actions?.map((action) => {
              return React.cloneElement(action)
            })}
            {isPreviewing && adminUrl ? (
              <a target="_blank" href={adminUrl}>
                <Button type="link">{isPreviewing ? t("endPreview") : t("endLearning")}</Button>
              </a>
            ) : (
              <Link to={"/"}>
                <Button type="link">{isPreviewing ? t("endPreview") : t("endLearning")}</Button>
              </Link>
            )}
            {isAdmin !== "true" ? (
              <>
                <Tooltip placement="bottom" title={t("backToModule")}>
                  <Link to={() => getModuleReturnUrl(enrolmentQuery?.data?.publication)}>
                    <Button icon={<BarsOutlined />} />
                  </Link>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={enrolmentType === "courses" ? t("backToCourse") : t("backToProgram")}
                >
                  <Button
                    onClick={() => {
                      queryClient.invalidateQueries()
                      push(`/${enrolmentType}/${enrolmentUid}`)
                    }}
                    type="primary"
                    icon={<ProjectOutlined />}
                  />
                </Tooltip>
              </>
            ) : null}
          </Space>
        </div>
      </header>
      <Content className={`lesson__container lesson__container--${lessonQuery?.data?.current?.type}`}>
        {(() => {
          switch (lessonQuery?.data?.current?.type) {
            case "card":
              return (
                <Cards
                  isPreviewing={isPreviewing}
                  materialAsJSON={lessonQuery?.data?.current?.materialAsJSON}
                  lessonQuery={lessonQuery}
                  moduleQuery={moduleQuery}
                />
              )
            case "page":
              // return <Page />
              return (
                <Page
                  isPreviewing={isPreviewing}
                  materialAsHTML={lessonQuery?.data?.current?.materialAsHTML}
                  lessonQuery={lessonQuery}
                  moduleQuery={moduleQuery}
                />
              )
            case "scorm":
              return (
                <Scorm
                  isPreviewing={isPreviewing}
                  materialAsJSON={lessonQuery?.data?.current?.materialAsJSON}
                  lessonQuery={lessonQuery}
                  moduleQuery={moduleQuery}
                  setActions={setActions}
                  enrolmentQuery={enrolmentQuery}
                  activity={enrolmentQuery?.data?.activity}
                />
              )
            case "pdf":
              return (
                <Pdf
                  isPreviewing={isPreviewing}
                  materialAsJSON={lessonQuery?.data?.current?.materialAsJSON}
                  lessonQuery={lessonQuery}
                  moduleQuery={moduleQuery}
                />
              )
            default:
              return <div>Unsupported lesson type</div>
          }
        })()}
      </Content>
    </Layout>
  )
}

import { Button, Card, Divider, Layout, message, PageHeader, Result, Skeleton, Space, Spin, Typography } from "antd"
import { Footer } from "antd/lib/layout/layout"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"
import { useQueryClient } from "react-query"
import { SmallCard } from "../../../components/cards/SmallCard"
import { useScormMessenger } from "../../../hooks/useScormMessenger"
import { useCompleteEnrolmentMutation, useGetEnrolment } from "../../../network/useEnrolment"
import { useGetPerson } from "../../../network/usePerson"
import { useEnrolmentActivtyMutation } from "../../../network/useEnrolmentActivity"

const { Sider, Content } = Layout

export function ExternalLearning() {
  const [simulateLoading, setSimulateLoading] = useState(true)
  const { externalUid } = useParams()
  const { push } = useHistory()

  const enrolmentQuery = useGetEnrolment({ uid: externalUid })
  const personQuery = useGetPerson({ authenticated: true })
  const { terminated, isMounted } = useScormMessenger(
    externalUid,
    personQuery.data,
    enrolmentQuery?.data?.activity || [],
    enrolmentQuery?.data?.publication?.material[0],
    enrolmentQuery?.data?.publication?.material[0]?.uid
  )
  useEffect(() => {
    if (terminated) {
      async function complete() {
        setLoading(true)
        try {
          await activityMutation.mutateAsync({
            publicationEnrolmentUid: externalUid,
            data: {
              typeUid: externalUid,
              type: "external",
              status: "completed",
            },
          })
          await completeEnrolmentMutation?.mutateAsync({
            enrolmentUid: externalUid,
            data: {
              currentState: "completed",
              completedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            },
          })
          setLoading(false)
          queryClient?.invalidateQueries("enrolments")
          queryClient?.invalidateQueries(externalUid)
        } catch (error) {
          message?.error("Something went wrong...")
          setLoading(false)
        }
      }
      complete()
    }
  }, [terminated])
  const completeEnrolmentMutation = useCompleteEnrolmentMutation(externalUid)
  const activityMutation = useEnrolmentActivtyMutation()
  const queryClient = useQueryClient()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setSimulateLoading(false)
    }, 7000)
  }, [])

  return (
    <Layout style={{ minHeight: "100vh", background: "#f8f8f881" }}>
      <Content className="lesson__container lesson__container--scormFull">
        {!enrolmentQuery?.isLoading ? (
          <>
            {enrolmentQuery?.data?.publication?.material[0] &&
            enrolmentQuery?.data?.publication?.material[0]?.url &&
            enrolmentQuery?.data?.publication?.material[0]?.url !== "" &&
            isMounted ? (
              <>
                {simulateLoading && (
                  <Result
                    style={{ width: "100vw", height: "100vh", zIndex: 10 }}
                    icon={<Spin />}
                    title="Loading lesson"
                  />
                )}
                <iframe
                  id="scormPlayer"
                  src={`${enrolmentQuery?.data?.publication?.material[0]?.url}`}
                  frameBorder="0"
                  title={enrolmentQuery?.data?.publication?.translations["1"]?.title}
                  name="scormPlayer"
                />
              </>
            ) : (
              "There is no SCORM Url associated to this learning."
            )}
          </>
        ) : (
          <Skeleton />
        )}

        {terminated && (
          <Footer style={{ background: "none" }} className="lesson__footer">
            <Card
              className="lesson__button lesson__button--next"
              bordered
              hoverable
              onClick={async () => {
                if (!completeEnrolmentMutation.isLoading && !activityMutation.isLoading) {
                  push(`/externals/${externalUid}`)
                }
              }}
            >
              <Space size={16}>
                <Button loading={loading} type="primary" shape="circle" className="icon" icon={<RightOutlined />} />
                <Space size={0} direction="vertical" align="start" className="button__content">
                  <Typography.Text className="title" strong>
                    Complete Scorm!
                  </Typography.Text>
                  <Typography.Text className="description" style={{ color: "white" }}>
                    And continue learning
                  </Typography.Text>
                </Space>
              </Space>
            </Card>
          </Footer>
        )}
      </Content>
    </Layout>
  )
}

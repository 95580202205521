import React from "react"

export function ChatPolicy({ title, message }) {
  return (
    <div className="policy">
      <p className="title">{title}</p>
      <p className="policy-message">{message}</p>
    </div>
  )
}

import React from "react"
const Item = ({ id, item, dragOverlay }) => {
  const style = {
    cursor: dragOverlay ? "grabbing" : "grab",
  }

  return (
    <div style={style} className="item">
      {item?.translations?.["1"]?.title || item?.title}
    </div>
  )
}

export default Item

import React, { useState } from "react"
import { Badge, Card, Col, Row, Space, Tag, Typography, Tooltip, Button } from "antd"
import { DownloadOutlined, LockOutlined } from "@ant-design/icons"
import { ConditionalLink } from "../ConditionalLink"
import { generateKey } from "../../hooks/generateKey"
import { EnrolmentActionButton } from "../EnrolmentActionButton"
import { TagFactory } from "../TagFactory"
import { CertificateDrawer } from "../CertificateDrawer"

export function SmallCard({
  title,
  url,
  desc,
  topics = [],
  meta1,
  meta2,
  href,
  progress,
  certificate = null,
  bordered = false,
  loading = false,
  locked,
  rows = 1,
  course,
  showCertificate = false,
  ...rest
}) {
  const [visible, setVisible] = useState(false)
  const handleTopics = (tagsList) => {
    if (locked) {
      return [{ value: "Locked", color: "orange", icon: <LockOutlined /> }, ...tagsList]
    }
    return topics
  }

  return (
    <>
      <CertificateDrawer visible={visible} onClose={() => setVisible(false)} enrolment={certificate} />
      <ConditionalLink to={href} condition={href}>
        <Card
          loading={loading}
          size="small"
          bordered={bordered}
          {...rest}
          className={`smallCard ${locked && "smallCard--locked"}`}
        >
          <Row gutter={["16"]}>
            <Col xs={24}>
              <div
                className="smallCard__image bg--boilerplate"
                style={{
                  backgroundImage: url
                    ? `url('${url}')`
                    : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
                }}
              >
                {locked ? <LockOutlined className="lock" /> : null}
                {progress && progress > 0 ? (
                  <div className="progress">
                    <Badge count={`${progress}%`} style={{ backgroundColor: "#52c41a" }} className="percentageNum" />
                    <div className="percentage" style={{ width: `${progress}%` }} />
                  </div>
                ) : null}
              </div>
            </Col>
            <Col xs={24} className="content">
              <Space style={{ marginBottom: "0.625rem" }}>
                <Space style={{ marginBottom: "0.625rem" }}>
                  {handleTopics(topics)
                    ?.slice(0, 2)
                    ?.map((topic) => (
                      <TagFactory
                        key={generateKey(typeof topic === "string" ? topic : topic.value)}
                        icon={topic?.icon}
                        color={topic?.status ? null : topic?.color || "green"}
                        status={topic?.status}
                      >
                        {typeof topic === "string" ? topic : topic.value}
                      </TagFactory>
                    ))}
                  <p className="badge--meta">{topics?.length > 2 && `+ ${topics?.length - 2}`}</p>
                </Space>
              </Space>
              <Typography.Title level={4} ellipsis={{ rows }}>
                {title || "No Title Given"}
              </Typography.Title>
              {desc && (
                <Typography.Paragraph
                  style={{ width: "70%", overflow: "hidden" }}
                  type="secondary"
                  className="ellipses--two"
                >
                  {desc}
                </Typography.Paragraph>
              )}
              <Space size={16}>
                {meta1 && <Badge color="green" text={<span className="meta">{meta1}</span>} />}
                {meta2 && <Badge color="purple" text={<span className="meta">{meta2}</span>} />}
              </Space>
            </Col>
            {course?.continue && course?.continue.lessonUid && (
              <EnrolmentActionButton size="small" enrolment={course} />
            )}
          </Row>
        </Card>
      </ConditionalLink>
    </>
  )
}

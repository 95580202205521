import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useQuery, useQueryClient } from "react-query"

export const fetchMaterial = async (uid, queryClient) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/learn/enrolments/${uid}/material`, {
      headers: {
        Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
        "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
      },
    })
    .then((res) => res.data.data)

export const useGetMaterial = ({ enrolmentUid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    [enrolmentUid, "material"],
    () => fetchMaterial(enrolmentUid, queryClient),
    {
      enabled: !!enrolmentUid,
      staleTime: 1000 * 60 * 15,
      onError: (error) => {
        message.error(
          `Failed to fetch material: ${error.response.data.message}`
        )
      },
    }
  )
}

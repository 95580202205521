import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useQuery, useQueryClient } from "react-query"

export const fetchModule = async (enrolmentUid, moduleUid, queryClient, urlParams) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/learn/enrolments/${enrolmentUid}/modules/${moduleUid}${
        urlParams && urlParams.length ? `?${urlParams}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
        },
      }
    )
    .then((res) => res.data.data)

export const useGetModule = ({ enrolmentUid, moduleUid, filters }) => {
  let params = {}

  if (window.localStorage.getItem("classroom")) {
    params = {
      ...params,
      classroom: true,
    }
  }

  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = {
          ...params,
          [key]: filters[key],
        }
      }
    })
  }

  const urlParams = new URLSearchParams(params).toString()
  const queryClient = useQueryClient()
  return useQuery(
    [enrolmentUid, "module", moduleUid],
    () => fetchModule(enrolmentUid, moduleUid, queryClient, urlParams),
    {
      enabled: !!(enrolmentUid && moduleUid),
      staleTime: 1000 * 60 * 15,
      onError: (error) => {
        message.error(`Failed to fetch module: ${error.response.data.message}`)
      },
    }
  )
}

import { Modal, Tabs } from "antd"
import React from "react"

export function SupportModal({ visible, setVisible, support }) {
  return (
    <Modal
      visible={visible}
      cancelText="Cancel"
      onCancel={() => setVisible(false)}
      footer={null}
      width="55%"
      className="modal"
    >
      <Tabs defaultActiveKey="0">
        {support?.map((type, i) => (
          <Tabs.TabPane tab={type.name} key={i}>
            <iframe
              title="support form"
              src={type.url}
              width="100%"
              style={{
                border: 0,
                height: "82vh",
                boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)",
              }}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Modal>
  )
}

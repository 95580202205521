import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

import Item from "./Item"

const SortableItem = ({ id, item }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  return (
    <p
      className="missing__opt"
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <Item id={id} item={item} />
    </p>
  )
}

export default SortableItem

import { useDroppable } from "@dnd-kit/core"
import {
  horizontalListSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import React from "react"
import SortableItem from "./SortableItem"

export const OrderCorrectlyDroppable = ({
  id,
  items,
  className,
  vertical = false,
}) => {
  const combinedClassName = `dropzone ${className || ""} ${
    items?.length === 0 ? "empty" : ""
  }`

  const { setNodeRef } = useDroppable({
    id,
  })
  return (
    <SortableContext
      id={id}
      items={items}
      strategy={
        vertical ? verticalListSortingStrategy : horizontalListSortingStrategy
      }
    >
      <div className={combinedClassName} ref={setNodeRef}>
        {items.map((item, i) => (
          <SortableItem item={item} key={`${i}-${item?.uid}`} id={item} />
        ))}
      </div>
    </SortableContext>
  )
}

import { Button, Card, Carousel, message, Row, Space, Typography } from "antd"
import { Footer } from "antd/lib/layout/layout"
import React, { useReducer, useEffect, useState, useMemo } from "react"
import { RightOutlined, LeftOutlined } from "@ant-design/icons"
import { LessonCard } from "../../../../components/cards/LessonCard"
import { useEnrolmentActivtyMutation } from "../../../../network/useEnrolmentActivity"
import useWindowDimensions from "../../../../hooks/useWindowDimensions"
import { useLesson, trackAction } from "../../../../hooks/lesson-context"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

const handleCardArrangement = (cards = [], width) => {
  /**
   * Cards need to be dynamically rendered
   * ie. Two portrait cards can be rendered together
   * Landscape cards rendered alone
   * And one card at a time on mobile
   * The grouping of cards will be handled in the following way:
   * [['Portrait','Portrait'], ['Landscapte'], ['Portrait','Portrait']]
   *
   * Cards will be grouped into arrays inside an array
   * Thus, navigating back and forth is handled by an index (without having to compute the cards that are needing to be rendered)
   */

  let cardsArr = []
  const arrToReturn = []
  if (!cards?.length) return []

  if (width <= 992) return cards.map((card) => [card])
  cards.forEach((card, i) => {
    if (card.orientation === "portrait") {
      cardsArr.push(card)
      const nextCard = cards[i + 1]
      if (nextCard && nextCard.orientation === "landscape") {
        arrToReturn.push(cardsArr)
        cardsArr = []
      }
      if (cardsArr.length === 2) {
        arrToReturn.push(cardsArr)
        cardsArr = []
      }
      if (nextCard && cardsArr.length === 2) {
        arrToReturn.push(cardsArr)
        cardsArr = []
      }

      if (!nextCard && cardsArr.length && cardsArr.length < 2) {
        arrToReturn.push(cardsArr)
        cardsArr = []
      }
    } else {
      cardsArr.push(card)
      arrToReturn.push(cardsArr)
      cardsArr = []
    }
  })

  return arrToReturn
}

function cardsReducer(state, action) {
  const { type, payload, field } = action

  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }

    case "set": {
      return {
        ...state,
        ...payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

function initializer(initialState, data) {
  const completed = data?.actions?.some((x) => x.typeUid === initialState?.lessonUid && x.status === "completed")
  const cards = handleCardArrangement(data.cards, data?.width)
  let idx = 0

  if (completed) {
    return {
      ...initialState,
      cards,
      idx,
      completed,
    }
  }

  let idxOfLastCardFound
  if (data.cards) {
    data.cards.forEach((card, i) => {
      const index = data?.actions?.findIndex((x) => x?.uid === card?.id)
      if (index > -1) idxOfLastCardFound = index
    })
  }

  if (idxOfLastCardFound !== undefined) {
    const nextCard = data.cards[idxOfLastCardFound + 1] || data.cards[idxOfLastCardFound]
    cards.forEach((group, gi) => {
      const cardInGroup = group.find((x) => x?.id === nextCard?.id)
      if (cardInGroup) idx = gi
    })
  }

  return {
    ...initialState,
    cards,
    idx,
    completed,
  }
}

export const Cards = ({ materialAsJSON, lessonQuery, moduleQuery, isPreviewing }) => {
  const [showNext, setShowNext] = useState(true)
  const { enrolmentUid, lessonUid, enrolmentType } = useParams()
  const { state: ctxState, dispatch: ctxDispatch } = useLesson()
  const INITIAL_STATE = {
    idx: 0,
    position: 0,
    lessonUid,
    completed: false,
    loading: false,
    timestamps: [],
  }
  const { height, width } = useWindowDimensions()
  const windowDimensions = useWindowDimensions()
  const carouselRef = React.useRef()
  const { t } = useTranslation()

  const mutator = useEnrolmentActivtyMutation()
  const [state, dispatch] = useReducer(cardsReducer, INITIAL_STATE)

  useEffect(() => {
    dispatch({ type: "field", field: "position", payload: 0 })
    dispatch({ type: "field", field: "idx", payload: 0 })
    const data = initializer(
      {
        idx: 0,
        position: 0,
        lessonUid,
        completed: false,
      },
      {
        cards: materialAsJSON,
        width: windowDimensions.width,
        actions: [],
      }
    )
    dispatch({ type: "field", field: "cards", payload: data?.cards })
    dispatch({ type: "field", field: "completed", payload: data?.completed })
  }, [materialAsJSON])
  const completedCardLearning = async () => {
    if (!state?.completed && !ctxState?.actionRunning) {
      if (!isPreviewing) {
        const cardsActivity = materialAsJSON?.map((card, i) => ({
          typeUid: card?.id,
          type: "card",
          status: "completed",
          context: {
            completedAt: state?.timestamps?.[i] || new Date().toISOString(),
          },
        }))
        const lessonActivity = {
          typeUid: lessonUid,
          type: "lesson",
          status: "completed",
        }
        const payload = [...cardsActivity, lessonActivity]
        await trackAction({
          payload,
          publicationEnrolmentUid: enrolmentUid,
          dispatch: ctxDispatch,
          mutator,
        })
      }

      if (!ctxState?.actionRunning) {
        ctxDispatch({
          type: "next",
          payload: {
            isPreviewing,
            lessonUid,
            enrolmentUid,
            enrolmentType,
            currentModule: moduleQuery?.data,
            ...lessonQuery?.data?.next,
          },
        })
      } else {
        message?.info("Saving progress...")
      }
    }
  }

  const maxWidthForCards = useMemo(() => {
    const lessonHeader = document.getElementById("lesson-header")
    const lessonFooter = document.getElementById("lessonFooter")

    if (lessonFooter) {
      const lessonHeaderHeight = lessonHeader.offsetHeight
      const lessonFooterHeight = lessonFooter.getBoundingClientRect().height

      const availHeightForCards = height - lessonHeaderHeight - lessonFooterHeight - 32
      const maxWidthForCards = availHeightForCards * (16 / 9)

      if (maxWidthForCards >= 1200) return "1200px"
      return maxWidthForCards + "px"
    }
    return "1200px"
  }, [height, width])

  return (
    <>
      <Carousel
        ref={carouselRef}
        className="cards__carousel"
        beforeChange={(from, to) => {
          const videos = document.querySelectorAll("video")
          videos.forEach((el) => el.pause())
          dispatch({ type: "field", field: "position", payload: to })
          dispatch({
            type: "field",
            field: "timestamps",
            payload: [...state?.timestamps, ...state?.cards?.[from].map(() => new Date().toISOString())],
          })
        }}
        style={{ marginTop: "2rem" }}
        dotPosition="top"
      >
        {state.cards?.map((cardGroup, i) => (
          <React.Fragment key={i}>
            <Row gutter={32} style={{ maxWidth: maxWidthForCards, margin: "0 auto" }}>
              {cardGroup?.map((card) => (
                <LessonCard
                  setShowNext={setShowNext}
                  showNext={showNext}
                  index={i}
                  position={state?.position}
                  card={card}
                  key={card.id}
                />
              ))}
            </Row>
          </React.Fragment>
        ))}
      </Carousel>
      <Footer id="lessonFooter" style={{ background: "none" }} className="lesson__footer">
        <Space size={16}>
          {state?.position > 0 && (
            <Card
              onClick={() => carouselRef.current.prev()}
              className="lesson__button lesson__button--prev"
              bordered
              hoverable
            >
              <Space size={16}>
                <Button shape="circle" icon={<LeftOutlined />} />
                <Space size={0} direction="vertical" align="start" className="button__content">
                  <Typography.Text className="title" strong>
                    {t("previousCard")}
                  </Typography.Text>
                </Space>
              </Space>
            </Card>
          )}

          <Card
            onClick={async () => {
              if (ctxState?.actionRunning) {
                message.info(t("pleaseWaitWhileWeSave"))
              } else {
                if (showNext) {
                  if (state.cards?.length - state?.position > 1) {
                    carouselRef?.current?.next()
                  } else {
                    await completedCardLearning()
                  }
                } else {
                  message.info(t("watch90Percent"))
                }
              }
            }}
            className="lesson__button lesson__button--next"
            bordered
            hoverable
          >
            <Space size={16}>
              <Button
                loading={ctxState?.actionRunning}
                type="primary"
                shape="circle"
                className="icon"
                icon={<RightOutlined />}
              />
              <Space size={0} direction="vertical" align="start" className="button__content">
                <Typography.Text className="title" strong>
                  {state.cards?.length - state?.position > 1 ? t("nextCard") : t("wellDone") + "!"}
                </Typography.Text>
                <Typography.Text className="description" style={{ color: "white" }}>
                  {state.cards?.length - state?.position > 1
                    ? `${t("remainingCards")}: ${state.cards?.length - 1 - state?.position}`
                    : t("andContinueLearning")}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Space>
      </Footer>
    </>
  )
}

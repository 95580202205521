import React, { useMemo, useReducer } from "react"
import { Breadcrumb, Button, Card, Layout, message, Space, Typography, Upload } from "antd"
import { Link, useParams } from "react-router-dom"
import { InboxOutlined, RightOutlined, SaveOutlined } from "@ant-design/icons"
import { DefaultEditor } from "react-simple-wysiwyg"
import { nonHookUploader } from "../../../hooks/nonHookUploader"
import { useEnrolmentActivtyMutation } from "../../../network/useEnrolmentActivity"
import { trackAction, useLesson } from "../../../hooks/lesson-context"
import dayjs from "dayjs"
import { useQueryParam } from "../../../hooks/useQueryParam"
import { useTranslation } from "react-i18next"
// import { useDebounce } from '../../../hooks/useDebounce'
const { Content, Footer } = Layout
const { Dragger } = Upload

function assignmentReducer(state, action) {
  const { type, payload, field } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

/**
 * TODO:
 * If this is the first time loading the assignemt
 * Set status to started
 * Use a debounce to save their changes
 * Also make a button to allow them save their current changes ( just so they know they can do that, but also save in the background )
 * determine if this is their first time viewing in the initializer function
 */

export function Assignments({ lessonQuery, moduleQuery, enrolmentQuery }) {
  const mutator = useEnrolmentActivtyMutation()
  const { enrolmentUid, lessonUid, enrolmentType } = useParams()
  const queryParam = useQueryParam()
  const isAdmin = queryParam.get("isAdmin")
  const isFacilitate = queryParam.get("facilitate")
  const { t } = useTranslation()

  function initializer(initialState, data) {
    const assignemtHistory = data?.actions.find((x) => x?.typeUid === initialState?.assignmentUid)
    const completed = assignemtHistory && assignemtHistory?.status === "submitted"

    return {
      ...initialState,
      type: data?.type,
      [data?.type]: assignemtHistory ? assignemtHistory?.context?.submission?.data : null,
      completed,
    }
  }

  const isPreviewing = useMemo(() => {
    if (isAdmin === "true" || isFacilitate === "true") return true
    const { activity } = enrolmentQuery?.data || { activity: [] }
    const lessonUid = lessonQuery?.data?.current?.uid || ""

    return (
      enrolmentQuery?.data?.currentState === "completed" ||
      activity.some(({ typeUid, status }) => typeUid === lessonUid && status === "completed")
    )
  }, [enrolmentQuery, lessonQuery])

  const { state: ctxState, dispatch: ctxDispatch } = useLesson()
  const [state, dispatch] = useReducer(
    assignmentReducer,
    {
      upload: null,
      practical: null,
      "free-text": null,
      loading: false,
      submitting: false,
      assignmentUid: lessonQuery?.data?.current?.uid,
      type: null,
    },
    (initialState) =>
      initializer(initialState, {
        type: lessonQuery?.data?.current?.type,
        actions: enrolmentQuery?.data?.activity,
      })
  )

  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error("File must be smaller than 2MB!")
    }
    return isLt2M
  }

  return (
    <Layout style={{ background: "#f8f8f881", position: "relative" }}>
      <header className="leasson__header" style={{ background: "#f8f8f881" }}>
        <div className="content">
          <Space size={0} direction="vertical">
            <p className="badge--meta"> {ctxState?.learning?.kind}</p>
            <Typography.Title style={{ margin: 0 }} level={3}>
              {lessonQuery?.data?.current?.title}
            </Typography.Title>
          </Space>
          <Space>
            <Link to="/">
              <Button type="link">
                End
                {isPreviewing ? " Preview" : " Learning"}
              </Button>
            </Link>
          </Space>
        </div>
      </header>
      <Content className="lesson__container">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>{moduleQuery?.data?.title}</Breadcrumb.Item>
          <Breadcrumb.Item>{lessonQuery?.data?.current?.title}</Breadcrumb.Item>
        </Breadcrumb>
        {(() => {
          switch (state.type) {
            case "upload":
              return (
                <div className="assignment">
                  <Dragger
                    accept=".doc,.docx,.xls,.xlsx,.pdf,image/*,video/*, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
                    disabled={state?.completed || isPreviewing}
                    customRequest={(req) => nonHookUploader({ ...req, mutator })}
                    beforeUpload={beforeUpload}
                    multiple
                    onChange={(files) => {
                      if (!files.event) {
                        dispatch({
                          type: "field",
                          field: state.type,
                          payload: files?.fileList.map((file) => ({
                            name: file?.name,
                            uid: file?.uid,
                            type: file?.type,
                            size: file?.size,
                            url: file?.imageUrl,
                          })),
                        })
                      }
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p>{t("uploadAssignment")}</p>
                    <p className="badge--meta">{t("dragYourDocuments")}</p>
                  </Dragger>
                </div>
              )
            case "free-text":
              return (
                <DefaultEditor
                  disabled={state?.completed || isPreviewing}
                  onChange={(e) => {
                    console.log("e::: ", e)
                    dispatch({
                      type: "field",
                      field: state.type,
                      payload: e.target.value,
                    })
                  }}
                  value={state[state.type]}
                />
              )
            case "practical":
              return <div>This is a practical assignment</div>
            default:
              return <>Something went wrong while loading this assignment</>
          }
        })()}
      </Content>
      <Footer style={{ background: "none" }} className="lesson__footer">
        <Space size={16}>
          {!state?.completed && !isPreviewing && (
            <Card
              className="lesson__button lesson__button--prev"
              bordered
              hoverable
              onClick={async () => {
                dispatch({ type: "field", field: "loading", payload: true })
                await trackAction({
                  mutator,
                  publicationEnrolmentUid: enrolmentUid,
                  dispatch: ctxDispatch,
                  payload: {
                    typeUid: state?.assignmentUid,
                    type: "assignment",
                    context: {
                      type: state?.type,
                      submission: {
                        data: state[state?.type],
                        submittedAt: dayjs(),
                      },
                    },
                    status: "started",
                  },
                })
                dispatch({ type: "field", field: "loading", payload: false })
              }}
            >
              <Space size={16}>
                <Button loading={state.loading} shape="circle" className="icon" icon={<SaveOutlined />} />
                <Space size={0} direction="vertical" align="start" className="button__content">
                  <Typography.Text className="title" strong>
                    {t("saveChanges")}
                  </Typography.Text>
                  <Typography.Text className="description">{t("andFinishLater")}</Typography.Text>
                </Space>
              </Space>
            </Card>
          )}
          <Card
            className="lesson__button lesson__button--next"
            bordered
            hoverable
            onClick={async () => {
              if (!isPreviewing && !state[state?.type] && state?.type !== "practical")
                return message.error("Assignment cannot be empty")

              if (!state?.completed && !isPreviewing && !ctxState?.actionRunning) {
                await trackAction({
                  mutator,
                  publicationEnrolmentUid: enrolmentUid,
                  dispatch: ctxDispatch,
                  payload: {
                    typeUid: state?.assignmentUid,
                    type: "assignment",
                    context: {
                      type: state?.type,
                      submission: {
                        data: state[state?.type],
                        submittedAt: dayjs(),
                      },
                    },
                    status: "submitted",
                  },
                })
              }

              if (!ctxState?.actionRunning) {
                ctxDispatch({
                  type: "next",
                  payload: {
                    isPreviewing,
                    lessonUid,
                    enrolmentUid,
                    enrolmentType,
                    currentModule: moduleQuery?.data,
                    ...lessonQuery?.data?.next,
                  },
                })
              } else {
                message?.info("Saving progress...")
              }
            }}
          >
            <Space size={16}>
              <Button
                loading={ctxState?.actionRunning}
                type="primary"
                shape="circle"
                className="icon"
                icon={<RightOutlined />}
              />
              <Space size={0} direction="vertical" align="start" className="button__content">
                <Typography.Text className="title" strong>
                  {isPreviewing && state?.completed ? t("Next") : t("submitAssignment")}
                </Typography.Text>
                <Typography.Text className="description" style={{ color: "white" }}>
                  {isPreviewing ? t("andContinuePreviewing") : t("andContinueLearning")}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Space>
      </Footer>
    </Layout>
  )
}

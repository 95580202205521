import {
  Avatar,
  Button,
  Col,
  Comment,
  DatePicker,
  Divider,
  Form,
  message,
  Modal,
  PageHeader,
  Row,
  Typography,
} from "antd"
import dayjs from "dayjs"
import React, { useState } from "react"
import { makeProfileImg } from "../hooks/makeProfileImg"
import { useGetPerson } from "../network/usePerson"
import { useTrainingMutation } from "../network/useTraining"

export function TrainingModal({ visible, event, onCancel }) {
  const personQuery = useGetPerson({ authenticated: true })

  const trainingMutation = useTrainingMutation()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const createTrainingRequest = async (payload) => {
    setLoading(true)

    try {
      setLoading(true)
      trainingMutation.mutateAsync({
        data: payload,
      })
      onCancel()
      message.success("Traing Request Created")
    } catch (error) {
      message.error(`Something went wrong: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      visible={visible}
      width="80%"
      className="modal"
      onCancel={() => onCancel()}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      closeIcon={null}
    >
      <PageHeader className="site-page-header" onBack={() => onCancel()} title="Request Training" />
      <Divider />
      <Form
        form={form}
        name="training"
        layout="vertical"
        onFinish={() => {
          form.validateFields().then(async (values) => {
            const payload = {
              companyUid: personQuery.data?.company?.uid,
              training: {
                uid: event.uid,
                type: event?.type,
                typeUid: event?.typeUid,
                title: event.content.title || "",
                summary: event.content.summary || "Summary",
                descriptionAsHTML: event.content.descriptionAsHTML || "",
                isFacilitated: event?.isFacilitated,
                imageUrl: event?.featureImageUrl || makeProfileImg(`${event.content.title[0] || "A"}`),
              },
              date: values.date?.map((date) => dayjs(date).format("YYYY-MM-DD")),
              members: [
                {
                  imageUrl: personQuery?.data?.imageUrl,
                  name: `${personQuery?.data?.firstName} ${personQuery?.data?.lastName}`,
                  uid: personQuery?.data?.uid,
                },
              ],
              status: "pending",
            }
            await createTrainingRequest(payload)
          })
        }}
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Typography.Title level={5}>Traning</Typography.Title>
            <div className="chat__person">
              <Comment
                style={{ width: "100%", marginLeft: "1rem" }}
                author="Course"
                avatar={<Avatar src={event?.featureImageUrl || makeProfileImg(`${event?.content?.title[0]}`)} />}
                content={`${event?.content?.title}`}
              />
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Typography.Title level={5}>Person</Typography.Title>
            <div className="chat__person">
              <Comment
                style={{ width: "100%", marginLeft: "1rem" }}
                author="Member"
                avatar={<Avatar src={personQuery?.data?.imageUrl || makeProfileImg(personQuery?.data?.firstName)} />}
                content={`${personQuery?.data?.firstName} ${personQuery?.data?.lastName}`}
              />
            </div>
          </Col>
        </Row>
        <Divider />
        {event?.isFacilitated && (
          <Form.Item name="date" label="Date Range">
            <DatePicker.RangePicker />
          </Form.Item>
        )}
        <Button loading={loading} type="primary" htmlType="submit">
          Create Training Request
        </Button>
      </Form>
    </Modal>
  )
}

import {
  AppstoreOutlined,
  BarsOutlined,
  BookOutlined,
  ClockCircleOutlined,
  DownOutlined,
  RedoOutlined,
  SearchOutlined,
  StopOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons"
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Segmented,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import { useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { tabFactory } from "../helpers/tabsFactory"
import { TagFactory } from "./TagFactory"
import { TeamStatCards } from "./TeamStatCards"
import { fetchMemberEnrolments, fetchTeamPubEnrls, useGetSingleTeamPubs } from "../network/useTeams"
import { useQueryClient } from "react-query"
import { useGetPerson } from "../network/usePerson"
import { useIsThungela } from "../hooks/useIsThungela"
import { useTranslation } from "react-i18next"
dayjs.extend(LocalizedFormat)

export const TeamEnrolmentsPane = ({ team }) => {
  const personQuery = useGetPerson({ authenticated: true })
  const isThungela = useIsThungela(personQuery?.data?.company?.tenantId)
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const teamPubsQuery = useGetSingleTeamPubs({ teamUid: team?.uid })

  const [memoizedChildData, setMemoizedChildData] = useState([])
  const [memoizedMembersChildData, setMemoizedMembersChildData] = useState([])

  const [canReset, setCanReset] = useState(() => {
    const seg = tabFactory("eFilter").get()
    const search = tabFactory("eSearch").get()
    if (seg && seg !== "All" && search) {
      return true
    } else {
      return false
    }
  })
  const [segment, setSegment] = useState(tabFactory("eFilter").get() || "All")
  const [tableType, setTableType] = useState(tabFactory("eTable").get() || "Enrolments")
  const [search, setSearch] = useState(tabFactory("eSearch").get() || "")
  const { teamUid } = useParams()

  const membersColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Enrolments",
      dataIndex: "enrolments",
      render: (enrolments) => {
        return enrolments.length
      },
    },
    {
      title: t("Action"),
      dataIndex: "operation",
      key: "operation",
      render: ({ personUid, teamMemberUid }) => {
        return (
          <Space size="middle">
            <Link to={`/teams/${teamUid}/booking?personUid=${personUid}`}>{t("requestTraining")}</Link>
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      key: "Send Communication",
                      label: (
                        <Link to={`/teams/${teamUid}/communication?personUid=${teamMemberUid}`}>
                          {t("sendCommunication")}
                        </Link>
                      ),
                    },
                  ]}
                />
              }
            >
              <Typography.Link>
                {t("More")} <DownOutlined />
              </Typography.Link>
            </Dropdown>
          </Space>
        )
      },
    },
  ]

  const columns = [
    {
      title: t("Name"),
      dataIndex: "enrolment",
      key: "enrolment",
      width: "25%",
      render: ({ name, imageUrl }) => {
        return (
          <Space align="center" size={16}>
            <Avatar src={imageUrl} />
            <Typography.Paragraph style={{ margin: 0 }}>{name}</Typography.Paragraph>
          </Space>
        )
      },
    },
    {
      width: "25%",
      title: t("Enrolments"),
      dataIndex: "enrolments",
      key: "enrolments",
    },
    {
      title: t("Completed"),
      dataIndex: "completed",
      key: "completed",
    },
    {
      width: "25%",
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      width: "25%",
      title: t("createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
    },
  ]
  const childCols = [
    {
      title: t("Person"),
      dataIndex: "person",
      key: "person",
      render: ({ name, imageUrl }) => {
        return (
          <Space align="center" size={16}>
            {imageUrl ? <Avatar src={imageUrl} /> : <Avatar icon={<UserOutlined />} />}
            <Typography.Paragraph style={{ margin: 0 }}>{name}</Typography.Paragraph>
          </Space>
        )
      },
    },
    {
      title: t("startedAt"),
      dataIndex: "startedAt",
      key: "startedAt",
    },
    {
      title: t("Status"),
      key: "currentState",
      render: ({ currentState }) => (
        <Badge
          style={{ textTransform: "capitalize" }}
          status={currentState === "completed" ? "success" : "warning"}
          text={currentState}
        />
      ),
    },
    {
      title: t("validUntil"),
      dataIndex: "validity",
      key: "validity",
      render: (completionValidUntil) => {
        if (completionValidUntil === null) return ""
        else {
          const diff = dayjs(completionValidUntil).diff(dayjs(), "days")
          if (diff > 365) {
            return (
              <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
                Expires in over a year
              </TagFactory>
            )
          } else {
            return (
              <TagFactory
                icon={diff < 0 ? <StopOutlined /> : <ClockCircleOutlined />}
                status={diff < 0 ? "Expired" : "Expiring"}
              >
                {diff < 0 ? "Expired" : `Expires ${dayjs(completionValidUntil).fromNow()}`}
              </TagFactory>
            )
          }
        }
      },
    },
    {
      title: t("completedAt"),
      dataIndex: "completedAt",
      key: "completedAt",
    },
    {
      title: t("More"),
      dataIndex: "operation",
      key: "operation",
      render: ({ enrolmentUid, personUid, teamMemberUid }) => {
        return (
          <Space size="middle">
            <Link to={`/teams/${teamUid}/booking?personUid=${personUid}`}>{t("requestTraining")}</Link>
            <Link to={`./${teamUid}/enrolments/${enrolmentUid}/person/${personUid}`}>{t("View")}</Link>
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      key: "Send Communication",
                      label: (
                        <Link to={`/teams/${teamUid}/communication?personUid=${teamMemberUid}`}>
                          {t("sendCommunication")}
                        </Link>
                      ),
                    },
                  ]}
                />
              }
            >
              <Typography.Link>
                {t("More")} <DownOutlined />
              </Typography.Link>
            </Dropdown>
          </Space>
        )
      },
    },
  ]
  const memberChildCols = [
    {
      title: "Name",
      dataIndex: "enrolment",
      key: "person",
      render: ({ name, imageUrl }) => {
        return (
          <Space align="center" size={16}>
            {imageUrl ? <Avatar src={imageUrl} /> : <Avatar icon={<UserOutlined />} />}
            <Typography.Paragraph style={{ margin: 0 }}>{name}</Typography.Paragraph>
          </Space>
        )
      },
    },
    {
      title: t("startedAt"),
      dataIndex: "startedAt",
      key: "startedAt",
    },
    {
      title: t("Status"),
      key: "currentState",
      render: ({ currentState }) => (
        <Badge
          style={{ textTransform: "capitalize" }}
          status={currentState === "completed" ? "success" : "warning"}
          text={currentState}
        />
      ),
    },
    {
      title: t("validUntil"),
      dataIndex: "validity",
      key: "validity",
      render: (completionValidUntil) => {
        if (completionValidUntil === null) return ""
        else {
          const diff = dayjs(completionValidUntil).diff(dayjs(), "days")
          if (diff > 365) {
            return (
              <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
                Expires in over a year
              </TagFactory>
            )
          } else {
            return (
              <TagFactory
                icon={diff < 0 ? <StopOutlined /> : <ClockCircleOutlined />}
                status={diff < 0 ? "Expired" : "Expiring"}
              >
                {diff < 0 ? "Expired" : `Expires ${dayjs(completionValidUntil).fromNow()}`}
              </TagFactory>
            )
          }
        }
      },
    },
    {
      title: t("completedAt"),
      dataIndex: "completedAt",
      key: "completedAt",
    },
  ]
  /**
   * Filters an array of publications based on segment and search criteria.
   * @param {Array} publications - The array of publications to filter.
   * @param {string} segment - The segment to filter by (All, Courses, Programs).
   * @param {string} search - The search string to filter publications by title or enrolments name.
   * @returns {Array} An array of filtered publications.
   */
  const memoizedData = useMemo(() => {
    if (teamPubsQuery?.data && !teamPubsQuery?.isLoading) {
      const filteredPublications = teamPubsQuery.data
        ?.filter((pub) => {
          const isCourse = pub?.type === "course"
          const isProgram = pub?.type === "program"
          if (segment === "All") {
            return true
          }
          return (segment === "Courses" && isCourse) || (segment === "Programs" && isProgram)
        })
        .filter((pub) => {
          if (!search) {
            return true
          }
          const lowerCaseSearch = search.toLowerCase()
          const title = pub?.content?.title?.toLowerCase()
          return title?.includes(lowerCaseSearch)
        })

      return filteredPublications?.map((pub, index) => {
        const isCourse = pub?.type === "course"
        const isProgram = pub?.type === "program"

        return {
          rowIndex: index,
          key: pub?.uid,
          enrolment: {
            name: pub?.content?.title,
            imageUrl: pub?.featureImageUrl,
          },
          completed: pub?.enrolments?.filter((x) => x.currentState === "completed")?.length || 0,
          enrolments: pub?.enrolments?.length,
          type: isCourse ? "Course" : isProgram ? "Program" : "",
          createdAt: pub?.createdAt ? dayjs(pub?.createdAt).format("ll") : "",
        }
      })
    } else {
      return []
    }
  }, [teamPubsQuery.data, teamPubsQuery.isLoading, team, segment, search])
  // }, [teamPubsQuery.data, memoizedChildData, teamPubsQuery.isLoading, team, segment, search])

  const memoizedMembersData = useMemo(() => {
    if (team && team.members) {
      return team.members
        .map((member, index) => ({
          rowIndex: index,
          key: member?.member?.uid,
          uid: member?.member?.uid,
          personUid: member?.member?.person?.uid,
          name: member?.member?.person?.firstName + " " + member?.member?.person?.lastName,
          email: member?.member?.email,
          enrolments: member?.member?.person?.enrolments,
          member: member?.member,
          operation: {
            personUid: member?.member?.person?.uid,
            teamMemberUid: member?.member?.uid,
          },
        }))
        .filter((x) => {
          if (!search) return true

          const lowerCaseSearch = search.toLowerCase()
          const name = x?.name?.toLowerCase()
          return name?.includes(lowerCaseSearch)
        })
    } else {
      return []
    }
  }, [team, search])

  useEffect(() => {
    const fetchData = async () => {
      if (memoizedData && memoizedData.length) {
        try {
          const promises = memoizedData?.map(async (record) => {
            const data = await queryClient.fetchQuery(
              ["team", teamUid, "publications", record.key],
              () =>
                fetchTeamPubEnrls({
                  publicationUid: record.key,
                  teamUid,
                  queryClient,
                }),
              {
                staleTime: 1000 * 60 * 30,
              }
            )
            return data
          })

          const results = await Promise.all(promises)
          setMemoizedChildData(results)
        } catch (error) {
          console.error(error)
          setMemoizedChildData(null)
        }
      } else {
        setMemoizedChildData([])
      }
    }

    fetchData()
  }, [memoizedData])

  useEffect(() => {
    const fetchData = async () => {
      if (memoizedMembersData && memoizedMembersData.length) {
        try {
          const promises = memoizedMembersData?.map(async (record) => {
            const data = await queryClient.fetchQuery(
              ["memberEnrolments", record.personUid],
              () =>
                fetchMemberEnrolments({
                  personUid: record.personUid,
                  queryClient,
                  filters: {
                    limit: record?.enrolments?.length ?? 10,
                  },
                }),
              {
                staleTime: 1000 * 60 * 30,
              }
            )
            return data
          })

          const results = await Promise.all(promises)

          setMemoizedMembersChildData(results.map((payload) => payload.enrolments ?? []))
        } catch (error) {
          console.error(error)
          setMemoizedMembersChildData(null)
        }
      } else {
        setMemoizedMembersChildData([])
      }
    }
    fetchData()
  }, [memoizedMembersData])

  const subTables = (record) => {
    const formattedData = memoizedChildData?.[record?.rowIndex]?.map((e, i) => {
      return {
        key: e?.person?.uid + "-" + i,
        person: {
          name: `${e?.person?.firstName} ${e?.person?.lastName}`,
          imageUrl: e?.person?.imageUrl,
        },
        validity: e?.completionValidUntil ? e?.completionValidUntil : null,
        startedAt: e?.startedAt ? dayjs(e?.startedAt).format("ll") : "",
        completedAt: e?.completedAt ? dayjs(e?.completedAt).format("ll") : "",
        currentState: e?.currentState ?? "",
        completedStatus: e?.completedStatus,
        operation: {
          teamMemberUid: team.members?.find((x) => x?.member?.person?.uid === e?.person?.uid).member.uid,
          personUid: e?.person?.uid,
          enrolmentUid: record?.key,
        },
      }
    })
    return (
      <div style={{ padding: "0 0 3rem 1rem" }}>
        <Table
          rowKey={(record) => record.key}
          columns={childCols}
          dataSource={formattedData ?? []}
          pagination={formattedData?.length > 14}
        />
      </div>
    )
  }

  const memberSubTable = (record) => {
    const formattedData = memoizedMembersChildData?.[record?.rowIndex]?.map((e, i) => {
      console.log("e::: ", e)
      return {
        key: e?.uid + "-" + i,
        enrolmentUid: e?.uid,
        enrolment: {
          name: `${e?.publication?.content?.title}`,
          imageUrl: e?.publication?.featureImageUrl,
        },
        validity: e?.completionValidUntil ? e?.completionValidUntil : null,
        startedAt: e?.startedAt ? dayjs(e?.startedAt).format("ll") : "",
        completedAt: e?.completedAt ? dayjs(e?.completedAt).format("ll") : "",
        currentState: e?.currentState ?? "",
        completedStatus: e?.completedStatus,
      }
    })
    return (
      <div style={{ padding: "0 0 3rem 1rem" }}>
        <Table
          rowKey={(record) => record.key}
          columns={memberChildCols}
          dataSource={formattedData ?? []}
          // pagination={formattedData?.length > 14}
        />
      </div>
    )
  }

  console.log("memoizedData::: ", memoizedData)

  return (
    <>
      <div className="filter">
        <Input
          className="filter__input filter__input--noMargin"
          bordered={false}
          placeholder={t("searchByEnrolmentTitle")}
          onInput={(e) => {
            const value = e?.target?.value
            tabFactory("eSearch").onChange(value)
            setSearch(value)
            if (value !== "" && segment !== "All") setCanReset(true)
            else setCanReset(false)
          }}
          value={tabFactory("eSearch").get() || ""}
          prefix={<SearchOutlined />}
        />
        <Space className="onMobile--hide">
          <Segmented
            value={tabFactory("eTable").get() || "Enrolments"}
            onChange={(value) => {
              console.log("value::: ", value)
              tabFactory("eTable").onChange(value)
              setTableType(value)
            }}
            options={[
              {
                label: t("Courses"),
                value: "Enrolments",
                icon: <UnorderedListOutlined />,
              },
              {
                label: t("Members"),
                value: "Members",
                icon: <UserOutlined />,
              },
            ]}
          />
          {canReset ? (
            <Button
              onClick={() => {
                tabFactory("eFilter").onChange("All")
                tabFactory("eSearch").onChange("")
                setSegment("All")
                setSearch("")
                setCanReset(false)
              }}
              type="primary"
              icon={<RedoOutlined />}
            >
              Clear
            </Button>
          ) : null}
        </Space>
      </div>
      <Space className="onMobile--show">
        <Segmented
          value={tabFactory("eFilter").get() || "All"}
          onChange={(value) => {
            tabFactory("eFilter").onChange(value)
            setSegment(value)
            if (value !== "All" && search) setCanReset(true)
            else if (value === "All" && !search) setCanReset(false)
          }}
          options={[
            {
              label: t("All"),
              value: "All",
              icon: <BookOutlined />,
            },
            {
              label: t("Courses"),
              value: "Courses",
              icon: <BarsOutlined />,
            },
            {
              label: t("Programs"),
              value: "Programs",
              icon: <AppstoreOutlined />,
            },
          ]}
        />
        {canReset ? (
          <Button
            onClick={() => {
              tabFactory("eFilter").onChange("All")
              tabFactory("eSearch").onChange("")
              setSegment("All")
              setSearch("")
              setCanReset(false)
            }}
            type="primary"
            icon={<RedoOutlined />}
          >
            Clear
          </Button>
        ) : null}
      </Space>
      <Divider />
      {tableType === "Enrolments" ? (
        <>
          {memoizedData && memoizedData?.length && memoizedChildData?.length ? (
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: subTables,
              }}
              dataSource={memoizedData}
              loading={teamPubsQuery.isLoading}
              rowKey={(record) => record.key}
            />
          ) : (
            <Table columns={columns} dataSource={[]} loading={true} />
          )}
        </>
      ) : (
        <>
          {memoizedMembersData && memoizedMembersData?.length ? (
            <Table
              //
              columns={membersColumns}
              expandable={{
                expandedRowRender: memberSubTable,
              }}
              dataSource={memoizedMembersData}
              rowKey={(record) => record.key}
            />
          ) : (
            <Table columns={membersColumns} dataSource={[]} loading={true} />
          )}
        </>
      )}
    </>
  )
}

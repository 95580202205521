import { stripHtml } from "./stripHtml"

export const cardFactory = (payload) => {
  switch (payload?.integrationName) {
    case "go1": {
      const obj = {
        id: payload?.id,
        title: payload?.title,
        url: payload?.image,
        desc: payload.summary,
        description: payload?.description,
        summary: payload?.summary,
        topics: payload?.attributes?.topics,
        outcomes: payload?.attributes?.learning_outcomes,
        provider: {
          imageUrl: payload?.provider?.logo,
          name: payload?.provider?.name,
        },
        ...payload,
      }
      return obj
    }

    default:
      return {
        id: payload?.uid,
        title:
          payload?.content?.title || payload?.translations["1"]?.title || "",
        url: payload?.featureImageUrl,
        desc: stripHtml(payload?.content?.descriptionAsHTML),
        description: payload?.content?.descriptionAsHTML,
        summary: payload?.content?.summary,
        topics: payload?.topics,
        outcomes: payload?.content?.learningOutcomes,
        provider: {
          imageUrl: payload?.publishedBy?.imageUrl,
          name: payload?.publishedBy?.name,
        },
        ...payload,
      }
  }
}

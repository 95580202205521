import { useEffect, useMemo, useReducer } from "react"
import { useHistory } from "react-router-dom"
import { useGetEnrolments } from "../network/useEnrolments"
import { learningReducer } from "../reducers/learningReducer"
import { useDebounce } from "./useDebounce"
import { useGetQueryParam } from "./useGetQueryParam"

export const useMyLearning = () => {
  const { push } = useHistory()
  const qPage = useGetQueryParam("page")
  const [state, dispatch] = useReducer(learningReducer, {
    initialized: false,
    open: false,
    featured: [],
    enrolments: [],
    initialData: [],
    sort: { by: null, orderIdx: 0 },
    filters: {
      offset: 0,
      limit: 10,
      q: "",
    },
    page: qPage || 1,
    total: 0,
    view: "list",
    searchToDebounce: "",
  })

  const enrolmentsQuery = useGetEnrolments(state?.filters)
  useDebounce(state?.searchToDebounce, 1000, dispatch)

  useEffect(() => {
    if (!enrolmentsQuery?.isLoading && enrolmentsQuery?.data?.enrolments) {
      if (
        (enrolmentsQuery?.data?.enrolments?.length === 1 && state?.page < 2) ||
        !state?.page
      ) {
        const item = enrolmentsQuery?.data?.enrolments[0]
        push(`${item?.publication?.type}s/${item?.uid}`)
      }

      dispatch({
        type: "batch",
        payload: {
          initialData: enrolmentsQuery?.data?.enrolments,
          enrolments: enrolmentsQuery?.data?.enrolments,
          sort: { by: null, orderIdx: 0 },
        },
      })
      // dispatch({ type: 'field', field: 'enrolments', payload: enrolmentsQuery?.data?.enrolments })
    }
  }, [enrolmentsQuery?.isLoading])

  const topics = useMemo(() => {
    const list = []

    enrolmentsQuery?.data?.enrolments?.forEach((x) => {
      if (x?.publication?.topics) {
        list.push(...x?.publication?.topics)
      }
    })
    if (enrolmentsQuery?.data) {
      dispatch({
        type: "batch",
        payload: {
          page: 1,
          total: enrolmentsQuery.data?.totalData,
          initialData: enrolmentsQuery?.data?.enrolments,
          enrolments: enrolmentsQuery?.data?.enrolments,
        },
      })
    }
    return list
  }, [enrolmentsQuery?.data?.enrolments])

  return { state: { ...state, topics }, dispatch, enrolmentsQuery }
}

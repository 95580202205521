import { Button, Modal, notification, Space, Tag, Tooltip } from "antd"
import dayjs from "dayjs"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import advancedFormat from "dayjs/plugin/calendar"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useGetPerson } from "../network/usePerson"
import { useDeleteEnrolmentMutation, useEnrolmentMutation } from "../network/useEnrolment"

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(advancedFormat)

export function ExternalEnrolmentActionButton({ enrolment }) {
  const [enrolLoading, setEnrolLoading] = useState(false)
  const [unenrolLoading, setUnenrolLoading] = useState(false)

  const personQuery = useGetPerson({ authenticated: true })
  const unenrolMutation = useDeleteEnrolmentMutation()
  const enrolmentMutation = useEnrolmentMutation(enrolment?.uid)

  const { push } = useHistory()
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to unenroll?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone and any progress will be lost",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      confirmLoading: unenrolMutation.isLoading,
      async onOk() {
        setUnenrolLoading(true)
        await unenrolMutation.mutateAsync(
          {
            enrolmentUid: enrolment?.uid,
          },
          {
            onSuccess: () => push("/"),
            onSettled: () => setUnenrolLoading(false),
          }
        )
      },
    })
  }

  const handleStart = async () => {
    setEnrolLoading(true)
    notification.success({
      message: `Hey, ${personQuery.data?.firstName}!`,
      description: `Thank you for starting the ${enrolment?.publication?.content?.title} course, we hope you enjoy it.`,
    })
    await enrolmentMutation.mutateAsync(
      {
        enrolmentUid: enrolment?.uid,
        data: {
          currentState: "in-progress",
          startedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
        },
      },
      {
        onSettled: () => {
          setEnrolLoading(false)
        },
      }
    )
    push(`/externals/${enrolment?.uid}/learning`)
  }

  console.log("enrolment", enrolment)

  if (enrolment?.currentState === "not-started") {
    return (
      <Space>
        <Button loading={enrolLoading} disabled={unenrolLoading} onClick={() => handleStart()} type="primary">
          Start Course
        </Button>
        {enrolment?.enrolmentStatus === "self-enroled" && (
          <Tooltip
            title={`This course was enrolled to you ${dayjs
              .utc(enrolment?.createdAt)
              .local()
              .fromNow()}, would you like to unenroll?`}
          >
            <Button loading={unenrolLoading} disabled={enrolLoading} onClick={showDeleteConfirm}>
              Unenroll
            </Button>
          </Tooltip>
        )}
      </Space>
    )
  }

  if (enrolment?.currentState === "in-progress") {
    return (
      <Space>
        <Link to={`/externals/${enrolment?.uid}/learning`}>
          <Button type="primary">Resume Course</Button>
        </Link>
        {enrolment?.enrolmentStatus === "self-enroled" && (
          <Tooltip
            title={`This course was enrolled to you ${dayjs
              .utc(enrolment?.createdAt)
              .local()
              .fromNow()}, would you like to unenroll?`}
          >
            <Button loading={unenrolLoading} disabled={enrolLoading} onClick={showDeleteConfirm}>
              Unenroll
            </Button>
          </Tooltip>
        )}
      </Space>
    )
  }

  if (enrolment?.currentState === "completed") {
    return <Tag color="green">Completed: {dayjs(enrolment?.completedAt).format("DD/MM/YYYY")}</Tag>
  }

  return null
}

import { DownOutlined, PlusCircleOutlined, SmileOutlined } from "@ant-design/icons"
import { Dropdown, Menu, Space, message } from "antd"
import React from "react"
import { useActivityResetMutation } from "../network/useEnrolmentActivity"
import { useQueryClient } from "react-query"
import { useGetPerson } from "../network/usePerson"

export const ActivityAssessmentReset = ({ item, person }) => {
  console.log("item::: ", item)
  console.log("person::: ", person)
  return (
    <Dropdown placement="bottomRight" overlay={<MenuWrapper item={item} person={person} />}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  )
}

const MenuWrapper = ({ item, person }) => {
  const resestMutator = useActivityResetMutation()
  const queryClient = useQueryClient()
  const personQuery = useGetPerson({ authenticated: true })
  return (
    <Menu
      items={[
        {
          key: "1",
          label: "Grant Additional Attempt",
          icon: <PlusCircleOutlined />,
          onClick: async () => {
            if (!resestMutator.isLoading) {
              await resestMutator.mutateAsync(
                {
                  publicationEnrolmentUid: person?.publicationEnrolmentUid,
                  type: item.type,
                  typeUid: item.uid,
                },
                {
                  onSuccess: () => message.success("Additional attempt granted"),
                }
              )
            }
          },
        },
      ]}
    />
  )
}

import { UserOutlined } from "@ant-design/icons"
import { useAuthCreateUserWithEmailAndPassword, useAuthSignOut } from "@react-query-firebase/auth"
import { Button, Col, Form, Input, message, Modal, Row, Select, Skeleton, Typography } from "antd"
import React, { useReducer, useMemo, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { Countries } from "../../constants/Countries"
import { auth } from "../../firebase"
import { useHandleTenantId } from "../../hooks/useHandleTenantId"
import { useQueryParam } from "../../hooks/useQueryParam"
import { useLoggerMutation } from "../../network/useLogger"
import { useSelfRegisterWithEmailAndPassword } from "../../network/usePerson"
import { useQueryClient } from "react-query"

function registerReducer(state, action) {
  const { type, payload, field } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const SelfRegister = () => {
  const queryParam = useQueryParam()
  const REGISTER_STATE = {
    tenantId: queryParam.get("tenantId"),
    type: null,
    loading: false,
    showMFA: false,
    verificationId: null,
    code: null,
    support: false,
    acceptTerms: false,
    countries: [],
    filteredBranches: [],
    selectedCountry: null,
    showValid: false,
    showInvalid: false,
  }

  const [state, dispatch] = useReducer(registerReducer, REGISTER_STATE)
  const [form] = Form.useForm()
  auth.tenantId = state.tenantId || process.env.REACT_APP_TENANT_ID
  const registerMutation = useAuthCreateUserWithEmailAndPassword(auth)
  const createRegistrationMutation = useSelfRegisterWithEmailAndPassword()
  const history = useHistory()
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const logMutation = useLoggerMutation()

  const queryClient = useQueryClient()

  const DEFAULT_BG =
    "https://images.unsplash.com/photo-1612999805994-1eabaef7bb88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
  const { tenant, isLoading } = useHandleTenantId(state, dispatch)

  const availCountries = useMemo(() => {
    if (isLoading && !tenant && !tenant?.branches?.length) {
      return []
    }
    const uniqueCountries = new Set()
    tenant?.branches?.forEach((branch) => uniqueCountries.add(branch?.country))
    return Array.from(uniqueCountries).map((c) => ({
      name: c,
      flag: Countries[c],
    }))
  }, [tenant, isLoading])

  const availBranches = useMemo(() => {
    if (isLoading && !tenant && !tenant?.branches?.length) {
      return []
    }

    if (!state?.selectedCountry) return tenant?.branches

    return tenant?.branches?.filter((branch) => branch?.country === state?.selectedCountry)
  }, [tenant, isLoading, state?.selectedCountry])

  const determineCountry = (e) => {
    const branch = JSON.parse(e)

    form.setFieldsValue({
      country: branch?.country,
    })
  }
  const determineBranch = (e) => {
    dispatch({
      type: "field",
      field: "selectedCountry",
      payload: e,
    })
    const currentBranch = form?.getFieldValue("branch") ? JSON?.parse(form?.getFieldValue("branch")) : null

    if (!currentBranch) return null

    if (currentBranch?.country === e) return null

    form.setFieldsValue({
      branch: null,
    })
  }

  const handleEmail = (_, value) => {
    if (!tenant?.allowedDomains || !tenant?.allowedDomains?.length) return Promise.resolve()

    const splitEmail = value?.split("@")[1]
    if (!tenant?.allowedDomains?.includes(splitEmail)) {
      if (tenant?.invalidDomainInput) {
        dispatch({ type: "field", field: "showValid", payload: false })
        dispatch({ type: "field", field: "showInvalid", payload: true })
        return Promise.resolve()
      } else {
        return Promise.reject(new Error("Please enter a valid email"))
      }
    } else {
      if (tenant?.validDomainInput) {
        dispatch({ type: "field", field: "showValid", payload: true })
        dispatch({ type: "field", field: "showInvalid", payload: false })
      }
      return Promise.resolve()
    }
  }

  const handleRegistration = async (payload) => {
    try {
      const res = await registerMutation.mutateAsync(payload)
      if (!res?.user?.uid) {
        throw new Error("Unable to register user")
      }

      return res
    } catch (error) {
      dispatch({
        type: "field",
        field: "loading",
        payload: false,
      })
      message.error(error.message)
    }
  }

  useEffect(() => {
    queryClient.invalidateQueries()
  }, [])

  return (
    <div
      className="auth"
      style={{
        backgroundImage: `url(${tenant?.featureImageUrl || DEFAULT_BG})`,
      }}
    >
      <Modal
        visible={state?.support}
        cancelText="Cancel"
        onCancel={() => dispatch({ type: "field", field: "support", payload: false })}
        footer={null}
        width="55%"
        className="modal modal--noPadding"
      >
        <iframe
          title="support form"
          src={tenant?.supportUrl}
          width="100%"
          style={{
            border: 0,
            height: "82vh",
            boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)",
          }}
        />
      </Modal>
      <Row>
        <Col xs={24} md={12} lg={10} xl={8} className="auth__pannel">
          <Form
            form={form}
            name="login"
            layout="vertical"
            preserve
            initialValues={{ remember: true }}
            onFinish={async (values) => {
              dispatch({ type: "field", field: "loading", payload: true })
              const payload = {
                ...values,
                branchUid: values?.branch ? JSON.parse(values?.branch)?.branchUid : null,
              }

              const authentication = await handleRegistration({
                email: payload?.email,
                password: payload?.password,
              })

              await createRegistrationMutation.mutateAsync(
                {
                  data: {
                    ...payload,
                    authUid: authentication?.user?.uid,
                  },
                },
                {
                  onSuccess: () => {
                    logMutation.mutate({
                      data: {
                        event: "register",
                        context: {
                          type: "self",
                        },
                      },
                    })
                    history.push("/")
                  },
                  onSettled: () => {
                    form.resetFields()
                    dispatch({
                      type: "field",
                      field: "loading",
                      payload: false,
                    })
                  },
                  onError: async (e) => {
                    message.error("Unable able to create account")
                    window.localStorage.clear()
                    window.sessionStorage.clear()
                    await signOutMutation()
                  },
                }
              )
            }}
          >
            {!isLoading ? (
              <>
                {tenant?.selfRegistrationEnabled ? (
                  <>
                    <Typography.Title style={{ color: "white" }} level={3}>
                      Self Registration
                    </Typography.Title>
                    <Form.Item
                      name={"firstName"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name",
                        },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                      name={"lastName"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name",
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email address",
                        },
                        {
                          validator: handleEmail,
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="email@..."
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      placeholder="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirm"
                      placeholder="Confirm Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error("The two passwords that you entered do not match!"))
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name={"identityNumber"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your employee number or ID number",
                        },
                      ]}
                    >
                      <Input placeholder="Employee Number or ID Number" />
                    </Form.Item>
                    <Form.Item
                      name={"country"}
                      rules={[
                        {
                          required: true,
                          message: "Please select a country",
                        },
                      ]}
                    >
                      <Select placeholder="Country" onChange={determineBranch}>
                        {availCountries?.map((country) => (
                          <Select.Option key={country?.name} value={country?.name}>
                            <span style={{ marginRight: ".325rem" }} role="img" aria-label={country?.name}>
                              {country?.flag}
                            </span>
                            {country?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={"branch"}
                      rules={[
                        {
                          required: true,
                          message: "Please select a branch",
                        },
                      ]}
                    >
                      <Select placeholder="Business Unit" onChange={determineCountry}>
                        {availBranches?.map((branch) => (
                          <Select.Option
                            key={branch?.uid}
                            value={JSON.stringify({
                              branch: branch?.name,
                              country: branch?.country,
                              branchUid: branch?.uid,
                            })}
                          >
                            <span style={{ marginRight: ".325rem" }} role="img" aria-label={branch?.country}>
                              {Countries[branch?.country]}
                            </span>
                            {branch?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {state?.showValid && tenant?.validDomainInput && (
                      <Form.Item
                        name={tenant?.validDomainInput?.name}
                        rules={[
                          {
                            required: state?.showValid && tenant?.validDomainInput,
                            message: "Please select a branch",
                          },
                        ]}
                      >
                        <Select placeholder={tenant?.validDomainInput?.label}>
                          {tenant?.validDomainInput?.options?.map((opt, i) => (
                            <Select.Option key={`${i}-${opt?.value}-${opt?.description}`} value={opt?.value}>
                              {opt?.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    {state?.showInvalid && tenant?.invalidDomainInput && (
                      <Form.Item
                        name={tenant?.invalidDomainInput?.name}
                        rules={[
                          {
                            required: state?.showInvalid && tenant?.invalidDomainInput,
                            message: "Please select a branch",
                          },
                        ]}
                      >
                        <Select placeholder={tenant?.invalidDomainInput?.label}>
                          {tenant?.invalidDomainInput?.options?.map((opt, i) => (
                            <Select.Option key={`${i}-${opt?.value}-${opt?.description}`} value={opt?.value}>
                              {opt?.description}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={state?.loading}
                      block
                      style={{ marginBottom: "1rem" }}
                    >
                      Register
                    </Button>
                    <Link to={`/auth/sign-in?tenantId=${tenant?.tenantId}`}>
                      <Button block type="link">
                        Already have an account?
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Typography.Title style={{ color: "white" }} level={1}>
                      Register Now
                    </Typography.Title>
                    <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>
                      Self Registration is not enabled for your tenant
                    </Typography.Text>
                    <Link to={`/auth/sign-in?tenantId=${tenant?.tenantId}`}>
                      <Button type="primary">Go to login page</Button>
                    </Link>
                  </>
                )}
              </>
            ) : (
              <Skeleton active paragraph="Loading company configurations" />
            )}
          </Form>
        </Col>
      </Row>
    </div>
  )
}

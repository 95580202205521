import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useGetTenant } from "../network/useTenant"
import { useQueryParam } from "./useQueryParam"

export const useHandleTenantId = (state, dispatch) => {
  const queryParam = useQueryParam()
  const tenantQuery = useGetTenant({ tenantId: state.tenantId })
  const [awaitTenant, setAwaitTenant] = useState(true)

  const history = useHistory()

  useEffect(() => {
    // window.location.hostname.includes('localhost') ||
    if (window.location.hostname.includes("i-win.io")) {
      setAwaitTenant(false)
    }
    if (!tenantQuery.isLoading && tenantQuery.isSuccess) {
      setAwaitTenant(false)
      let fnTenantId = state?.tenantId

      if (fnTenantId !== tenantQuery?.data?.tenantId && tenantQuery?.data?.tenantId) {
        fnTenantId = tenantQuery?.data?.tenantId
        dispatch({
          type: "field",
          field: "tenantId",
          payload: tenantQuery?.data?.tenantId,
        })
      }

      if (window.location.pathname.includes("register")) {
        dispatch({
          type: "field",
          field: "type",
          payload: tenantQuery?.data?.linkRegistration?.defaultProvider || "email",
        })
      } else {
        dispatch({
          type: "field",
          field: "type",
          payload: tenantQuery?.data?.defaultProvider || "email",
        })
      }
      const filters = {
        tenantId: fnTenantId,
        returnUrl: queryParam.get("returnUrl") || window.localStorage.getItem("returnUrl") || null,
      }

      let params = {}

      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          params = {
            ...params,
            [key]: filters[key],
          }
        }
      })

      let authType
      switch (window.location.pathname) {
        case "/auth/self-register":
        case "/auth/self-register/":
          authType = "self-register"
          break

        case "/auth/register":
        case "/auth/register/":
          authType = "register"
          break

        case "/auth/classroom/":
        case "/auth/classroom":
          authType = "classroom"
          break

        default:
          authType = "sign-in"
          break
      }

      const urlParams = new URLSearchParams(params).toString()
      const path = `/auth/${authType}${urlParams.length ? `?${urlParams}` : ""}`
      history.replace(path)
    }
  }, [tenantQuery.isLoading, tenantQuery.data])

  return {
    tenant: tenantQuery.data,
    isLoading: tenantQuery.isLoading,
    isError: tenantQuery.isError,
    awaitTenant,
  }
}

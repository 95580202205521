import { Image, Row, Space, Tag, Typography } from "antd"
import React from "react"
import { makeProfileImg } from "../hooks/makeProfileImg"

export function TeamModalTitle({ title, tag, imageUrl, children }) {
  return (
    <Row style={{ width: "100%" }} justify="space-between" align="middle">
      <Typography.Text strong>{title}</Typography.Text>
      <Space>
        {typeof tag === "string" ? (
          <Tag color="green" style={{ textTransform: "capitalize" }}>
            {tag}
          </Tag>
        ) : (
          tag
        )}
        {children}
        <Image
          preview={false}
          width={32}
          height={32}
          src={imageUrl || makeProfileImg(title)}
          style={{ borderRadius: "0.375rem", objectFit: "cover" }}
        />
      </Space>
    </Row>
  )
}

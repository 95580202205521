export const tabFactory = (key = "tab") => {
  return {
    get: () => {
      return new URLSearchParams(window.location.search).get(key)
      // return new URLSearchParams(useLocation().search).get(key)
    },
    onChange: (keyValue) => {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search)

      params.set(key, keyValue)
      url.search = params.toString()

      window.history.replaceState({}, "", url.toString())
      // const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${key}=${keyValue}`
      // window.history.pushState({ path: newUrl }, "", newUrl)
    },
  }
}

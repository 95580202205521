import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useQuery, useQueryClient } from "react-query"

export const getEnrolments = async (queryClient, filters) => {
  let params = {}

  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = {
          ...params,
          [key]: filters[key],
        }
      }
    })
  }

  const urlParams = new URLSearchParams(params).toString()
  return axios
    .get(`${process.env.REACT_APP_API_URL}/learn/enrolments${urlParams.length ? `?${urlParams}` : ""}`, {
      headers: {
        Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
        "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
      },
    })
    .then((res) => res.data.data)
}

export const useGetEnrolments = (filters) => {
  const queryClient = useQueryClient()

  return useQuery(["enrolments", filters], () => getEnrolments(queryClient, filters), {
    staleTime: 1000 * 60 * 5,
    retry: false,
    onError: (error) => {
      message.error(`Failed to fetch enrolments: ${error.response.data.message}`)
    },
  })
}

export const useGetClassroom = ({ uid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["classroom", uid],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/classroom/${uid}`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      retry: false,
      enabled: !!uid,
      staleTime: 1000 * 60 * 30,
    }
  )
}

export const useGetTraining = () => {
  const queryClient = useQueryClient()
  return useQuery(
    "training",
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/learn/publications/events`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 15,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch training: ${error.response.data.message}`)
      },
    }
  )
}

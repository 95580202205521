import React, { useEffect, useRef, useState } from "react"
import { useVideoWatchedToCompletion } from "../../hooks/useVideoWatchedToCompletion"

export const VideoCard = ({
  html,
  id,
  setShowNext,
  showNext,
  index,
  position,
}) => {
  const [src, setSrc] = useState(null)
  const videoRef = useRef(null)
  const watchedToCompletion = useVideoWatchedToCompletion(videoRef)

  useEffect(() => {
    if (window.localStorage.getItem(`${id}-watched`) === "true") {
      setShowNext(true)
    } else if (index === position) {
      setShowNext(false)
      if (watchedToCompletion) {
        window.localStorage.setItem(`${id}-watched`, true)
        setShowNext(true)
      }
    } else {
      setShowNext(true)
    }
  }, [watchedToCompletion, index, position])

  React.useEffect(() => {
    const video = document.getElementById(id).querySelector("video")
    if (!video) return

    setSrc(video.src)
  }, [html])

  return (
    <>
      <div
        style={{ display: "none" }}
        id={id}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="video__card">
        <video ref={videoRef} className="ql-video" controls src={src}></video>
      </div>
    </>
  )
}

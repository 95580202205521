import { useAuthSignOut } from "@react-query-firebase/auth"
import { Button, Col, Form, Input, Row, Typography } from "antd"
import React, { useEffect, useReducer } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { auth } from "../firebase"
import { useGetClassroom } from "../network/useEnrolments"
import { useGetEnrolment } from "../network/useEnrolment"
import { useGetTenant } from "../network/useTenant"

function classroomReducer(state, action) {
  const { type, payload, field } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const GoToClassroom = () => {
  const CLASSROOM_REDUCER = {
    loading: false,
    id: null,
    classroomEnrolmentUid: null,
    tenantId: window.localStorage.getItem("tenantId"),
  }

  const [state, dispatch] = useReducer(classroomReducer, CLASSROOM_REDUCER)

  const tenantQuery = useGetTenant({ tenantId: state.tenantId })

  const classroom = window.localStorage.getItem("classroom")
  const classroomOnly = window.localStorage.getItem("classroomOnly")
  const classroomId = window.localStorage.getItem("classroomId")
  const classroomEnrolmentUid = window.localStorage.getItem("classroomEnrolmentUid")
  const classroomPersonUid = window.localStorage.getItem("classroomPersonUid")

  const DEFAULT_BG =
    "https://images.unsplash.com/photo-1612999805994-1eabaef7bb88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"

  const history = useHistory()

  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const classroomQuery = useGetClassroom({
    uid: classroomOnly && classroomId ? classroomId : state.id,
  })

  console.log("state::: ", state)

  const enrolmentQuery = useGetEnrolment({ uid: state?.classroomEnrolmentUid })
  const queryClient = useQueryClient()

  useEffect(async () => {
    if (!classroom || !classroomId || !classroomEnrolmentUid || !classroomPersonUid || !classroomOnly) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      await signOutMutation()
    }
  }, [classroom, classroomId, classroomEnrolmentUid, classroomPersonUid])

  useEffect(() => {
    if (classroomQuery.data && !classroomQuery.isLoading) {
      const eData = classroomQuery.data

      console.log("eData::: ", eData)

      window.localStorage.setItem("classroom", true)
      window.localStorage.setItem("classroomId", state.id)
      window.localStorage.setItem("classroomEnrolmentUid", eData.enrolmentUid)
      window.localStorage.setItem("classroomPersonUid", eData.personUid)

      dispatch({ type: "field", field: "classroomEnrolmentUid", payload: eData.enrolmentUid })
    }
  }, [classroomQuery.data, classroomQuery.isLoading])
  useEffect(() => {
    if (enrolmentQuery.data && !enrolmentQuery.isLoading) {
      const eData = enrolmentQuery.data

      history.push(
        `/enrolments/${eData.uid}/modules/${eData.publication.material[0].uid}/learning/${eData.publication.material[0].learning[0].uid}/${eData.publication.type}s`
      )
    }
  }, [enrolmentQuery.data, enrolmentQuery.isLoading])

  console.log("Logging on the go to classroom component")

  return (
    <div
      className="auth"
      style={{
        backgroundImage: `url(${tenantQuery.data?.featureImageUrl || DEFAULT_BG})`,
      }}
    >
      <Row>
        <Col xs={24} md={12} lg={10} xl={8} className="auth__pannel">
          <Form
            name="login"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={async (values) => {
              dispatch({ type: "field", field: "loading", payload: true })
              dispatch({ type: "field", field: "id", payload: values.id })
            }}
          >
            <Typography.Title style={{ color: "white" }} level={3}>
              Welcome to I•WIN Classroom
            </Typography.Title>
            <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>
              Join your classroom with your ID
            </Typography.Text>
            <Form.Item
              label={<span style={{ color: "white" }}>Your unique Classroom ID</span>}
              name="id"
              rules={[{ required: true, message: "Please enter your classroom ID" }]}
            >
              <Input type="id" label="Classroom ID" placeholder="Classroom ID" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={state?.loading || classroomQuery.isLoading || enrolmentQuery.isLoading}
              block
              style={{ marginBottom: "1rem" }}
            >
              Go to classroom
            </Button>
            <Button
              type="primary"
              htmlType="button"
              onClick={async () => {
                window.localStorage.clear()
                window.sessionStorage.clear()
                await signOutMutation()
              }}
              loading={state?.loading || classroomQuery.isLoading || enrolmentQuery.isLoading}
              block
              style={{ marginBottom: "1rem" }}
            >
              Exit classroom
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

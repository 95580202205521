import {
  Avatar,
  Card,
  Divider,
  Modal,
  Skeleton,
  Space,
  Tree,
  Typography,
} from "antd"
import React, { useState, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  MailOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  StopOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import { makeProfileImg } from "../../hooks/makeProfileImg"
import {
  useGetMemberEnrolments,
  useGetSingleTeam,
} from "../../network/useTeams"
import { TeamModalTitle } from "../../components/TeamModalTitle"
import { TagFactory } from "../../components/TagFactory"

function findMember(members, uid) {
  return members?.find((x) => x?.member?.personUid === uid)
}

export function TeamMemberModal() {
  const [visible, setVisible] = useState(true)

  const { personUid, teamUid } = useParams()
  const { replace } = useHistory()
  const enrolmentsQuery = useGetMemberEnrolments({ personUid })
  const teamQuery = useGetSingleTeam({ teamUid })

  function closeModal() {
    setVisible(false)
    setTimeout(() => replace(`/teams/${teamUid}`), 500)
  }

  const learner = useMemo(() => {
    if (!teamQuery?.isLoading && teamQuery?.data) {
      const mem = findMember(teamQuery?.data?.members, personUid)
      if (mem) return mem
      closeModal()
    }

    if (teamQuery.isError) closeModal()
  }, [teamQuery?.isLoading, teamQuery.data])

  const handleExpiring = (learner) => {
    if (learner?.currentState !== "completed") return ""
    if (!learner?.completionValidUntil) return ""
    const difference = dayjs(learner?.completionValidUntil).diff(
      dayjs(),
      "days"
    )

    if (difference > 0) {
      if (difference > 365) {
        return (
          <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
            Expires in over a year
          </TagFactory>
        )
      } else {
        return (
          <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
            Expires {dayjs().to(learner?.completionValidUntil)}
          </TagFactory>
        )
      }
    } else if (difference < 0) {
      return (
        <TagFactory icon={<StopOutlined />} status="Expired">
          Expired {dayjs(learner?.completionValidUntil).from(dayjs())}
        </TagFactory>
      )
    }
  }

  const treeData = useMemo(() => {
    if (!enrolmentsQuery?.isLoading && enrolmentsQuery?.data) {
      const data = enrolmentsQuery?.data?.enrolments?.map((e) => {
        return {
          key: e?.uid,
          title: (
            <TeamModalTitle
              title={e?.publication?.content?.title}
              tag={
                e?.enrolledAs === "facilitated"
                  ? "In-Person Training"
                  : e?.publication?.type
              }
              imageUrl={e?.publication?.featureImageUrl}
            >
              <Space>
                {handleExpiring(e)}
                <TagFactory status={e?.currentState}>
                  {e?.currentState.toSentenceCase().replace("-", " ")}
                </TagFactory>
              </Space>
            </TeamModalTitle>
          ),
          children: [],
        }
      })
      return data
    }

    if (enrolmentsQuery.isError) closeModal()
  }, [enrolmentsQuery?.isLoading, enrolmentsQuery.data])

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="modal modal--noPadding"
      onCancel={closeModal}
    >
      <div className="modal__body">
        <Card>
          <Card.Meta
            avatar={
              <Avatar
                src={
                  learner?.member?.person?.imageUrl ||
                  makeProfileImg(`${learner?.member?.person?.firstName}}`)
                }
              />
            }
            title={`${learner?.member?.person?.firstName} ${learner?.member?.person?.lastName}`}
            description={
              <Space>
                {learner?.member?.email && (
                  <Space>
                    <MailOutlined />
                    <Typography.Text>{learner?.member?.email}</Typography.Text>
                  </Space>
                )}
                {learner?.member?.tel && (
                  <Space>
                    <PhoneOutlined />
                    <Typography.Text>{learner?.member?.tel}</Typography.Text>
                  </Space>
                )}
              </Space>
            }
          />
        </Card>
        <Divider />
        <Typography.Title level={3}>Learning</Typography.Title>
        {!enrolmentsQuery?.isLoading ? (
          <Tree.DirectoryTree
            className="dropdown--tree"
            showIcon={false}
            treeData={treeData}
          />
        ) : (
          <Skeleton />
        )}
      </div>
    </Modal>
  )
}

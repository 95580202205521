import { Button, Collapse, List } from "antd"
import React, { useState } from "react"
import { useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { fetchEnrolment } from "../network/useEnrolment"
import { useTranslation } from "react-i18next"

export function QuickLinks({ courses, programs }) {
  const [courseSlice, setCourseSlice] = useState(3)
  const [programSlice, setProgramSlice] = useState(3)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  if (!courses?.length && !programs?.length) return null
  return (
    <Collapse defaultActiveKey={["course", "program"]} ghost>
      {courses?.length && (
        <Collapse.Panel header={t("Courses")} key="course">
          <List
            dataSource={courses.slice(0, courseSlice)}
            itemLayout="vertical"
            size="small"
            loadMore={
              courseSlice < courses.length && (
                <Button style={{ marginTop: "1rem" }} onClick={() => setCourseSlice(courses.length)} type="dashed">
                  {t("showMore")} +{courses.length - courseSlice}
                </Button>
              )
            }
            renderItem={(course) => {
              return (
                <List.Item
                  onMouseEnter={() =>
                    queryClient.prefetchQuery([course.uid], () => fetchEnrolment(course?.uid, queryClient), {
                      staleTime: 1000 * 30,
                    })
                  }
                >
                  <Link to={`/${course?.publication?.type}s/${course.uid}`}>{course.publication.content.title}</Link>
                </List.Item>
              )
            }}
          />
        </Collapse.Panel>
      )}
      {programs?.length && (
        <Collapse.Panel header={t("Programs")} key="program">
          <List
            dataSource={programs.slice(0, programSlice)}
            itemLayout="vertical"
            size="small"
            loadMore={
              programSlice < programs.length && (
                <Button style={{ marginTop: "1rem" }} onClick={() => setProgramSlice(programs.length)} type="dashed">
                  {t("showMore")} +{programs.length - programSlice}
                </Button>
              )
            }
            renderItem={(program) => (
              <List.Item
                onMouseEnter={() =>
                  queryClient.prefetchQuery([program.uid], () => fetchEnrolment(program?.uid, queryClient), {
                    staleTime: 1000 * 30,
                  })
                }
              >
                <Link to={`/${program?.publication?.type}s/${program.uid}`}>{program.publication.content.title}</Link>
              </List.Item>
            )}
          />
        </Collapse.Panel>
      )}
    </Collapse>
  )
}

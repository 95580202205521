import { Button, Carousel, Typography } from "antd"
import React from "react"
import useWindowDimensions from "../hooks/useWindowDimensions"

export function Hero({ children, autoplaySpeed = 8000, progress, ...rest }) {
  return (
    <Carousel {...rest} autoplaySpeed={autoplaySpeed}>
      {children}
    </Carousel>
  )
}

Hero.Slide = function ({ children, shadowless = false, img, style, bordered, ...rest }) {
  return (
    <div className={`hero ${shadowless ? "shadowless" : ""}`} {...rest}>
      <div
        style={{
          backgroundImage: img
            ? `url('${img}')`
            : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
        }}
        alt="hero cover"
        className="hero__image"
      />
      <div className="hero__content">{children}</div>
    </div>
  )
}

Hero.Title = function ({ children, level = 1, style = {}, ...rest }) {
  const { width } = useWindowDimensions()
  return (
    <Typography.Title
      ellipsis={{
        rows: 2,
        expandable: true,
        symbol: (
          <Button type="link" style={{ fontSize: "1rem", padding: 0, margin: 0 }}>
            More
          </Button>
        ),
      }}
      style={{ color: "white", ...style }}
      level={width < 768 ? 3 : level}
      {...rest}
    >
      {children}
    </Typography.Title>
  )
}

Hero.Description = function ({ children, level = 1, ...rest }) {
  return (
    <Typography.Paragraph
      style={{ color: "white" }}
      // ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
      className="ellipses--two"
      {...rest}
    >
      {children}
    </Typography.Paragraph>
  )
}

import { Avatar, Button, Col, Drawer, Dropdown, Image, Menu, Row, Skeleton, Space, Spin, Typography } from "antd"
import React, { useState } from "react"
import { BellOutlined, GlobalOutlined, MessageOutlined } from "@ant-design/icons"
import { NavLink, useLocation } from "react-router-dom"
import { useGetPerson, usePersonMutation } from "../network/usePerson"
import { NavDropdown } from "./NavDropdown"
import { SupportModal } from "./SupportModal"
import { HelpDrawer } from "./HelpDrawer"
import { TrainingRerquestDrawer } from "./TrainingRerquestDrawer"
import { useIsThungela } from "../hooks/useIsThungela"
import { useTranslation } from "react-i18next"
import { Countries } from "../constants/Countries"
import { useQueryClient } from "react-query"

export function Navbar() {
  const { pathname } = useLocation()
  const personQuery = useGetPerson({
    authenticated: !window.location.pathname.includes("auth"),
  })
  const { t, i18n } = useTranslation()
  const personMutation = usePersonMutation()
  const queryClient = useQueryClient()

  const [visible, setVisible] = useState(false)
  const [open, setOpen] = useState(false)
  const [trainingRequest, setTrainingRequest] = useState(false)

  const [drawerFormType, setDrawerFormType] = useState(null)
  const homeRoutes = ["", "courses", "programs"]
  const exploreAllowed = ["Thungela-6nv82", "Thungela-oe94g", "Unitrans-t3kkx", "I-WIN-Demo-2lj5l"]
  const isThungela = useIsThungela(personQuery?.data?.company?.tenantId)
  if (
    pathname.split("/")[5] === "learning" ||
    (pathname.split("/")[1] === "externals" && pathname.split("/")[3] === "learning")
  ) {
    return null
  }

  const localeMap = {
    en: {
      country: Countries["United Kingdom"],
      name: "English",
      id: 1,
    },
    fr: {
      country: Countries.France,
      name: "French",
      id: 2,
    },
    es: {
      country: Countries.Spain,
      name: "Spanish",
      id: 3,
    },
    zh: {
      country: Countries.China,
      name: "Mandarin",
      id: 4,
    },
  }

  const generateMenuItems = (tenantId) => {
    const items = [
      {
        label: (
          <NavLink to="/" title="View all content that you're enrolled on">
            {t("myLearning")}
          </NavLink>
        ),
        key: "learning",
      },
    ]
    if (tenantId && exploreAllowed.includes(tenantId)) {
      items.push({
        label: (
          <NavLink to="/explore" title="Explore our learning library of either self-enrolled or SETA online courses">
            {t("Explore")}
          </NavLink>
        ),
        key: "explore",
      })
    }
    if (tenantId) {
      items.push({
        label: (
          <NavLink to="/teams" title="Manage, review team member/s activity and make course bookings">
            {t("Teams")}
          </NavLink>
        ),
        key: "teams",
      })
    }
    return items
  }

  const getOrgImage = (pQuery) => {
    if (pQuery?.data && !pQuery?.isLoading) {
      if (
        pQuery.data?.company?.configuration?.products &&
        Object.prototype.hasOwnProperty.call(pQuery.data?.company?.configuration?.products, window.location.origin)
      ) {
        return pQuery.data?.company?.configuration?.products?.[window.location.origin]?.imageUrl
      } else if (pQuery.data?.company?.imageUrl) {
        return pQuery.data?.company?.imageUrl
      }
      return "iwinlogo.png"
    }
  }

  return (
    <Row className="navbar hide--aboveMedium">
      <SupportModal
        visible={visible}
        setVisible={setVisible}
        support={personQuery?.data?.company?.configuration?.products?.learn?.support}
      />
      <HelpDrawer
        personQuery={personQuery}
        visible={open}
        onClose={() => setOpen(false)}
        drawerFormType={drawerFormType}
      />

      {!personQuery.isLoading ? (
        <>
          <Col span={6}>
            <Image
              preview={false}
              height="2.5rem"
              style={{
                width: "2.5rem",
                objectFit: "cover",
                borderRadius: "0.625rem",
              }}
              src={getOrgImage(personQuery)}
              fallback="/iwinlogo.png"
            />
          </Col>
          <Col flex={"auto"} className="hide--aboveMedium">
            <Menu
              items={generateMenuItems(personQuery.data?.companyPerson?.company?.tenantId)}
              className="navbar__list"
              selectedKeys={homeRoutes.includes(pathname.split("/")[1]) ? "learning" : pathname.split("/")[1]}
              mode="horizontal"
            />
          </Col>
          <Col className="nav__person" span={6}>
            <Space>
              {personQuery?.data?.companyPerson?.admin && (
                <a href={`https://admin.${window.location.host}`}>
                  <Button type="primary" size="small">
                    Switch to admin
                  </Button>
                </a>
              )}
              {isThungela ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          setOpen(true)
                          setDrawerFormType("query")
                        }}
                        key={"support query"}
                      >
                        Support Query
                      </Menu.Item>
                      <Menu.Item
                        key={"Course creation request"}
                        onClick={() => {
                          setOpen(true)
                          setDrawerFormType("course")
                        }}
                      >
                        Course Creation Request
                      </Menu.Item>
                      <Menu.Item
                        key={"Content Creation Form"}
                        onClick={() => {
                          setOpen(true)
                          setDrawerFormType("content")
                        }}
                      >
                        Content Creation Form
                      </Menu.Item>
                      <Menu.Item
                        key={"Training Request Form"}
                        onClick={() => {
                          setOpen(true)
                          setDrawerFormType("training")
                        }}
                      >
                        Training Request Form
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="link">{t("Help")}</Button>
                </Dropdown>
              ) : personQuery?.data?.companyPerson?.company?.tenantId.includes("Brew") ? (
                <a href="mailto:support@brewmastersacademy.com?subject=Support%20Query&body=Query%20type%0D%0A%0D%0AAccess%0D%0A%0D%0ARegistration%0D%0A%0D%0AReporting%0D%0A%0D%0ACompletion%0D%0A%0D%0ABookings%0D%0A%0D%0AReset%20Assessment%0D%0A%0D%0AOther%20-%20Please%20explain%0D%0A%0D%0A%0D%0A%0D%0APlease%20copy%20the%20URL%20of%20the%20page%20where%20you%20are%20encountering%20the%20issue.%0D%0A%0D%0A%0D%0A%0D%0APlease%20attach%20screenshots%20of%20the%20issue%20so%20that%20we%20can%20better%20assist%20you.">
                  {t("Help")}
                </a>
              ) : (
                <a href="mailto:support@winwinza.com?subject=Support%20Query&body=Query%20type%0D%0A%0D%0AAccess%0D%0A%0D%0ARegistration%0D%0A%0D%0AReporting%0D%0A%0D%0ACompletion%0D%0A%0D%0ABookings%0D%0A%0D%0AReset%20Assessment%0D%0A%0D%0AOther%20-%20Please%20explain%0D%0A%0D%0A%0D%0A%0D%0APlease%20copy%20the%20URL%20of%20the%20page%20where%20you%20are%20encountering%20the%20issue.%0D%0A%0D%0A%0D%0A%0D%0APlease%20attach%20screenshots%20of%20the%20issue%20so%20that%20we%20can%20better%20assist%20you.">
                  {t("Help")}
                </a>
              )}
              {personQuery?.data?.company?.tenantId.toLowerCase().includes("seriti") && (
                <a
                  href="https://storage.googleapis.com/winwin-content/Seriti videos/Seriti_Learner Guide (1).pdf"
                  target="_blank"
                >
                  FAQ
                </a>
              )}
              {personQuery?.data?.company?.configuration?.products?.learn?.locales?.length && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          i18n.changeLanguage("en")
                          personMutation.mutate(
                            {
                              data: {
                                preference: {
                                  defaultLanguageId: 1,
                                },
                              },
                            },
                            {
                              onSuccess: () => {
                                window.location.reload()
                                // queryClient.invalidateQueries()
                              },
                            }
                          )
                        }}
                      >
                        {Countries["United Kingdom"]} <span style={{ marginLeft: "5px" }}>English</span>
                      </Menu.Item>
                      {personQuery?.data?.company?.configuration?.products?.learn?.locales
                        ?.filter((x) => x !== "en")
                        ?.map((locale, index) => (
                          <Menu.Item
                            key={locale + index}
                            onClick={() => {
                              i18n.changeLanguage(locale)
                              personMutation.mutate(
                                {
                                  data: {
                                    preference: {
                                      defaultLanguageId: localeMap?.[locale].id,
                                    },
                                  },
                                },
                                {
                                  onSuccess: () => {
                                    window.location.reload()
                                  },
                                }
                              )
                            }}
                          >
                            {localeMap?.[locale].country}{" "}
                            <span style={{ marginLeft: "5px" }}>{localeMap?.[locale].name}</span>
                          </Menu.Item>
                        ))}
                    </Menu>
                  }
                >
                  <Button size="small" loading={personMutation.isLoading}>
                    {i18n.language === "en" && Countries["United Kingdom"]}
                    {i18n.language === "fr" && Countries.France}
                    {i18n.language === "zh" && Countries.China}
                    {i18n.language === "es" && Countries.Spain}
                    <span
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {i18n.language}
                    </span>
                  </Button>
                </Dropdown>
              )}
              <NavLink to="/chat" title="Chat with your peers, groups and teams on learning content">
                <Button className="chat__yellow--icon" type="link" icon={<MessageOutlined />} />
              </NavLink>

              {isThungela && <TrainingRerquestDrawer visible={trainingRequest} setVisible={setTrainingRequest} />}
              <Dropdown title="Manage your profile and view your progress" overlay={<NavDropdown />}>
                <Space>
                  <Avatar src={personQuery.data?.imageUrl} />
                  <Typography.Text className="hide--onMobile" ellipsis>
                    {personQuery.data?.firstName} {personQuery.data?.lastName}
                  </Typography.Text>
                </Space>
              </Dropdown>
            </Space>
          </Col>
        </>
      ) : (
        <Space>
          <Skeleton.Avatar />
          <Skeleton.Input />
        </Space>
      )}
    </Row>
  )
}

import React, { useState, useEffect } from "react"
import { Form, Input, Space } from "antd"
import { Draggable } from "../Draggable"
import uuidv4 from "../../hooks/uuid"

export function FillInMissing({
  assessment,
  question,
  questionIndex,
  form,
  dispatch,
  isFacilitate = false,
  disabled = false,
  inReview = false,
}) {
  const [answers, setAnswers] = useState([])
  const [options, setOptions] = useState([])

  const handleFillInMissing = (key, answers, i) => {
    const noOfQuestions = assessment.questions[parseInt(key.split("-")[1]) - 1].answers.filter((x) => x.correct).length
    const values = form.getFieldValue(key) || Array(noOfQuestions).fill([])
    values[i] = answers[0]

    let noEmpties = true

    values.forEach((value, i) => {
      if (!value) noEmpties = false
    })

    if (noEmpties) dispatch({ type: "field", field: "canProceed", payload: true })
    else
      dispatch({
        type: "throwError",
        payload: `Please select ${noOfQuestions}`,
      })

    form.setFieldsValue({
      [key]: values,
    })
  }

  useEffect(() => {
    const answers = form.getFieldValue(`q-${questionIndex + 1}`) || []
    const ansUids = answers.map((x) => x.uid) || []

    const filteredQuestions = question.answers.filter((option) => !ansUids.includes(option?.uid))
    setAnswers(answers)
    setOptions(filteredQuestions)
  }, [])

  return (
    <>
      <Form.List className="sr-only" name={`q-${questionIndex + 1}`}>
        {(fields) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                <Form.Item hidden {...restField} name={[name, "uid"]}>
                  <Input placeholder="uid" />
                </Form.Item>
                <Form.Item hidden {...restField} name={[name, "correct"]}>
                  <Input placeholder="correct" />
                </Form.Item>
                <Form.Item hidden {...restField} name={[name, "title"]}>
                  <Input placeholder="title" />
                </Form.Item>
              </Space>
            ))}
          </>
        )}
      </Form.List>
      <div className="fill__missing fill__missing--inline">
        {(() => {
          let dragCounter
          return (
            <>
              {question.translations["1"]?.title.split(" ").map((string, i) => {
                if (string !== "") {
                  if (string !== "[$]") {
                    return <p key={uuidv4()}>{string}</p>
                  }
                  if (dragCounter === undefined) {
                    dragCounter = 0
                  } else dragCounter++
                  if (inReview) {
                    const ans = form?.getFieldValue(`q-${questionIndex + 1}`)[dragCounter]
                    return (
                      <div
                        className={`missing__opt ${
                          isFacilitate !== "true" && parseInt(ans.correct) === dragCounter
                            ? "missing__opt--correct"
                            : "missing__opt--wrong"
                        }`}
                        key={`${ans.uid}__${dragCounter}`}
                      >
                        {ans.title}
                      </div>
                    )
                  }
                  return (
                    <Draggable
                      key={dragCounter}
                      onChange={handleFillInMissing}
                      index={dragCounter}
                      question={`q-${questionIndex + 1}`}
                      direction="horizontal"
                      className="dropzone dropzone--single empty"
                    >
                      <p
                        data-correct={answers[dragCounter]?.correct}
                        data-uid={answers[dragCounter]?.uid}
                        key={answers[dragCounter]?.uid}
                        className="missing__opt"
                      >
                        {answers[dragCounter]?.title}
                      </p>
                    </Draggable>
                  )
                }
                return null
              })}
            </>
          )
        })()}
      </div>
      {!inReview && (
        <Draggable direction="horizontal" className="dropzone  dropzone--smallMTop empty">
          {options.map((answer) => (
            <p data-correct={answer.correct} data-uid={answer.uid} key={answer.uid} className="missing__opt">
              {answer.translations["1"]?.title}
            </p>
          ))}
        </Draggable>
      )}
    </>
  )
}

import {
  AppstoreOutlined,
  BarsOutlined,
  MessageOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { Segmented } from "antd"
import React, { useCallback, useMemo, useState } from "react"
import { useGetPerson } from "../network/usePerson"
import { useLocation, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const MobileNavbar = () => {
  const { pathname } = useLocation()
  const [segment, setSegment] = useState(`/${pathname?.split("/")[1]}`)
  const { push } = useHistory()
  const personQuery = useGetPerson({
    authenticated: !window.location.pathname.includes("auth"),
  })
  const { t } = useTranslation()
  const exploreAllowed = ["Thungela-6nv82", "Thungela-oe94g", "Unitrans-t3kkx", "Tempero-kjm9i"]

  const options = useMemo(() => {
    const options = [
      {
        label: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              padding: "6px",
              lineHeight: "12px",
              fontSize: "12px",
            }}
          >
            <UnorderedListOutlined />
            <span>{t("Learning")}</span>
          </div>
        ),
        value: "/",
      },
      {
        label: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              padding: "6px",
              lineHeight: "12px",
              fontSize: "12px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-users"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
            <span>{t("Teams")}</span>
          </div>
        ),
        value: "/teams",
      },
    ]
    if (personQuery.data && !personQuery.isLoading) {
      if (
        personQuery.data?.companyPerson?.company?.tenantId &&
        exploreAllowed.includes(personQuery.data?.companyPerson?.company?.tenantId)
      ) {
        options.push({
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                padding: "6px",
                lineHeight: "12px",
                fontSize: "12px",
              }}
            >
              <SearchOutlined />
              <span>{t("Explore")}</span>
            </div>
          ),
          value: "/explore",
        })
      }
    }
    options.push({
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            padding: "6px",
            lineHeight: "12px",
            fontSize: "12px",
          }}
        >
          <UserOutlined />
          <span>{t("Profile")}</span>
        </div>
      ),
      value: "/profile",
    })

    return options
  }, [personQuery.data, personQuery.isLoading])

  const handleChange = useCallback((e) => {
    setSegment(e)
    push(e)
  }, [])

  if (
    pathname.split("/")[5] === "learning" ||
    (pathname.split("/")[1] === "externals" && pathname.split("/")[3] === "learning")
  ) {
    return null
  }
  return (
    <div className="mobileNav onMobile--show">
      <Segmented block options={options} value={segment} onChange={handleChange} />
    </div>
  )
}

export const progressFactory = {
  course: (enrolment) => {
    const total =
      Math.floor(Number(enrolment?.publication?.materialCount) ?? 0) +
      Math.floor(Number(enrolment?.publication?.lessonCount) ?? 0) +
      Math.floor(Number(enrolment?.publication?.lessonMaterialCount) ?? 0) +
      Math.floor(Number(enrolment?.publication?.assessmentCount) ?? 0) +
      Math.floor(Number(enrolment?.publication?.assessmentQuestionCount) ?? 0) +
      Math.floor(Number(enrolment?.publication?.assignmentCount) ?? 0)
    const completed = enrolment?.activityCount || 0

    let resultAsPercent = 0

    if (total) {
      resultAsPercent = (completed / total) * 100 > 100 ? 100 : (completed / total) * 100
    }

    if (enrolment?.currentState === "completed") resultAsPercent = 100

    return {
      total,
      completed,
      resultAsPercent: Math.floor(resultAsPercent),
    }
  },
  program: (enrolment) => {
    const total =
      Number(enrolment?.publication?.materialCount) +
      Number(enrolment?.publication?.lessonCount) +
      Number(enrolment?.publication?.lessonMaterialCount) +
      Number(enrolment?.publication?.assessmentCount) +
      Number(enrolment?.publication?.assessmentQuestionCount) +
      Number(enrolment?.publication?.assignmentCount)
    const completed = enrolment?.activityCount || 0

    let resultAsPercent = 0

    if (total) {
      resultAsPercent = (completed / total) * 100 > 100 ? 100 : (completed / total) * 100
    }

    if (enrolment?.currentState === "completed") resultAsPercent = 100

    return {
      total,
      completed,
      resultAsPercent: Math.floor(resultAsPercent),
    }
  },
  module: (module, actions) => {
    const total = []
    let completed = 0

    module?.learning?.forEach((learning) => {
      switch (`${learning?.kind}-${learning?.type || ""}`) {
        case "lesson-card":
          total.push(learning?.uid)
          // if (learning?.content?.materialAsJSON) {
          //   total?.push(...learning?.content?.materialAsJSON?.map(z => (z?.id)))
          // }
          break
        case "assessment-":
          total.push(learning?.uid)
          total?.push(...learning?.questions?.map((z) => z?.uid))
          break
        default:
          total.push(learning?.uid)
          break
      }
    })
    completed = total?.filter((x) =>
      actions?.find((y) => y?.uid === x && (y?.status === "completed" || y?.status === "submitted"))
    )?.length
    let resultAsPercent = (completed / total?.length) * 100 > 100 ? 100 : (completed / total?.length) * 100
    if (actions.some((x) => x?.uid === module?.uid && x?.status === "completed")) resultAsPercent = 100
    return {
      total: total?.length || 0,
      completed,
      resultAsPercent: Math.round(resultAsPercent),
    }
  },
}

import { useCallback, useEffect, useReducer, useState } from "react"
import db, { config } from "../firebase"
import { useEnrolmentActivtyMutation } from "../network/useEnrolmentActivity"
import { makeId } from "../hooks/uuid"
import { doc, onSnapshot } from "firebase/firestore"

export const useScormMessenger = (enrolmentUid, person, actions, scormMaterial, lessonUid, isPreviewing = false) => {
  const [terminated, setTerminated] = useState(false)
  const [isMounted] = useReducer((p) => !p, true)
  const [scormStatus, setScormStatus] = useState(null)
  const [completedForPreview, setCompletedForPreview] = useState(false)
  // const lesson = useLesson(lessonUid)
  // connected state to be setn once if only when person is loaded and ping has been recieved
  // end learning remove iframe

  const activityMutation = useEnrolmentActivtyMutation()

  const keysToDetermineCompleted = ["cmi.core.lesson_status", "cmi.completion_status"]
  const item = actions?.find((x) => x?.typeUid === lessonUid)

  let data = item?.context || {}

  const receiveMessage = useCallback(
    async (event) => {
      // console.log("scorm for::: ", lessonUid)
      const node = window.frames.scormPlayer

      if (event.data === "ping") {
        node?.postMessage("pong", `https://${config.storageBucket}`)
        // console.log("about to send::: ", {
        //   init: {
        //     url: scormMaterial?.url?.split("?url=")?.[1],
        //   },
        // })
        node.postMessage(
          JSON.stringify({
            init: {
              url: scormMaterial?.url?.split("?url=")?.[1],
            },
          }),
          "*"
        )
        if (scormMaterial?.version === "cmi5") {
          node.postMessage(
            JSON.stringify({
              cmi5: {
                endpoint: `${process.env.REACT_APP_API_URL}/lrs${isPreviewing ? "/preview" : ""}`,
                fetch: `${process.env.REACT_APP_API_URL}/lrs/authToken?key=${makeId(6)}`,
                actor: {
                  objectType: "Agent",
                  account: {
                    homePage: `${process.env.REACT_APP_URL}/profile/${person?.uid}`,
                    name: `${person?.firstName} ${person?.lastName}`,
                  },
                },
                registration: enrolmentUid,
                activityId: lessonUid,
              },
            }),
            "*"
          )
        } else {
          const lms = {
            ...data,
            isTracking: !terminated && !isPreviewing,
            isReviewing: isPreviewing,
            "cmi.core.student_id": person?.uid,
            "cmi.core.student_name": `${person?.firstName} ${person?.lastName}`,
          }

          node.postMessage(
            JSON.stringify({
              lms,
            }),
            "*"
          )
        }
      } else if (!terminated) {
        switch (event.data.method) {
          case "setValue": {
            const keys = Object.keys(event.data.data)

            if (keys.some((k) => keysToDetermineCompleted.indexOf(k) >= 0)) {
              for (const key of keys) {
                const index = keysToDetermineCompleted.indexOf(key)

                if (index > -1) {
                  const endStatus = ["completed", "passed"]

                  if (endStatus.includes(event.data.data[key])) {
                    setTerminated(true)
                  }
                }
              }
            }
            data = { ...data, ...event.data.data }
            return
          }
          case "commit": {
            console.log("commiting::: ", event)
            if (!isPreviewing) {
              return activityMutation.mutate({
                publicationEnrolmentUid: enrolmentUid,
                data: {
                  context: data,
                  type: scormMaterial?.version === 'cmi5' ? 'lrs-activity': "scorm",
                  typeUid: lessonUid,
                },
              })
            }
          }
          case "terminate": {
            console.log("terminating::: ", event)
            break
          }
          default:
            break
        }
      }
    },
    [lessonUid, enrolmentUid]
  )

  useEffect(() => {
    console.log("scormStatus::: ", scormStatus)
    if (scormStatus === "completed") {
      setTerminated(true)
    }
  }, [scormStatus, scormMaterial, lessonUid])

  useEffect(() => {
    console.log("id::: ", `${enrolmentUid}-${lessonUid}`)
    const isScormComplete = actions?.some((x) => x.uid === lessonUid && x.status === "completed")
    console.log("isScormComplete::: ", isScormComplete)
    if (isScormComplete || isPreviewing) {
      setCompletedForPreview(true)
    }
    let unsub = () => {}
    if (!isPreviewing && scormMaterial?.version === "cmi5") {
      console.log("Runninh CMI5")
      unsub = onSnapshot(doc(db, "cmi5", `${enrolmentUid}-${lessonUid}`), (doc) => {
        setScormStatus(doc.data()?.status)
      })
    }

    window.addEventListener("message", receiveMessage, false)
    return () => {
      window.removeEventListener("message", receiveMessage)
      if (!isPreviewing) {
        unsub()
      }
    }
  }, [scormMaterial, lessonUid, actions])

  return { terminated, isMounted, completedForPreview }
}

import { useAuthSignInWithRedirect } from "@react-query-firebase/auth"
import { Button, Typography } from "antd"
import React from "react"
import { WindowsFilled } from "@ant-design/icons"
import { provider } from "../../firebase"

export function Microsoft({
  children,
  auth,
  tenant,
  state,
  dispatch,
  title = "Sign In",
  description = "Sign in with your work account using Single Sign on.",
  customText,
}) {
  const microsoftMutation = useAuthSignInWithRedirect(auth)
  const handleMicrosoftAuth = () => {
    if (tenant) {
      provider.setCustomParameters({
        tenant,
      })
    }
    dispatch({ type: "field", field: "loading", payload: true })
    microsoftMutation.mutate({
      provider,
    })
  }
  return (
    <>
      <Typography.Title style={{ color: "white" }} level={3}>
        {title}
      </Typography.Title>
      <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>{description}</Typography.Text>
      <Button
        block
        loading={state.loading}
        icon={<WindowsFilled />}
        type="primary"
        onClick={() => handleMicrosoftAuth()}
        htmlType="button"
      >
        {customText || "Sign in with Microsoft"}
      </Button>
      {children}
    </>
  )
}

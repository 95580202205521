import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useMutation, useQueryClient } from "react-query"

export const useTrainingMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/learn/publications/requests`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries("team"),
      onError: (error) => {
        message.error(`Failed to create training request: ${error?.response?.data?.message}`)
      },
    }
  )
}
export const useUpdateTrainingMutation = (docId) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (body) =>
      axios
        .put(`${process.env.REACT_APP_API_URL}/learn/publications/requests/${docId}`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries("team"),
      onError: (error) => {
        message.error(`Failed to update training request: ${error?.response?.data?.message}`)
      },
    }
  )
}

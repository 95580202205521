import React from "react"
import { render } from "react-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import "./App.less"
import { ReactQueryDevtools } from "react-query/devtools"
import { BrowserRouter as Router } from "react-router-dom"
import './i18n';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <App />
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

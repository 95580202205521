import { Tag } from "antd"
import React from "react"

export function TagFactory({ status, children, icon = null, ...rest }) {
  const [color] = React.useState(() => {
    switch (status) {
      case "Not Started":
      case "not-started":
      case "expiring":
      case "Expiring":
      case "pdf":
        return "orange"

      case "requested":
      case "Requested":
      case "In Progress":
      case "in-progress":
      case "video":
        return "blue"

      case "declined":
      case "Declined":
      case "failed":
      case "Failed":
      case "Expired":
      case "expired":
      case "Rejected":
      case "rejected":
        return "red"

      case "Completed":
      case "completed":
      default:
        return "green"
    }
  })
  return (
    <Tag style={{ textTransform: "capitalize" }} {...rest} icon={icon} color={rest?.color ? rest?.color : color}>
      {children}
    </Tag>
  )
}

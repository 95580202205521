import React, { useEffect } from "react"
import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Comment,
  Divider,
  Empty,
  Form,
  Input,
  Rate,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Timeline,
  Typography,
} from "antd"
import { EditOutlined, SendOutlined } from "@ant-design/icons"
import { useParams, Link, useLocation } from "react-router-dom"
import { useEnrolmentMutation, useGetEnrolment } from "../../../network/useEnrolment"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import { Hero } from "../../../components/Hero"
import { Container } from "../../../components/Container"
import { makeProfileImg } from "../../../hooks/makeProfileImg"
import { generateKey } from "../../../hooks/generateKey"
import { tabFactory } from "../../../helpers/tabsFactory"
import { ExternalEnrolmentActionButton } from "../../../components/ExternalEnrolmentActionButton"

const { Title } = Typography
const { Panel } = Collapse

export function Externals() {
  const { externalUid } = useParams()
  const enrolmentQuery = useGetEnrolment({ uid: externalUid })
  const windowDimensions = useWindowDimensions()
  const reviewMutation = useEnrolmentMutation(externalUid)
  const [form] = Form.useForm()
  const { pathname } = useLocation()

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      {!enrolmentQuery.isLoading ? (
        <>
          {enrolmentQuery.data && !enrolmentQuery.isError ? (
            <>
              <Link className="floatingBack floatingBack--fixed floatingBack--dark" to="/">
                Back
              </Link>
              <Hero autoplay>
                <Hero.Slide img={enrolmentQuery.data.publication.featureImageUrl}>
                  <Comment
                    avatar={
                      enrolmentQuery.data.publication.publishedBy?.imageUrl ||
                      makeProfileImg(`${enrolmentQuery.data.publication.publishedBy?.name[0]}`)
                    }
                    author={<p style={{ color: "white", marginBottom: 0 }}>Publisher</p>}
                    content={enrolmentQuery.data.publication.publishedBy?.name}
                  />
                  <Hero.Title>{enrolmentQuery.data.publication.translations["1"]?.title}</Hero.Title>
                  <Hero.Description>{enrolmentQuery.data.publication.translations["1"]?.summary}</Hero.Description>

                  <ExternalEnrolmentActionButton enrolment={enrolmentQuery?.data} />
                </Hero.Slide>
              </Hero>
              <Container style={{ marginTop: "2.5rem" }}>
                {enrolmentQuery?.data?.publication?.material ? (
                  <Tabs
                    style={{ marginTop: "1rem", padding: "0 1rem" }}
                    defaultActiveKey={tabFactory().get() || "1"}
                    onChange={(keyValue) => tabFactory().onChange(keyValue)}
                  >
                    <Tabs.TabPane tab="About" key="About">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: enrolmentQuery.data.publication.translations["1"]?.descriptionAsHTML,
                        }}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Modules" key="Modules">
                      <Row gutter="32">
                        <Col xs={24} md={18}>
                          <Collapse className="no-padding" ghost defaultActiveKey={["1", "2"]}>
                            <Panel header={<Title level={5}>Modules</Title>} key="1">
                              <ul className="learning--list">
                                <Link to={`/externals/${externalUid}/learning`} className="hoverable">
                                  <Typography.Text strong>
                                    {enrolmentQuery.data.publication.translations["1"]?.title}
                                  </Typography.Text>
                                  <div className="details">
                                    <Badge
                                      color="green"
                                      text={
                                        <span className="badge--meta">{`${
                                          enrolmentQuery.data.publication.duration[
                                            Object.keys(enrolmentQuery.data.publication.duration)[0]
                                          ]
                                        }: ${Object.keys(enrolmentQuery.data.publication.duration)[0]}`}</span>
                                      }
                                    />
                                    <Tag className="capitalize" color="green">
                                      Lesson: Scorm
                                    </Tag>
                                  </div>
                                </Link>
                              </ul>
                            </Panel>
                            {enrolmentQuery.data.publication.translations["1"]?.learningOutcomes?.length && (
                              <Panel header={<Title level={5}>Learning Outcomes</Title>} key="2">
                                <Timeline style={{ marginTop: "1rem" }}>
                                  {enrolmentQuery.data.publication.translations["1"]?.learningOutcomes?.map(
                                    (outcome) => (
                                      <Timeline.Item key={generateKey(outcome)}>{outcome}</Timeline.Item>
                                    )
                                  )}
                                </Timeline>
                              </Panel>
                            )}
                          </Collapse>
                        </Col>
                        <Col xs={24} md={6}>
                          <Title level={5}>{enrolmentQuery.data.feedback ? "Reviews" : "Leave a Review"}</Title>
                          {!enrolmentQuery.data?.feedback && (
                            <>
                              <p className="badge--meta">Rate out of 5</p>
                              <Form
                                name="review"
                                form={form}
                                onFinish={(values) => {
                                  reviewMutation.mutate(
                                    {
                                      enrolmentUid: externalUid,
                                      data: {
                                        feedback: {
                                          ...values,
                                        },
                                      },
                                    },
                                    {
                                      onSuccess: () => form.resetFields(),
                                    }
                                  )
                                }}
                              >
                                <Form.Item style={{ margin: 0 }} name="rating" rules={[{ required: true }]}>
                                  <Rate />
                                </Form.Item>
                                <div className="filter no-marg-top">
                                  <Form.Item
                                    className="filter__input filter__input--noMargin"
                                    name="comment"
                                    rules={[{ required: true }]}
                                  >
                                    <Input
                                      bordered={false}
                                      placeholder="Leave a comment..."
                                      prefix={<EditOutlined />}
                                    />
                                  </Form.Item>
                                  <Button
                                    loading={reviewMutation.isLoading}
                                    htmlType="submit"
                                    type="primary"
                                    icon={<SendOutlined />}
                                    size="small"
                                  />
                                </div>
                              </Form>
                              <Divider />
                            </>
                          )}
                          {enrolmentQuery.data.publication?.reviews?.map((review, i) => (
                            <Comment
                              key={i}
                              author={<Link to="/">{review?.name}</Link>}
                              avatar={<Avatar src={review?.imageUrl || makeProfileImg(`${review?.name}`)} />}
                              content={<p>{review.comment}</p>}
                              datetime={`${review.rating}/5`}
                            />
                          ))}
                        </Col>
                      </Row>
                    </Tabs.TabPane>
                  </Tabs>
                ) : (
                  <Empty
                    image="/empty.png"
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>This course does not have any modules.</span>}
                  >
                    <Button href="/" type="primary">
                      Back to {">"} My Learning
                    </Button>
                  </Empty>
                )}
              </Container>
            </>
          ) : (
            <Empty
              image="/empty.png"
              imageStyle={{
                height: 60,
              }}
              description={<span>You are not enrolled to any courses</span>}
            >
              <Button href="/" type="primary">
                Back to My Learning
              </Button>
            </Empty>
          )}
        </>
      ) : (
        <>
          <Skeleton.Input style={{ width: windowDimensions.width, height: "40vh" }} active />
          <Container style={{ marginTop: "2.5rem" }}>
            <Row gutter="32">
              {[1, 1, 1].map((_, i) => (
                <Col xs={24} md={12} lg={8} key={i}>
                  <Card size="small" bordered={false} className="smallCard">
                    <Skeleton active />
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
    </>
  )
}

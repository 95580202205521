function generateWeightedTotal(grades = [], weighting) {
  const WEIGHTING_FACTOR = weighting / 100
  const AVG_GRADE = grades?.length
    ? grades.reduce((a, b) => a + b, 0) / grades?.length
    : 100

  return AVG_GRADE * WEIGHTING_FACTOR
}

export const generateResult = {
  course: (enrolment) => {
    const assessmentActivities = enrolment?.activity?.filter(
      (x) => x?.type === "assessment"
    )
    let total = 0
    enrolment?.publication?.material?.forEach((module) => {
      const grades = module?.learning
        ?.filter((x) => x?.kind === "assessment")
        .map(
          (y) =>
            assessmentActivities?.find((a) => a?.typeUid === y?.uid)?.context
              ?.submissions[
              assessmentActivities?.find((a) => a?.typeUid === y?.uid)?.context
                ?.submissions?.length - 1
            ]?.resultAsPercentage || 0
        )
      total += generateWeightedTotal(grades, Number(module?.weight) ?? 0)
    })
    return total
  },
  program: (enrolment) => {
    const assessmentActivities = enrolment?.activity?.filter(
      (x) => x?.type === "assessment"
    )
    let total = 0
    enrolment?.publication?.material?.forEach((section) => {
      section?.modules.forEach((module) => {
        const grades = module?.learning
          ?.filter((x) => x?.kind === "assessment")
          .map(
            (y) =>
              assessmentActivities?.find((a) => a?.typeUid === y?.uid)?.context
                ?.submissions[
                assessmentActivities?.find((a) => a?.typeUid === y?.uid)
                  ?.context?.submissions?.length - 1
              ]?.resultAsPercentage || 0
          )
        total += generateWeightedTotal(grades, Number(module?.weight) ?? 0)
      })
    })
    return total
  },
}

import React, { useState } from "react"

export const QuizCard = ({ html, id }) => {
  const [elements, setElements] = useState([])
  const [selectedEl, setSelectedEl] = useState("")
  const handleAnswer = (id) => {
    const el = document.getElementById(id)
    if (!el) return null
    setSelectedEl(
      `${id}:${
        el.dataset.isCorrect === "true"
          ? "quiz__opt--correct"
          : "quiz__opt--wrong"
      }`
    )
  }
  React.useEffect(() => {
    const els = document
      .getElementById(id)
      .querySelectorAll("[data-is-correct]")
    setElements(
      <div className="interactive">
        <h2>Knowledge Card</h2>
        <p
          dangerouslySetInnerHTML={{
            __html:
              document.getElementById(id)?.childNodes[0]?.childNodes[1]
                ?.childNodes?.[0]?.innerHTML,
          }}
        />
        {[...els].map((el, i) => (
          <div
            onClick={() => handleAnswer(`${id}-${i}`)}
            key={`${id}-${i}`}
            id={`${id}-${i}`}
            className={
              selectedEl?.split(":")[0] === `${id}-${i}`
                ? `quiz__opt ${selectedEl?.split(":")[1]}`
                : "quiz__opt"
            }
            data-is-correct={el?.dataset?.isCorrect}
          >
            {el.innerHTML}
          </div>
        ))}
      </div>
    )
  }, [selectedEl])

  return (
    <>
      <div
        style={{ display: "none" }}
        id={id}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      {elements}
    </>
  )
}

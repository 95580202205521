import {
  CommentOutlined,
  DeleteOutlined,
  DislikeOutlined,
  HeartOutlined,
  HeartTwoTone,
  InboxOutlined,
  LikeOutlined,
  MoreOutlined,
  PushpinOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons"
import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { Hero } from "./Hero"
import db from "../firebase"
import { arrayUnion, deleteDoc, doc, getDoc, onSnapshot, orderBy, query, setDoc, updateDoc } from "firebase/firestore"
import dayjs from "dayjs"
import { useGetPerson } from "../network/usePerson"
import { nonHookUploader } from "../hooks/nonHookUploader"
import { useMedia } from "../network/useMedia"
import uuidv4 from "../hooks/uuid"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const TeamForumPane = ({ team }) => {
  const [sending, setSending] = useState(false)
  const personQuery = useGetPerson({ authenticated: true })
  const [messages, setMessages] = useState([])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const mutator = useMedia()
  const [deleting, setDeleting] = useState(false)
  const [pinIdLoading, setPinIdLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    const unsub = onSnapshot(doc(db, "forums", team.uid), (doc) => {
      if (doc.exists()) {
        setMessages(
          doc.data().messages.sort((a, b) => {
            if (a.pinned && !b.pinned) {
              return -1 // 'a' is pinned, so it comes first
            } else if (!a.pinned && b.pinned) {
              return 1 // 'b' is pinned, so it comes first
            } else if (a.pinned && b.pinned) {
              // Both 'a' and 'b' are pinned, so compare by 'pinnedAt'
              return new Date(a.pinnedAt) - new Date(b.pinnedAt)
            } else {
              // Neither 'a' nor 'b' are pinned, so compare by 'createdAt'
              return new Date(b.createdAt) - new Date(a.createdAt)
            }
          })
        )
      }
      setLoading(false)
    })
    return () => unsub()
  }, [team.uid])

  const props = {
    name: "file",
    multiple: true,
    accept: "image/*",
    listType: "picture",
    customRequest: (req) => nonHookUploader({ ...req, mutator }),
    maxCount: 4,
  }

  async function sendMessage(messageToSend) {
    setSending(true)
    try {
      console.log("messageToSend::: ", messageToSend)
      if (messages.length > 0) {
        await updateDoc(doc(db, "forums", team.uid), {
          messages: arrayUnion(messageToSend),
        })
      } else {
        await setDoc(doc(db, "forums", team.uid), {
          messages: arrayUnion(messageToSend),
        })
      }
    } catch (error) {
      console.log("error::: ", error)
      message.error(`Failed to send message: ${error}`)
    }
    setSending(false)
  }

  async function deletePost(id) {
    setDeleting(true)
    try {
      await updateDoc(doc(db, "forums", team.uid), {
        messages: messages.filter((x) => x.id !== id),
      })
    } catch (error) {
      console.log("error::: ", error)
      message.error(`Failed to send message: ${error}`)
    }
    setDeleting(false)
  }

  async function handlePinMessage(post) {
    console.log("post::: ", post)
    setPinIdLoading(post.id)
    try {
      await updateDoc(doc(db, "forums", team.uid), {
        messages: messages.filter((x) => {
          if (x.id !== post.id) return x
          if (!post?.pinned) {
            x.pinned = true
            x.pinnedAt = dayjs().toISOString()
          } else {
            x.pinned = false
          }
          return x
        }),
      })
    } catch (error) {
      console.log("error::: ", error)
      message.error(`Failed to pin message: ${error}`)
    }
    setPinIdLoading(null)
  }

  const amIAdmin = useMemo(() => {
    if (team && personQuery.data && !personQuery.isLoading) {
      return team?.admin?.companyPersonUid === personQuery?.data?.companyPerson?.uid
    }
    return false
  }, [team, personQuery.data, personQuery.isLoading])

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Card title={t("letsMakeANewPost")}>
          <Form
            form={form}
            name="forum"
            layout="vertical"
            onFinish={() => {
              form.validateFields().then(async (values) => {
                console.log("values::: ", values)
                const payload = {
                  id: uuidv4(),
                  createdAt: new Date().toISOString(),
                  text: values.text,
                  media: values?.images?.fileList?.length > 0 ? values?.images?.fileList?.map((x) => x?.imageUrl) : [],
                  personUid: personQuery?.data?.uid,
                  upvotes: [],
                  downvotes: [],
                }
                await sendMessage(payload)
                form.resetFields()
              })
            }}
          >
            <Row align="top" gutter={[16, 16]}>
              <Col flex={"32px"}>
                {personQuery.data?.imageUrl ? (
                  <Avatar style={{ marginTop: "0.225rem" }} src={personQuery.data?.imageUrl} />
                ) : (
                  <Avatar icon={<UserOutlined />} />
                )}
              </Col>
              <Col flex={"auto"}>
                <Form.Item name="text" rules={[{ required: true, message: "Please input a message" }]}>
                  <Input placeholder={t("letYourTeamKnowWhatsOnYourMind")} />
                </Form.Item>

                <Form.Item name="images">
                  <Form.Item name={"images"}>
                    <Upload.Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{t("teamUploadTitle")}</p>
                      <p className="ant-upload-hint">{t("teamUploadDescription")}</p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
                <Button
                  disabled={sending || loading}
                  loading={sending}
                  type="primary"
                  icon={<SendOutlined />}
                  htmlType="submit"
                >
                  {t("Post")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={24} style={{ marginBottom: "10rem" }}>
        <Row gutter={[32, 32]}>
          {loading ? (
            <Spin />
          ) : (
            <>
              {messages?.length > 0 ? (
                <>
                  {messages?.length > 0 &&
                    messages.map((post, i) => {
                      const member = team?.members?.find((x) => x?.member?.personUid === post?.personUid)?.member
                      return (
                        <Col key={post.id + i} span={24}>
                          <Card
                            actions={[
                              <Link to={team.uid + "/posts/" + post.id}>
                                <Button type="text" size="small">
                                  <CommentOutlined />
                                  <span>
                                    {post?.comments?.length ? `${t("Comments")}: ${post?.comments?.length}` : null}
                                  </span>
                                </Button>
                              </Link>,
                              <Button
                                size="small"
                                onClick={async () => {
                                  const messageToMutate = messages?.[i]
                                  const upvotes = new Set(messageToMutate.upvotes)
                                  const downvotes = new Set(messageToMutate.downvotes)
                                  const myUid = personQuery?.data?.uid
                                  if (downvotes.has(myUid)) {
                                    downvotes.delete(myUid)
                                  }

                                  if (upvotes?.has(myUid)) {
                                    upvotes.delete(myUid)
                                    messageToMutate.upvotes = Array.from(upvotes)
                                    messageToMutate.downvotes = Array.from(downvotes)
                                    await updateDoc(doc(db, "forums", team.uid), {
                                      messages,
                                    })
                                  } else {
                                    upvotes.add(myUid)
                                    messageToMutate.upvotes = Array.from(upvotes)
                                    messageToMutate.downvotes = Array.from(downvotes)
                                    await updateDoc(doc(db, "forums", team.uid), {
                                      messages,
                                    })
                                  }
                                }}
                                type="text"
                              >
                                {post?.upvotes?.includes(personQuery?.data?.uid) ? (
                                  <HeartTwoTone twoToneColor="#eb2f96" />
                                ) : (
                                  <HeartOutlined />
                                )}

                                <span>{post?.upvotes?.length ? `${t("Likes")}: ${post?.upvotes?.length}` : null}</span>
                              </Button>,
                            ]}
                            extra={
                              amIAdmin || post.personUid === personQuery?.data?.uid
                                ? [
                                    amIAdmin ? (
                                      <Button
                                        style={{ marginRight: "0.5rem" }}
                                        loading={pinIdLoading === post.id}
                                        onClick={() => handlePinMessage(post)}
                                        aria-disabled={pinIdLoading === post.id}
                                        size="small"
                                        type={post.pinned ? "primary" : "default"}
                                        icon={<PushpinOutlined />}
                                      />
                                    ) : null,
                                    <Dropdown
                                      overlay={
                                        <Menu
                                          items={[
                                            {
                                              onClick: () => deletePost(post.id),
                                              danger: true,
                                              key: "delete",
                                              icon: deleting ? <Spin /> : <DeleteOutlined />,
                                              label: deleting ? "Deleting Post..." : t("deletePost"),
                                            },
                                          ]}
                                        />
                                      }
                                    >
                                      <MoreOutlined />
                                    </Dropdown>,
                                  ]
                                : []
                            }
                            cover={
                              post?.media?.length > 0 ? (
                                <div style={{ overflow: "hidden", borderRadius: "1rem" }}>
                                  <Hero autoplay autoplaySpeed={3000}>
                                    {post?.media?.map((url) => (
                                      <Hero.Slide key={url} img={url} />
                                    ))}
                                  </Hero>
                                </div>
                              ) : null
                            }
                          >
                            <Card.Meta
                              title={post.text}
                              description={dayjs(post?.createdAt).fromNow()}
                              avatar={
                                member?.person?.imageUrl ? (
                                  <Tooltip title={member?.person?.firstName + member?.person?.lastName}>
                                    <Avatar src={member?.person?.imageUrl} />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={member?.person?.firstName + member?.person?.lastName}>
                                    <Avatar icon={<UserOutlined />} />
                                  </Tooltip>
                                )
                              }
                            />
                          </Card>
                        </Col>
                      )
                    })}
                </>
              ) : (
                <Empty />
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  )
}

import { arrayMove, insertAtIndex, removeAtIndex } from "./array"

export const moveBetweenContainers = (items, activeContainer, activeIndex, overContainer, overIndex, item) => {
  return {
    ...items,
    [activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
    [overContainer]: insertAtIndex(items[overContainer], overIndex, item),
  }
}

export const handleDragOver = ({ active, over, setItemGroups }) => {
  const overId = over?.id

  if (!overId) {
    return
  }

  const activeContainer = active.data.current.sortable.containerId
  const overContainer = over.data.current?.sortable.containerId || over.id

  if (activeContainer !== overContainer) {
    setItemGroups((itemGroups) => {
      const activeIndex = active.data.current.sortable.index
      const overIndex = over.id in itemGroups ? itemGroups[overContainer].length + 1 : over.data.current.sortable.index

      return moveBetweenContainers(itemGroups, activeContainer, activeIndex, overContainer, overIndex, active.id)
    })
  }
}

export const handleDragEnd = ({ active, over, setActiveId, setItemGroups, itemGroups }) => {
  if (!over) {
    setActiveId(null)
    return
  }
  if (active.id !== over.id) {
    const activeContainer = active.data.current.sortable.containerId
    const overContainer = over.data.current?.sortable.containerId || over.id
    const activeIndex = active.data.current.sortable.index
    const overIndex =
      itemGroups && overContainer && over.id in itemGroups
        ? itemGroups[overContainer].length + 1
        : over.data.current.sortable.index
    // const overIndex = over.id in itemGroups ? itemGroups[overContainer].length + 1 : over.data.current.sortable.index
    setItemGroups((itemGroups) => {
      let newItems
      if (activeContainer === overContainer) {
        newItems = {
          ...itemGroups,
          [overContainer]: arrayMove(itemGroups[overContainer], activeIndex, overIndex),
        }
      } else {
        newItems = moveBetweenContainers(itemGroups, activeContainer, activeIndex, overContainer, overIndex, active.id)
      }
      return newItems
    })
  }
  setActiveId(null)
}

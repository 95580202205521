import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useMutation, useQueryClient } from "react-query"

export const useLoggerMutation = () => {
  const queryClient = useQueryClient()
  let params = {}
  if (window.localStorage.getItem("classroom")) {
    params = {
      ...params,
      classroom: true,
    }
  }
  const urlParams = new URLSearchParams(params).toString()

  return useMutation(
    async (body) =>
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/people/log${urlParams && urlParams.length ? `?${urlParams}` : ""}`,
          body.data,
          {
            headers: {
              Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
              "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries("team"),
    }
  )
}

import { Typography } from "antd"
import React, { useState } from "react"
import { CertificateDrawer } from "./CertificateDrawer"

export const ProfileCertificate = ({ enrolment, children }) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CertificateDrawer visible={visible} onClose={() => setVisible(false)} enrolment={enrolment} />
      {children ? children : <Typography.Link onClick={() => setVisible(true)}>View</Typography.Link>}
    </>
  )
}

import { useEffect, useRef } from "react"

/**
 * Custom hook for attaching event listeners to DOM elements, the window, or media query lists.
 * @param {string} eventName - The name of the event to listen for.
 * @param {(event: Event) => void} handler - The event handler function.
 * @param {RefObject<HTMLElement | MediaQueryList>} [element] - The DOM element or media query list to attach the event listener to (optional).
 * @param {boolean | AddEventListenerOptions} [options] - An options object that specifies characteristics about the event listener (optional).
 */
function useEventListener(eventName, handler, element, options) {
  // Create a ref that stores the handler
  const savedHandler = useRef(handler)

  useEffect(() => {
    // Update the stored handler when the passed handler changes
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Define the listening target
    const targetElement = element?.current || window

    if (!(targetElement && targetElement.addEventListener)) return

    // Create event listener that calls the stored handler function
    const listener = (event) => {
      savedHandler.current(event)
    }

    targetElement.addEventListener(eventName, listener, options)

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, listener, options)
    }
  }, [eventName, element, options])
}

export { useEventListener }

import { Badge, Space, Typography } from "antd"
import { EnrolmentActionButton } from "../EnrolmentActionButton"
import React from "react"

export function MinifiedCard({ title = "Title", meta, url, program, ...rest }) {
  return (
    <div {...rest} className="miniCard">
      <Space>
        <div
          className="miniCard__image"
          style={{
            backgroundImage: url
              ? `url('${url}')`
              : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
          }}
        />
        <div>
          <Typography.Paragraph style={{ marginBottom: ".425rem" }} ellipsis={{ rows: 2, expandable: false }} strong>
            {title}
          </Typography.Paragraph>
          {meta && <Badge color="purple" text={<span className="badge--meta">{meta}</span>} />}
          <div style={{ marginTop: ".425rem" }}>
            {program?.continue && program?.continue.lessonUid && (
              <EnrolmentActionButton size="small" enrolment={program} />
            )}
          </div>
        </div>
      </Space>
    </div>
  )
}

import { MailOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import {
  Avatar,
  Button,
  Col,
  Comment,
  Drawer,
  Form,
  Input,
  Layout,
  Modal,
  PageHeader,
  Result,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from "antd"
import { addDoc, collection, onSnapshot, query, where } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { Link, Route, Switch, useLocation } from "react-router-dom"
import db from "../../firebase"
import { makeProfileImg } from "../../hooks/makeProfileImg"
import { useGetPeople, useGetPerson } from "../../network/usePerson"
import { SelectedChat } from "./SelectedChat"
import { useTranslation } from "react-i18next"

const { Sider, Content } = Layout

export function Chat() {
  const [channels, setChannels] = useState([])
  const [filteredChannels, setFilteredChannels] = useState([])
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [keyOfChat, setKeyOfChat] = useState(null)
  const [searchString, setSearchString] = useState(null)
  const [drawer, setDrawer] = useState(false)
  const { t } = useTranslation()

  const { pathname } = useLocation()

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [pathname])

  const personQuery = useGetPerson({ authenticated: true })
  const peopleQuery = useGetPeople({
    companyUid: personQuery.data?.company?.uid,
    filters: {
      q: searchString,
      limit: 150,
    },
  })

  useEffect(() => {
    setLoading(true)
    if (!personQuery.isLoading && personQuery.data) {
      const unsub = onSnapshot(
        query(collection(db, "channels"), where("uids", "array-contains-any", [personQuery?.data?.uid])),
        (snap) => {
          const arr = []
          snap.forEach((doc) => {
            arr.push({ ...doc.data(), id: doc.id })
          })
          setChannels(arr)
          setFilteredChannels(arr)

          setLoading(false)
        }
      )
      return () => unsub()
    }
  }, [personQuery.isLoading, personQuery.data])

  const createNewChat = async (key, participant) => {
    setKeyOfChat(key)
    await addDoc(collection(db, "channels"), {
      members: [
        {
          name: `${participant?.person?.firstName} ${participant?.person?.lastName}`,
          uid: participant?.person?.uid,
          imageUrl: participant?.person?.imageUrl,
        },
        {
          name: `${personQuery.data?.firstName} ${personQuery.data?.lastName}`,
          uid: personQuery.data?.uid,
          imageUrl: personQuery.data?.imageUrl,
        },
      ],
      uids: [personQuery.data?.uid, participant?.person?.uid],
      type: "chat",
      messages: [],
    })
    setKeyOfChat(null)
    setVisible(false)
  }

  const filterChats = (filterString) => {
    // eslint-disable-next-line
    if (!filterString || filterString === "") setFilteredChannels(channels)

    setFilteredChannels(
      channels.filter((channel) => {
        if (channel.type === "chat") {
          const otherPerson = channel.members.find((x) => x.uid !== personQuery.data?.uid)
          return otherPerson.name.toLowerCase().includes(filterString.toLowerCase())
        }
        return channel.name.toLowerCase().includes(filterString.toLowerCase())
      })
    )
  }

  return (
    <>
      <button onClick={() => setDrawer(true)} className="floatingBack floatingBack--dark" to="/">
        {t("Channels")}
      </button>
      <Drawer placement="left" visible={drawer} onClose={() => setDrawer(false)} closeIcon={null}>
        <div className="channel--sidebar channel--sidebar--drawer">
          <div className="filter">
            <div className="filter__input">
              <Input
                loading={loading || !personQuery.isLoading}
                onChange={(e) => filterChats(e.target.value)}
                bordered={false}
                placeholder={t("searchForAPerson") + "..."}
                // placeholder="Search for person..."
                prefix={<SearchOutlined />}
              />
            </div>
            <Tooltip title="Create new chat">
              <Button onClick={() => setVisible(true)} size="small" type="primary" icon={<PlusOutlined />} />
            </Tooltip>
          </div>
          {!loading && !personQuery.isLoading ? (
            <>
              <Typography.Title level={4}>Channels</Typography.Title>
              {filteredChannels.map((channel, i) => {
                if (channel.type !== "chat") {
                  return (
                    <Link key={i} to={`/chat/${channel.id}`} className="channel">
                      <div className="channel__image">
                        <img width="60" src={channel.imageUrl || makeProfileImg(channel.name)} alt="channel" />
                      </div>
                      <div className="channel__content">
                        <p className="title">{channel.name}</p>
                        <div className="extras">
                          <p className="message">{channel.members.length}: Members</p>
                        </div>
                      </div>
                    </Link>
                  )
                }
                return null
              })}
              <Typography.Title level={4}>Chats</Typography.Title>
              {filteredChannels.map((channel, i) => {
                if (channel.type === "chat") {
                  const otherPerson = channel.members.find((x) => x.uid !== personQuery.data?.uid)
                  return (
                    <Link key={i} to={`/chat/${channel.id}`} className="channel">
                      <div className="channel__image">
                        <img width="60" src={otherPerson.imageUrl || makeProfileImg(otherPerson.name)} alt="channel" />
                      </div>
                      <div className="channel__content">
                        <p className="title">{otherPerson.name}</p>
                      </div>
                    </Link>
                  )
                }
                return null
              })}
            </>
          ) : (
            <Skeleton active />
          )}
        </div>
      </Drawer>
      <Modal
        visible={visible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className="modal"
        onCancel={() => {
          setVisible(false)
        }}
        closeIcon={null}
      >
        <PageHeader className="site-page-header" onBack={() => setVisible(false)} title={t("createANewChat")} />
        <Form
          className="filter"
          onFinish={(values) => {
            setSearchString(values.searchString)
          }}
        >
          <Form.Item name="searchString" className="filter__input filter__input--noMargin">
            <Input bordered={false} placeholder={t("searchForAPerson")} prefix={<SearchOutlined />} />
          </Form.Item>
          <Button loading={peopleQuery.isLoading} htmlType="submit" type="primary">
            {t("Search")}
          </Button>
        </Form>
        <Row gutter={32}>
          {peopleQuery.data?.people?.map((person, i) => (
            <Col key={person?.uid} className="chat__person" xs={24} md={12}>
              <Comment
                // author="Member"
                avatar={
                  <Avatar
                    src={
                      person?.person?.imageUrl ||
                      makeProfileImg(`${person?.person?.firstName[0]}${person?.person?.lastName[0]}`)
                    }
                  />
                }
                content={`${person?.person?.firstName} ${person?.person?.lastName} `}
              />

              <Tooltip title="Create new chat">
                <Button
                  loading={keyOfChat === i}
                  onClick={() => createNewChat(i, person)}
                  icon={<PlusOutlined />}
                  size="small"
                  type="primary"
                />
              </Tooltip>
            </Col>
          ))}
        </Row>
      </Modal>
      <Row
        style={{
          background: "#f8f8f881",
          height: "100vh",
        }}
      >
        <Col xs={0} xl={6} className="chat-sidebar">
          <div className="channel--sidebar">
            <div className="filter">
              <div className="filter__input">
                <Input
                  loading={loading || !personQuery.isLoading}
                  onChange={(e) => filterChats(e.target.value)}
                  bordered={false}
                  placeholder={t("searchForAPerson")}
                  prefix={<SearchOutlined />}
                />
              </div>
              <Tooltip title="Create new chat">
                <Button onClick={() => setVisible(true)} size="small" type="primary" icon={<PlusOutlined />} />
              </Tooltip>
            </div>
            {!loading && !personQuery.isLoading ? (
              <>
                <Typography.Title level={4}>{t("Channels")}</Typography.Title>
                {filteredChannels.map((channel, i) => {
                  if (channel.type !== "chat") {
                    return (
                      <Link key={i} to={`/chat/${channel.id}`} className="channel">
                        <div className="channel__image">
                          <img width="60" src={channel.imageUrl || makeProfileImg(channel.name)} alt="channel" />
                        </div>
                        <div className="channel__content">
                          <p className="title">{channel.name}</p>
                          <div className="extras">
                            <p className="message">
                              {channel.members.length}: {t("Members")}
                            </p>
                          </div>
                        </div>
                      </Link>
                    )
                  }
                  return null
                })}
                <Typography.Title level={4}>{t("Chats")}</Typography.Title>
                {filteredChannels.map((channel, i) => {
                  if (channel.type === "chat") {
                    const otherPerson = channel.members.find((x) => x.uid !== personQuery.data?.uid)
                    return (
                      <Link key={i} to={`/chat/${channel.id}`} className="channel">
                        <div className="channel__image">
                          <img
                            width="60"
                            src={otherPerson.imageUrl || makeProfileImg(otherPerson.name)}
                            alt="channel"
                          />
                        </div>
                        <div className="channel__content">
                          <p className="title">{otherPerson.name}</p>
                        </div>
                      </Link>
                    )
                  }
                  return null
                })}
              </>
            ) : (
              <Skeleton active />
            )}
          </div>
        </Col>
        <Col xs={24} xl={18}>
          {window.location.pathname.split("/chat")[1] === "" && (
            <Content style={{ margin: "auto 0" }} className="chat--wrapper">
              <Result icon={<MailOutlined />} title={t("gladToSeeYou")} subTitle={t("pleaseSelectAChat")} />
            </Content>
          )}
          <Switch>
            <Route
              exact
              path="/chat/:chatUid"
              component={() => <SelectedChat channels={channels} handleOpen={() => setDrawer(true)} />}
            />
          </Switch>
        </Col>
      </Row>
    </>
  )
}

import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  Upload,
} from "antd"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { UserOutlined, UploadOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons"
import { nonHookUploader } from "../../hooks/nonHookUploader"
import { useGetPerson, usePersonMutation } from "../../network/usePerson"
import { tabFactory } from "../../helpers/tabsFactory"
import { useMedia } from "../../network/useMedia"
import { useIsThungela } from "../../hooks/useIsThungela"
import { useTranslation } from "react-i18next"

function editReducer(state, action) {
  const { type, field, payload } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export function Edit() {
  const [state, dispatch] = React.useReducer(
    editReducer,
    {
      language: "",
      visible: false,
      avatarUrl: null,
      hasChanged: false,
      hobbies: [],
      interests: [],
      hobbiesString: "",
      interestsString: "",
    },
    (init) => ({ ...init, visible: true })
  )
  const [form] = Form.useForm()
  const { replace } = useHistory()
  const personMutation = usePersonMutation()
  const uploadMutation = useMedia()
  const { t } = useTranslation()

  const personQuery = useGetPerson({ authenticated: true })
  const isThungela = useIsThungela(personQuery?.data?.company?.tenantId)

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!")
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!")
    }
    return isJpgOrPng && isLt2M
  }

  function handleUploadChange(info) {
    if (info.file.status === "done") {
      dispatch({
        type: "field",
        field: "avatarUrl",
        payload: info.file.imageUrl,
      })
      personMutation.mutate({
        data: {
          imageUrl: info.file.imageUrl,
        },
      })
    }
  }

  function handleNewTag(e, key, force = false) {
    dispatch({ type: "field", field: "hasChanged", payload: true })
    if (force || e?.keyCode === 13) {
      if (e) e?.preventDefault()
      dispatch({
        type: "field",
        field: key,
        payload: [...state[key], state[`${key}String`]],
      })
      dispatch({ type: "field", field: `${key}String`, payload: "" })
    }
  }

  const createNestedObject = function (base, names, value) {
    const lastName = arguments.length === 3 ? names.pop() : false
    for (let i = 0; i < names.length; i++) {
      base = base[names[i]] = base[names[i]] || {}
    }
    if (lastName) base = base[lastName] = value
    return base
  }

  useEffect(() => {
    if (!personQuery.isLoading && personQuery.data) {
      if (personQuery.data?.detail?.hobbies)
        dispatch({
          type: "field",
          field: "hobbies",
          payload: personQuery.data?.detail?.hobbies || [],
        })
      if (personQuery.data?.detail?.interests)
        dispatch({
          type: "field",
          field: "interests",
          payload: personQuery.data?.detail?.interests || [],
        })
      if (personQuery.data?.imageUrl)
        dispatch({
          type: "field",
          field: "avatarUrl",
          payload: personQuery.data?.imageUrl,
        })
    }
  }, [personQuery.isLoading, personQuery.data])

  const handleEdit = async () => {
    const values = form.getFieldsValue(true)
    const payload = {}
    Object.keys(values).forEach((key) => {
      const split = key.split(".")
      createNestedObject(payload, split, values[key])
    })
    dispatch({ type: "field", field: "hasChanged", payload: false })
    await personMutation.mutateAsync({
      data: {
        ...payload,
        imageUrl: state.avatarUrl || personQuery?.data?.imageUrl,
        detail: {
          ...payload?.detail,
          hobbies: state?.hobbies,
          interests: state?.interests,
        },
      },
    })
  }

  const handleDelete = (type, index) => {
    const filteredItems = state[type]?.splice(index, 1)
    dispatch({ type: "field", key: type, payload: filteredItems })
  }

  return (
    <Modal
      visible={state.visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="modal"
      closable={!personMutation.isLoading}
      onCancel={() => {
        dispatch({ type: "field", field: "visible", payload: false })
        setTimeout(() => replace("/profile"), 500)
      }}
    >
      <Typography.Title level={2}>{t("profileSettings")}</Typography.Title>
      {!personQuery.isLoading ? (
        <>
          {personQuery.data && !personQuery.isError && (
            <Form
              name="sign-in"
              form={form}
              onFinish={handleEdit}
              initialValues={{
                firstName: personQuery.data?.firstName,
                lastName: personQuery.data?.lastName,
                "detail.biography": personQuery.data?.detail?.biography,
                "detail.hobbies": personQuery.data?.detail?.hobbies,
                "detail.interests": personQuery.data?.detail?.interests,
                "contact.email": personQuery.data?.contact?.email || personQuery?.data?.companyPerson?.email,
                "contact.tel": personQuery.data?.contact?.tel || personQuery?.data?.companyPerson?.tel,
                "preference.alertFrequency": personQuery.data?.preference?.alertFrequency,
                "preference.appearence": personQuery.data?.preference?.appearance,
                "preference.defaultLanguageId": personQuery.data?.preference?.defaultLanguageId,
              }}
            >
              <Tabs
                defaultActiveKey={tabFactory().get() || "1"}
                onChange={(keyValue) => tabFactory().onChange(keyValue)}
              >
                <Tabs.TabPane tab={t("Profile")} key="1">
                  <div className="image__editor">
                    {/* <img width='60' src={person} height='60' alt='avatar' /> */}
                    {state?.avatarUrl ? (
                      <Avatar style={{ marginRight: "1.75rem" }} size={128} src={state?.avatarUrl} />
                    ) : (
                      <Avatar style={{ marginRight: "1.75rem" }} size={128} icon={<UserOutlined />} />
                    )}
                    <div className="editor">
                      <h3>{t("profilePhoto")}</h3>
                      <p className="info">{t("profilePhotoCaption")} 400x400</p>
                      <Upload
                        name="file"
                        disabled={personMutation.isLoading}
                        customRequest={(req) => nonHookUploader({ ...req, mutator: uploadMutation })}
                        className="img"
                        beforeUpload={beforeUpload}
                        onChange={handleUploadChange}
                      >
                        <Button icon={<UploadOutlined />}>{t("profilePhotoUpload")}</Button>
                      </Upload>
                    </div>
                  </div>
                  <Typography.Title level={4}>{t("accountInfo")}</Typography.Title>
                  <Row gutter={32}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your first name",
                          },
                        ]}
                      >
                        <Input disabled={isThungela} placeholder="First Name" />
                      </Form.Item>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your last name",
                          },
                        ]}
                      >
                        <Input disabled={isThungela} placeholder="Last Name" />
                      </Form.Item>
                      <Form.Item name="detail.biography">
                        <Input.TextArea rows={4} placeholder={t("profileBio")} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name="contact.email">
                        <Input disabled={isThungela} type="email" placeholder="name@org.co.za" />
                      </Form.Item>
                      <Form.Item name="contact.tel">
                        <Input type="tel" placeholder="+27 ..." />
                      </Form.Item>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("Preferences")} key="2">
                  <Typography.Title level={4}>{t("preferredLanguage")}</Typography.Title>
                  <Form.Item name="preference.defaultLanguageId">
                    <Select
                      placeholder={t("preferredLanguage")}
                      style={{ width: "100%" }}
                      options={[
                        {
                          value: "1",
                          label: "English",
                        },
                        {
                          value: "2",
                          label: "French",
                        },
                        {
                          value: "3",
                          label: "Spanish",
                        },
                        {
                          value: "4",
                          label: "Chinese",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Typography.Title level={4}>{t("alertFrequency")}</Typography.Title>
                  <Form.Item name="preference.alertFrequency">
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="immediately">{t("Immediately")}</Radio.Button>
                      <Radio.Button value="hourly">{t("Hourly")}</Radio.Button>
                      <Radio.Button value="daily">{t("Daily")}</Radio.Button>
                      <Radio.Button value="weekly">{t("Weekly")}</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  {/* <Typography.Title level={4}>Appearence</Typography.Title>
                  <Form.Item name="preference.appearence">
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="light">Light</Radio.Button>
                      <Radio.Button value="dark">Dark</Radio.Button>
                      <Radio.Button value="system">System</Radio.Button>
                    </Radio.Group>
                  </Form.Item> */}
                </Tabs.TabPane>
                <Tabs.TabPane style={{ marginBottom: "1.5rem" }} tab={t("hobbiesAndInterests")} key="3">
                  <Typography.Title level={4}>{t("yourHobbies")}</Typography.Title>
                  <div className="filter">
                    <div className="filter__input">
                      <Input
                        value={state?.hobbiesString}
                        onChange={(e) =>
                          dispatch({
                            type: "field",
                            field: "hobbiesString",
                            payload: e.target.value,
                          })
                        }
                        onKeyDown={(e) => handleNewTag(e, "hobbies")}
                        bordered={false}
                        placeholder={t("addHobby")}
                        // placeholder="Add hobby"
                        prefix={<PlusOutlined />}
                      />
                    </div>
                    <div className="filter__actions">
                      <Button type="primary" htmlType="button" onClick={() => handleNewTag(null, "hobbies", true)}>
                        {t("Add")}
                      </Button>
                    </div>
                  </div>
                  {state?.hobbies?.map((tag, i) => (
                    <Tag key={i} color="green">
                      {tag}{" "}
                      <Button type="text" onClick={() => handleDelete("hobbies", i)} danger icon={<DeleteOutlined />} />
                    </Tag>
                  ))}
                  <Divider />
                  <Typography.Title style={{ marginTop: "1.5rem" }} level={4}>
                    {t("yourInterests")}
                  </Typography.Title>
                  <div className="filter">
                    <div className="filter__input">
                      <Input
                        value={state?.interestsString}
                        onChange={(e) =>
                          dispatch({
                            type: "field",
                            field: "interestsString",
                            payload: e.target.value,
                          })
                        }
                        onKeyDown={(e) => handleNewTag(e, "interests")}
                        bordered={false}
                        placeholder="Add interest"
                        prefix={<PlusOutlined />}
                      />
                    </div>
                    <div className="filter__actions">
                      <Button type="primary" htmlType="button" onClick={() => handleNewTag(null, "interests", true)}>
                        {t("Add")}
                      </Button>
                    </div>
                  </div>
                  {state?.interests?.map((tag, i) => (
                    <Tag key={i} color="green">
                      {tag}{" "}
                      <Button
                        type="text"
                        onClick={() => handleDelete("interests", i)}
                        danger
                        icon={<DeleteOutlined />}
                      />
                    </Tag>
                  ))}
                </Tabs.TabPane>
              </Tabs>
              <Space direction="vertical" style={{ marginTop: "1rem" }}>
                {state?.hasChanged && (
                  <Typography.Text disabled italic>
                    Any unsubmitted changes will be lost
                  </Typography.Text>
                )}
                <Button loading={personMutation.isLoading} type="primary" htmlType="submit">
                  {t("Submit")}
                </Button>
              </Space>
            </Form>
          )}
        </>
      ) : (
        <Spin />
      )}
    </Modal>
  )
}

import { useState, useEffect } from "react"

export const useVideoWatchedToCompletion = (videoRef) => {
  const [watchedToCompletion, setWatchedToCompletion] = useState(false)

  useEffect(() => {
    const video = videoRef.current

    if (!video) {
      return
    }

    const handleTimeUpdate = () => {
      if (video.currentTime / video.duration >= 0.9) {
        setWatchedToCompletion(true)
      }
    }

    video.addEventListener("timeupdate", handleTimeUpdate)

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate)
    }
  }, [videoRef])

  return watchedToCompletion
}

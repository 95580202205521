import { Avatar, Button, Comment, Divider, Form, Input, List, Modal, Spin, Tooltip, Typography, message } from "antd"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import db from "../../firebase"
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { Hero } from "../../components/Hero"
import { CommentOutlined, UserOutlined } from "@ant-design/icons"
import { useGetSingleTeam } from "../../network/useTeams"
import uuidv4 from "../../hooks/uuid"
import { useGetPerson } from "../../network/usePerson"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

export const TeamForumPost = () => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(true)
  const { replace } = useHistory()
  const { postId, teamUid } = useParams()
  const [post, setPost] = useState(null)
  const [messages, setMessages] = useState(null)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const personQuery = useGetPerson({ authenticated: true })
  const teamQuery = useGetSingleTeam({ teamUid })
  function closeModal() {
    setVisible(false)
    setTimeout(() => replace(`/teams/${teamUid}`), 500)
  }

  useEffect(() => {
    const getForum = async () => {
      const docRef = doc(db, "forums", teamUid)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        console.log("postId::: ", postId)
        const post = docSnap.data().messages.find((x) => x.id === postId)
        if (!post) return
        console.log("post::: ", post)
        setPost(post)
        setMessages(docSnap.data().messages)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!")
        closeModal()
      }
    }
    getForum()
  }, [postId])

  const handleComment = async (comment) => {
    setLoading(true)
    try {
      const payload = post
      if (!payload.comments) {
        payload.comments = [comment]
      } else {
        payload.comments = [...payload.comments, comment]
      }
      setPost(payload)
      await updateDoc(doc(db, "forums", teamUid), {
        messages: messages.filter((x) => {
          if (x.id !== postId) return x
          if (!x.comments) {
            x.comments = [comment]
          } else {
            x.comments = [...x.comments, comment]
          }
          return x
        }),
      })
    } catch (error) {
      console.log("error::: ", error)
      message.error(`Failed to leave comment. Please try again: ${error}`)
    }
    setLoading(false)
  }
  console.log("psot::: ", post)

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="modal modal--noPadding"
      onCancel={closeModal}
    >
      {post ? (
        <>
          <Hero autoplay autoplaySpeed={3000}>
            {post?.media?.map((url) => (
              <Hero.Slide key={url} img={url} />
            ))}
          </Hero>
          <div className="modal__body">
            <Typography.Paragraph>{post.text}</Typography.Paragraph>
            <List
              className="comment-list"
              header={post?.comments?.length ? `${t("Comments")}: ${post?.comments?.length}` : null}
              // header={`${post?.comments?.length} comment${post?.comments?.length === 1 ? "" : "s"}`}
              itemLayout="horizontal"
              dataSource={post.comments ?? []}
              renderItem={(item) => {
                const member = teamQuery.data?.members?.find((x) => x?.member?.personUid === post?.personUid)?.member
                console.log("member::: ", member)
                return (
                  <li>
                    <Comment
                      author={member?.person?.firstName + member?.person?.lastName}
                      avatar={
                        member?.person?.imageUrl ? (
                          <Tooltip title={member?.person?.firstName + member?.person?.lastName}>
                            <Avatar src={member?.person?.imageUrl} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={member?.person?.firstName + member?.person?.lastName}>
                            <Avatar icon={<UserOutlined />} />
                          </Tooltip>
                        )
                      }
                      content={item.comment}
                      datetime={dayjs(item?.createdAt).fromNow()}
                    />
                  </li>
                )
              }}
            />
            <Divider />
            <Form
              onFinish={async () => {
                form.validateFields().then(async (values) => {
                  const payload = {
                    id: uuidv4(),
                    createdAt: new Date().toISOString(),
                    comment: values.comment,
                    personUid: personQuery?.data?.uid,
                  }
                  await handleComment(payload)
                  form.resetFields()
                })
              }}
              form={form}
              name="comment"
            >
              <div style={{ display: "flex", alignItems: "start", gap: "1rem" }}>
                <Form.Item
                  style={{ flexGrow: 1 }}
                  name="comment"
                  rules={[{ required: true, message: "Please don't leave any empty column" }]}
                >
                  <Input placeholder={t("Comments") + "..."} />
                </Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  {t("Post")}
                  {/* {loading ? "Commenting..." : "Comment"} */}
                </Button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <Spin />
      )}
    </Modal>
  )
}

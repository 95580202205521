import { Image } from "antd"
import React, { useState } from "react"

export function MessageImage({ message, index, messageList, personQuery }) {
  const [incoming] = useState(() => {
    if (
      message.sender.id === personQuery.data?.uid ||
      message.sender.uid === personQuery.data?.uid
    ) {
      return false
    }
    return true
  })
  return (
    <li
      className={`message  ${
        incoming ? "message__incoming" : "message__outgoing"
      } ${
        messageList[index + 1]?.sender.uid === message.sender.uid &&
        "message--grouped"
      } message--image`}
    >
      <p className="caption">{message.content.text}</p>
      <Image src={message.content.url} alt="media" className="media" />
      <img src={message.sender.imageUrl} alt="sender" className="sender" />
    </li>
  )
}

export const isModuleLocked = (
  module,
  publication,
  actions,
  sectionIndex,
  forceLock = false
) => {
  const type = publication?.type

  if (!publication?.completeLearningInOrder) {
    return false
  }

  if (forceLock) return true

  if (type === "program") {
    const indexOfModule = publication?.material[
      sectionIndex
    ]?.modules?.findIndex((x) => x?.uid === module?.uid)

    if (sectionIndex === 0 && indexOfModule === 0) {
      return false
    }

    // IS PREVIOUS ADJACENT MODULE COMPLETE
    if (indexOfModule > 0) {
      const previousModule =
        publication?.material[sectionIndex]?.modules[indexOfModule - 1]
      if (actions?.find((x) => x?.typeUid === previousModule?.uid)) {
        return false
      }
    }

    // IS PREVIOUS SECTION MODULE COMPLETE
    if (indexOfModule === 0 && sectionIndex > 0) {
      const previousSection = publication?.material[sectionIndex - 1]
      const lastModInSection =
        previousSection?.modules[previousSection?.modules?.length - 1]
      if (actions?.find((x) => x?.typeUid === lastModInSection?.uid)) {
        return false
      }
    }
  } else {
    const indexOfModule = publication?.material?.findIndex(
      (x) => x?.uid === module?.uid
    )
    if (!publication?.completeLearningInOrder) {
      return false
    }
    // IS FIRST MODULE
    if (indexOfModule === 0) {
      return false
    }

    // IS PREVIOUS COMPLETE
    if (indexOfModule > 0) {
      const previousModule = publication?.material[indexOfModule - 1]
      if (actions?.find((x) => x?.typeUid === previousModule?.uid)) {
        return false
      }
    }
  }

  // IS CURRENT MODULE COMPLETE
  if (actions?.find((x) => x?.typeUid === module?.uid)) {
    return false
  }

  return true
}

import { useCallback, useState } from "react"
import { useEventListener } from "./useEventListener"
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect"

const useElementSize = (options = {}) => {
  const { initializeWithValue = true } = options

  const [ref, setRef] = useState(null)

  const readValue = useCallback(() => {
    return {
      width: ref?.offsetWidth ?? undefined,
      height: ref?.offsetHeight ?? undefined,
    }
  }, [ref?.offsetHeight, ref?.offsetWidth])

  const [size, setSize] = useState(() => {
    if (initializeWithValue) {
      return readValue()
    }
    return { width: undefined, height: undefined }
  })

  const handleSize = useCallback(() => {
    setSize(readValue())
  }, [ref?.offsetHeight, ref?.offsetWidth])

  useEventListener("resize", handleSize)

  useIsomorphicLayoutEffect(() => {
    handleSize()
  }, [ref?.offsetHeight, ref?.offsetWidth])

  const result = [setRef, size]

  result.ref = result[0]
  result.width = size.width
  result.height = size.height

  return result
}

export { useElementSize }

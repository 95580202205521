import React from "react"
import { Badge, Card, Col, Row, Space, Tag, Typography, Button, Tooltip, Comment } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import { ConditionalLink } from "../ConditionalLink"
import { generateKey } from "../../hooks/generateKey"
import { EnrolmentActionButton } from "../EnrolmentActionButton"

export function ResponsiveCard({
  title,
  url,
  desc,
  topics = [],
  meta1,
  meta2,
  href,
  certificate = null,
  onClick,
  progress,
  provider,
  course,
  ...rest
}) {
  return (
    <ConditionalLink to={href} condition={href}>
      <Card {...rest} className="respCard" onClick={() => onClick && onClick()}>
        <Row gutter={["16"]}>
          <Col xs={24} md={8}>
            <div
              className="respCard__image bg--boilerplate"
              style={{
                backgroundImage: url
                  ? `url('${url}')`
                  : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
              }}
            >
              {progress && progress > 0 ? (
                <div className="progress">
                  <Badge count={`${progress}%`} style={{ backgroundColor: "#52c41a" }} className="percentageNum" />
                  <div className="percentage" style={{ width: `${progress}%` }} />
                </div>
              ) : null}
            </div>
          </Col>
          <Col xs={24} md={13} className="content">
            <Space style={{ marginBottom: "0.625rem" }}>
              <Space style={{ marginBottom: "0.625rem" }}>
                {topics &&
                  topics.slice(0, 2).map((topic) => (
                    <Tag key={generateKey(typeof topic === "string" ? topic : topic.value)} color="green">
                      {typeof topic === "string" ? topic : topic.value}
                    </Tag>
                  ))}
                <p className="badge--meta">{topics?.length > 2 && `+ ${topics?.length - 2}`}</p>
              </Space>
            </Space>
            <Typography.Title
              style={{ marginBottom: "0.625rem" }}
              level={4}
              className="ellipses--two"
              // ellipsis={{
              //   rows: 2,
              //   expandable: true,
              //   symbol: <span className='expand'>more</span>
              // }}
            >
              {title}
            </Typography.Title>
            {desc && (
              <Typography.Paragraph
                type="secondary"
                className="ellipses--two"
                // ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
              >
                {desc}
              </Typography.Paragraph>
            )}
            {provider && <Comment avatar={provider?.imageUrl} author="Provider" content={provider?.name} />}
            <Space size={16}>
              {meta1 && <Badge color="green" text={<span className="meta">{meta1}</span>} />}
              {meta2 && <Badge color="purple" text={<span className="meta">{meta2}</span>} />}
            </Space>
            <Space style={{ paddingTop: 15 }}>
              {course?.continue && course?.continue.lessonUid && (
                <EnrolmentActionButton size="small" enrolment={course} />
              )}
            </Space>
          </Col>
        </Row>
      </Card>
    </ConditionalLink>
  )
}

export const useIsThungela = (tenantId) => {
  if (tenantId) {
    if (String(tenantId).toLowerCase().includes("tempero")) {
      return true
    }
    if (String(tenantId).toLowerCase().includes("winwin")) {
      return true
    }
    return String(tenantId).toLowerCase().includes("thungela")
  }
  return false
}

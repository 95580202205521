import { Button, Image, Space, Tag } from "antd"
import { Link } from "react-router-dom"
import { ProfileCertificate } from "../components/ProfileCertificate"
import { TagFactory } from "../components/TagFactory"

const fallBackImage =
  "https://images.unsplash.com/photo-1644192590638-c44104565833?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
export const tables = {
  profile: {
    columns: [
      {
        title: "Title",
        dataIndex: "enrolment",
        key: "enrolment",
        render: (x) => (
          <Space>
            <div
              style={{
                width: 32,
                height: 32,
                borderRadius: "0.375rem",
                objectFit: "cover",
                position: "relative",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: x?.src
                  ? `url('${x?.src}')`
                  : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
              }}
            ></div>
            {x?.contentType === "physical" ? (
              <>
                <Button type="text">{x.title}</Button>
              </>
            ) : (
              <Link to={`/${x.type}s/${x.uid}`}>
                <Button type="link">{x.title}</Button>
              </Link>
            )}
          </Space>
        ),
      },

      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (tags) => (
          <Space>
            {tags?.map((tag) => {
              return (
                <Tag color={tag === "In Person" ? "orange" : "blue"} key={tag}>
                  {tag}
                </Tag>
              )
            })}
          </Space>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, { status }) => {
          return (
            <Space>
              {status.map((tag, i) => {
                if (!tag) return null
                return (
                  <>
                    <TagFactory icon={tag?.icon} status={tag?.status} key={`${tag}-${i}`}>
                      {tag?.value}
                    </TagFactory>
                  </>
                )
              })}
            </Space>
          )
        },
      },
      {
        title: "Certificate",
        dataIndex: "certificate",
        key: "certificate",
        render: (_, { certificate }) => {
          if (certificate) {
            return <ProfileCertificate enrolment={certificate} />
          }
          return null
        },
      },
    ],
  },
}

export const seritiProfileTables = {
  columns: [
    {
      title: "Title",
      dataIndex: "enrolment",
      key: "enrolment",
      render: (x) => (
        <Space>
          <div
            style={{
              width: 32,
              height: 32,
              borderRadius: "0.375rem",
              objectFit: "cover",
              position: "relative",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundImage: x?.src
                ? `url('${x?.src}')`
                : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
            }}
          ></div>
          <Button type="text">{x.title}</Button>
        </Space>
      ),
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (tags) => (
        <Space>
          {tags?.map((tag) => {
            return (
              <Tag color={tag === "In Person" ? "orange" : "blue"} key={tag}>
                {tag}
              </Tag>
            )
          })}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        return (
          <Space>
            {status.map((tag, i) => {
              if (!tag) return null
              return (
                <>
                  <TagFactory icon={tag?.icon} status={tag?.status} key={`${tag}-${i}`}>
                    {tag?.value}
                  </TagFactory>
                </>
              )
            })}
          </Space>
        )
      },
    },
    {
      title: "Certificate",
      dataIndex: "certificate",
      key: "certificate",
      render: (_, { certificate }) => {
        if (certificate) {
          return <ProfileCertificate enrolment={certificate} />
        }
        return null
      },
    },
  ],
}

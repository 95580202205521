export const sortEnrolments = (enrolments, by, idx) => {
  switch (by) {
    case "Due Date":
      return enrolments.sort((a, b) =>
        idx === 0
          ? new Date(b.dueDate) - new Date(a.dueDate)
          : new Date(a.dueDate) - new Date(b.dueDate)
      )
    case "Enrolment date":
      return enrolments.sort((a, b) =>
        idx === 0
          ? new Date(b.createdAt) - new Date(a.createdAt)
          : new Date(a.createdAt) - new Date(b.createdAt)
      )
    case "Duration":
      return enrolments.sort((a, b) =>
        idx === 0
          ? new Date(b.publication.duration) - new Date(a.publication.duration)
          : new Date(a.publication.duration) - new Date(b.publication.duration)
      )
    default:
      return enrolments
  }
}

import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useMutation, useQueryClient } from "react-query"

export const useEnrolmentActivtyMutation = () => {
  const queryClient = useQueryClient()
  let params = {}
  if (window.localStorage.getItem("classroom")) {
    params = {
      ...params,
      classroom: true,
    }
  }
  const urlParams = new URLSearchParams(params).toString()
  return useMutation(
    async (body) => {
      return axios
        .post(
          `${process.env.REACT_APP_API_URL}/learn/enrolments/${body.publicationEnrolmentUid}/activity${
            urlParams && urlParams.length ? `?${urlParams}` : ""
          }`,
          body.data,
          {
            headers: {
              Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
              "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            },
          }
        )
        .then((res) => res.data)
    },
    {
      onError: (error) => {
        message.error(`Failed to send enrolment activty: ${error.response.data.message}`)
      },
    }
  )
}
export const useActivityResetMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => {
      const { publicationEnrolmentUid, type, typeUid } = body ?? {}
      return axios
        .put(
          `${process.env.REACT_APP_API_URL}/learn/enrolments/${publicationEnrolmentUid}/activity/${type}/${typeUid}/reset`,
          body.data,
          {
            headers: {
              Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
              "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            },
          }
        )
        .then((res) => res.data)
    },
    {
      onError: (error) => {
        message.error(`Failed to update enrolment activty: ${error.response.data.message}`)
      },
    }
  )
}

import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useQuery, useQueryClient } from "react-query"

export const useGetTeams = () => {
  const queryClient = useQueryClient()
  return useQuery(
    "teams",
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/teams/me`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch teams: ${error.response.data.message}`)
      },
    }
  )
}
export const useGetSingleTeam = ({ teamUid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["team", teamUid],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/teams/${teamUid}`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!teamUid,
      onError: (error) => {
        message.error(`Failed to fetch teams: ${error.response.data.message}`)
      },
    }
  )
}
export const useGetSingleTeamPubs = ({ teamUid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["team", teamUid, "publications"],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/teams/${teamUid}/publications`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!teamUid,
      onError: (error) => {
        message.error(`Failed to fetch teams: ${error.response.data.message}`)
      },
    }
  )
}

export const fetchTeamPubEnrls = async ({ teamUid, publicationUid, queryClient }) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/teams/${teamUid}/publications/${publicationUid}`, {
      headers: {
        Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
        "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
      },
    })
    .then((res) => res.data.data)
}
export const useGetSingleTeamPubEnrls = ({ teamUid, publicationUid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["team", teamUid, "publications", publicationUid],
    async () => fetchTeamPubEnrls({ teamUid, publicationUid, queryClient }),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!teamUid,
      onError: (error) => {
        message.error(`Failed to fetch teams: ${error.response.data.message}`)
      },
    }
  )
}
export const useGetTeamLeaderboard = ({ teamUid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["team", teamUid, "leaderboard"],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/teams/${teamUid}/leaderboard`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!teamUid,
      onError: (error) => {
        message.error(`Failed to fetch teams: ${error.response.data.message}`)
      },
    }
  )
}

export const useGetTeamActivity = ({ teamUid, filters }) => {
  let params = {}

  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = {
          ...params,
          [key]: filters[key],
        }
      }
    })
  }

  const urlParams = new URLSearchParams(params).toString()
  const queryClient = useQueryClient()
  return useQuery(
    ["team", teamUid, "activity"],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/teams/${teamUid}/enrolments`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!teamUid,
      onError: (error) => {
        message.error(`Failed to fetch teams activty: ${error.response.data.message}`)
      },
    }
  )
}

export const fetchMemberEnrolments = async ({ personUid, filters, queryClient }) => {
  let params = {
    contentType: "digital,physical,mixed",
    include: "completed",
    limit: 30,
  }
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params = {
          ...params,
          [key]: filters[key],
        }
      }
    })
  }
  const urlParams = new URLSearchParams(params).toString()
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/learn/enrolments/person/${personUid}${urlParams.length ? `?${urlParams}` : ""}`,
      {
        headers: {
          Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
          "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
        },
      }
    )
    .then((res) => res.data.data)
}
export const useGetMemberEnrolments = ({ personUid, filters }) => {
  const queryClient = useQueryClient()

  return useQuery(
    ["memberEnrolments", personUid],
    async () => fetchMemberEnrolments({ personUid, filters, queryClient }),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!personUid,
      onError: (error) => {
        message.error(`Failed to fetch team member: ${error.response.data.message}`)
      },
    }
  )

  // return useQuery(
  //   ["memberEnrolments", personUid],
  //   async () =>
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_API_URL}/learn/enrolments/person/${personUid}${
  //           urlParams.length ? `?${urlParams}` : ""
  //         }`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
  //             "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
  //           },
  //         }
  //       )
  //       .then((res) => res.data.data),
  //   {
  //     staleTime: 1000 * 60 * 5,
  //     retry: false,
  //     enabled: !!personUid,
  //     onError: (error) => {
  //       message.error(`Failed to fetch team member: ${error.response.data.message}`)
  //     },
  //   }
  // )
}

import { Checkbox, Col, Form, Row } from "antd"

export function MultipleChoice({
  question,
  questionIndex,
  dispatch,
  form,
  isFacilitate = false,
  disabled = false,
  required = false,
  inReview = false,
}) {
  const isWrong = (answer) => {
    if (!inReview) return ""

    const checkbox = document.getElementById(answer.uid)
    if (checkbox?.checked && answer.correct === 0) {
      return "option__choice--wrong"
    }
    return ""
  }

  const stringifiedAnswers = form.getFieldValue(`q-${questionIndex + 1}`) || []
  const parsedAnswers = stringifiedAnswers.map((x) => JSON.parse(x))
  return (
    <Form.Item
      name={`q-${questionIndex + 1}`}
      rules={[
        {
          required,
          message: "Please select an answer",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            const currValue = getFieldValue(`q-${questionIndex + 1}`)
            if (currValue && currValue.length < Number(question.requiredAnswerCount)) {
              dispatch({
                type: "throwError",
                payload: `Please select at least ${Number(question.requiredAnswerCount)}`,
              })
              return Promise.reject(new Error(`Please select at least ${Number(question.requiredAnswerCount)}`))
            }

            if (currValue && currValue.length > Number(question.requiredAnswerCount)) {
              dispatch({
                type: "throwError",
                payload: `Please select only ${Number(question.requiredAnswerCount)}`,
              })
              return Promise.reject(new Error(`Please select only ${Number(question.requiredAnswerCount)}`))
            }
            dispatch({ type: "field", field: "canProceed", payload: true })
            return Promise.resolve()
          },
        }),
      ]}
    >
      <Checkbox.Group style={{ width: "100%" }}>
        <Row gutter={["16", "16"]}>
          {question.answers.map((answer) => (
            <Col key={answer.uid} style={{ marginBottom: "1rem", display: "flex" }} xs={24} lg={12}>
              <Checkbox
                disabled={
                  parsedAnswers
                    ? (() => {
                        if (parsedAnswers.length >= Number(question.requiredAnswerCount)) {
                          return !parsedAnswers.find((x) => x.uid === answer.uid)
                        }
                        return false
                      })()
                    : false
                }
                id={answer.uid}
                className={`option__choice ${
                  isFacilitate !== "true" && inReview && answer.correct === 1 ? "option__choice--correct" : ""
                } ${isWrong(answer)}`}
                value={JSON.stringify({
                  uid: answer.uid,
                  title: answer?.title || answer?.translations?.["1"]?.title,
                  correct: answer?.correct,
                })}
              >
                {answer?.title || answer?.translations?.["1"]?.title}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  )
}

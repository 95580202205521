import { Space } from "antd"
import React, { useState } from "react"
import { ConditionalLink } from "../ConditionalLink"

export function MessageText({ message, index, messageList, personQuery }) {
  const [incoming] = useState(() => {
    if (
      message.sender.id === personQuery.data?.uid ||
      message.sender.uid === personQuery.data?.uid
    ) {
      return false
    }
    return true
  })
  return (
    <li
      className={`message ${
        incoming ? "message__incoming" : "message__outgoing"
      } ${
        messageList[index + 1]?.sender.uid === message.sender.uid &&
        "message--grouped"
      }`}
    >
      <img src={message.sender.imageUrl} alt="sender" className="sender" />
      <ConditionalLink
        style={{ color: "white" }}
        condition={message?.content?.url}
        to={message?.content?.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Space>
          {message?.content?.url && <>&#10697;</>}
          {message.content.text}
        </Space>
      </ConditionalLink>
    </li>
  )
}

import React from "react"
import { Link } from "react-router-dom"

export function ConditionalLink({ children, to, condition, style, ...rest }) {
  return !!condition && to ? (
    <Link to={to} style={style} {...rest}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

import React, { useEffect, useState } from "react"
import { Form, Input, Space } from "antd"
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core"
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import { OrderCorrectlyDroppable } from "./OrderCorrectlyDroppable"
import { handleDragEnd, handleDragOver } from "../../helpers/dragHelpers"
import { useTranslation } from "react-i18next"

export function OrderCorrectly({
  question,
  questionIndex,
  form,
  dispatch,
  isFacilitate = false,
  disabled = false,
  required = false,
  inReview = false,
}) {
  const { t } = useTranslation()
  const [activeId, setActiveId] = useState(null)
  const [itemGroups, setItemGroups] = useState(() => {
    const answers = form.getFieldValue(`q-${questionIndex + 1}`) || []
    const ansUids = answers.map((x) => x.uid) || []

    const filteredQuestions = question.answers.filter((option) => !ansUids.includes(option?.uid))

    return {
      answers,
      options: filteredQuestions,
    }
  })

  useEffect(() => {
    if (itemGroups?.answers?.length < question.answers?.length) {
      dispatch({
        type: "throwError",
        payload: `Please select ${question.answers?.length}`,
      })
      dispatch({ type: "field", field: "canProceed", payload: false })
    } else dispatch({ type: "field", field: "canProceed", payload: true })

    form.setFieldsValue({
      [`q-${questionIndex + 1}`]: itemGroups?.answers,
    })
  }, [itemGroups?.answers])

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragStart = ({ active }) => setActiveId(active.id)

  const handleDragCancel = () => setActiveId(null)

  return (
    <>
      <p className="instruction">{t("dragTo")}</p>
      <Form.List name={`q-${questionIndex + 1}`} className="sr-only">
        {(fields) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space className="sr-only" key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                  <Form.Item hidden {...restField} name={[name, "uid"]}>
                    <Input placeholder="uid" />
                  </Form.Item>
                  <Form.Item hidden {...restField} name={[name, "correct"]} className="">
                    <Input placeholder="correct" />
                  </Form.Item>
                  <Form.Item hidden {...restField} name={[name, "title"]} className="">
                    <Input placeholder="title" />
                  </Form.Item>
                </Space>
              ))}
            </>
          )
        }}
      </Form.List>
      {inReview ? (
        <div className="dropzone empty">
          {form.getFieldValue(`q-${questionIndex + 1}`) &&
            form.getFieldValue(`q-${questionIndex + 1}`).map((ans, i) => (
              <div
                className={`missing__opt ${
                  isFacilitate !== "true" && i === parseInt(ans.correct)
                    ? "missing__opt--correct"
                    : "missing__opt--wrong"
                }`}
                key={ans.uid}
              >
                {ans.title}
              </div>
            ))}
        </div>
      ) : (
        <>
          {itemGroups ? (
            <DndContext
              sensors={sensors}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
              onDragOver={({ active, over }) => handleDragOver({ active, over, setItemGroups })}
              onDragEnd={(props) => handleDragEnd({ ...props, setActiveId, setItemGroups, activeId, itemGroups })}
            >
              {Object.keys(itemGroups).map((group) => (
                <OrderCorrectlyDroppable
                  id={group}
                  items={itemGroups[group]}
                  activeId={activeId}
                  key={group}
                  className={group === "answers" ? "dropzone--smallMTop" : ""}
                />
              ))}
            </DndContext>
          ) : null}
        </>
      )}
    </>
  )
}

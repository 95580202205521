import { useAuthIdToken } from "@react-query-firebase/auth"
import { Card, Col, notification, Row, Skeleton, Space, Spin } from "antd"
import React, { Suspense, useEffect, useMemo } from "react"
import { Redirect, Route, useLocation, useHistory } from "react-router-dom"
import { MobileNavbar } from "./components/MobileNavbar"
import { Navbar } from "./components/Navbar"
import { ThemeProvider } from "./components/ThemeProvider"
import { auth } from "./firebase"
import { LessonProvider } from "./hooks/lesson-context"
import { useLoggerMutation } from "./network/useLogger"
import MyLearning from "./views"
import { Classroom } from "./views/auth/Classroom"
import { Register } from "./views/auth/Register"
import { SelfRegister } from "./views/auth/SelfRegister"
import { SignInWrapper } from "./views/auth/SignInWrapper"
import { Chat } from "./views/chat/Chat"
import { Courses } from "./views/enrolments/courses/Courses"
import { Externals } from "./views/enrolments/externals/External"
import { ExternalLearning } from "./views/enrolments/externals/ExternalLearning"
import { Programs } from "./views/enrolments/programs/Programs"
import { Explore } from "./views/Explore"
import { Modules } from "./views/modules/Modules"
import { Edit } from "./views/profile/Edit"
import { Profile } from "./views/profile/Profile"
import { Team } from "./views/teams/Team"
import { Teams } from "./views/teams/Teams"
import { GoToClassroom } from "./views/GoToClassroom"
import { useQueryParam } from "./hooks/useQueryParam"
import { Container } from "./components/Container"

export function App() {
  const tokenResult = useAuthIdToken(["token"], auth)
  const location = useLocation()
  const history = useHistory()
  const logMutation = useLoggerMutation()

  const queryParam = useQueryParam()
  const tenantId = queryParam.get("tenantId")

  // eslint-disable-next-line no-extend-native
  String.prototype.toSentenceCase = function () {
    const str = this.toLowerCase().split(" ")
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(" ")
  }

  // Loggin useEffect
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production" && tokenResult.data) {
      logMutation.mutate({
        data: {
          event: "route",
          context: {
            path: location.pathname,
          },
        },
      })
    }
  }, [location])

  // Check for classroom local storage at root and redirect
  useEffect(() => {
    if (
      !location.pathname.includes("enrolments") &&
      !location.pathname.includes("auth") &&
      window.localStorage.getItem("classroom") &&
      window.localStorage.getItem("classroomOnly")
    ) {
      history.push("/go-to-classroom")
    }
  }, [location.pathname])

  const handleOnline = (e) => {
    notification.destroy()
  }
  const handleOffline = (e) => {
    notification.error({
      message: "Network is offline",
      description: "It seems as though you are offline, please re-connect.",
      duration: null,
    })
  }
  useEffect(() => {
    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)
    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  const createSignInSearchParams = (obj) => {
    let params = {}

    if (obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key]) {
          params = {
            ...params,
            [key]: obj[key],
          }
        }
      })
    }
    return new URLSearchParams(params).toString()
  }
  const memoizedReturnUrl = useMemo(() => {
    if (!tokenResult.isLoading && tokenResult.data) {
      return window.location.pathname
    }
    return queryParam.get("returnUrl") || "/"
  }, [tokenResult.isLoading, tokenResult.data])

  return (
    <>
      {tokenResult.isLoading ? (
        <>
          <Skeleton.Input style={{ width: "100dvw", height: "45vh" }} active />
          <Container style={{ marginTop: "2.5rem" }}>
            <Space size={15} style={{ marginBottom: "1.125rem" }}>
              <Skeleton.Button active />
              <Skeleton.Button active />
              <Skeleton.Button active />
            </Space>
            <Row gutter={["32"]}>
              <Col xs={24} lg={18}>
                <Skeleton.Button size="small" style={{ margin: "1rem 0" }} />
                <Card size="small" className="respCard">
                  <Row>
                    <Col xs={24} md={8}>
                      <Skeleton.Image style={{ width: "12.5rem", height: "12.5rem" }} />
                    </Col>
                    <Col xs={24} md={13} className="content">
                      <Skeleton />
                    </Col>
                  </Row>
                </Card>
                <Card size="small" className="respCard">
                  <Row>
                    <Col xs={24} md={8}>
                      <Skeleton.Image style={{ width: "12.5rem", height: "12.5rem" }} />
                    </Col>
                    <Col xs={24} md={13} className="content">
                      <Skeleton />
                    </Col>
                  </Row>
                </Card>
                <Card size="small" className="respCard">
                  <Row>
                    <Col xs={24} md={8}>
                      <Skeleton.Image style={{ width: "12.5rem", height: "12.5rem" }} />
                    </Col>
                    <Col xs={24} md={13} className="content">
                      <Skeleton />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="hide--onMobile" xs={24} lg={6}>
                <Skeleton.Button size="small" style={{ margin: "1rem 0" }} />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <Suspense fallback="Loading...">
          <Route exact path="/auth/sign-in" component={SignInWrapper} />
          <Route exact path="/auth/self-register" component={SelfRegister} />
          <Route exact path="/auth/register" component={Register} />
          <Route exact path="/auth/classroom" component={Classroom} />
          <Route exact path="/go-to-classroom" component={GoToClassroom} />
          {tokenResult.data ? (
            <>
              {!window.localStorage.getItem("classroom") && !window.localStorage.getItem("classroomOnly") && (
                <>
                  <ThemeProvider>
                    <Navbar />
                    <MobileNavbar />
                    <Route exact path="/go-to-classroom" component={GoToClassroom} />
                    <Route exact path="/" component={MyLearning} />
                    <Route path="/courses/:courseUid" component={Courses} />
                    <Route path="/programs/:programUid" component={Programs} />
                    <Route exact path="/externals/:externalUid" component={Externals} />
                    <Route exact path="/externals/:externalUid/learning" component={ExternalLearning} />
                    <Route exact path="/teams" component={Teams} />
                    <Route exact path="/explore" component={Explore} />
                    <Route path="/chat" component={Chat} />
                    <Route path="/teams/:teamUid" component={Team} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/profile/edit" component={() => <Edit />} />
                  </ThemeProvider>
                </>
              )}
              <LessonProvider>
                <Route
                  path="/enrolments/:enrolmentUid/modules/:moduleUid/learning/:lessonUid/:enrolmentType"
                  component={Modules}
                />
              </LessonProvider>
            </>
          ) : (
            <Route path="*">
              <Redirect
                to={`/auth/${
                  window.location.pathname.includes("auth/self-register")
                    ? "self-register"
                    : window.location.pathname.includes("auth/register")
                    ? "register"
                    : window.location.pathname.includes("auth/classroom")
                    ? "classroom"
                    : "sign-in"
                }/?${createSignInSearchParams({
                  tenantId: tenantId || window.localStorage.getItem("tenantId"),
                  returnUrl: memoizedReturnUrl,
                })}`}
              />
            </Route>
          )}
        </Suspense>
      )}
    </>
  )
}

import { Form, Input, Typography } from "antd"
import React from "react"
import { UserOutlined, LockOutlined } from "@ant-design/icons"

export function EmailPassword({
  children,
  emailError,
  emailPlaceholder,
  type,
  title = "Sign In",
  description = "Sign in with your email and password.",
}) {
  return (
    <>
      <Typography.Title style={{ color: "white" }} level={3}>
        {title}
      </Typography.Title>
      <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>{description}</Typography.Text>
      <Form.Item
        //
        name="email"
        rules={[{ required: true, message: emailError || "Please enter your email address" }]}
      >
        <Input
          //
          type={type || "email"}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder={emailPlaceholder || "Email"}
        />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: "Please enter your password" }]}>
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          label="Password"
          placeholder="Password"
        />
      </Form.Item>
      {children}
    </>
  )
}

import { Col } from "antd"
import dayjs from "dayjs"
import React, { useMemo } from "react"
import { useQueryClient } from "react-query"
import { progressFactory } from "../helpers/progressFactory"
import { ResponsiveCard } from "./cards/ResponsiveCard"
import { SmallCard } from "./cards/SmallCard"
import { fetchEnrolment } from "../network/useEnrolment"

import { useTranslation } from "react-i18next"

export function CourseLearning({ view, courses }) {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  if (view === "list" && Array.isArray(courses) && courses?.length) {
    return (
      <Col span={24}>
        {courses?.map((course) => {
          let topics = course.publication.topics ?? []
          const difference = dayjs().diff(course?.createdAt, "days")
          if (difference <= 12 && course?.currentState === "not-started") {
            topics.unshift("New")
          }
          return (
            <ResponsiveCard
              onMouseEnter={() =>
                queryClient.prefetchQuery([course?.uid], () => fetchEnrolment(course?.uid, queryClient), {
                  staleTime: 1000 * 30,
                })
              }
              progress={progressFactory.course(course).resultAsPercent}
              key={course.uid}
              href={`/${course.publication.type}s/${course.uid}`}
              title={course?.publication?.content?.title}
              url={course?.publication?.featureImageUrl}
              desc={course?.publication?.content?.summary}
              topics={topics}
              meta1={course?.dueDate ? dayjs(course?.dueDate).format("DD/MM/YYYY") : t("noDueDate")}
              meta2={`${course.publication.materialCount} ${
                course.publication.materialCount > 1 ? t("modules") : t("module")
              }`}
              course={course}
            />
          )
        })}
      </Col>
    )
  }
  if (view === "grid" && Array.isArray(courses) && courses?.length) {
    return (
      <>
        {courses?.map((course) => {
          let topics = course.publication.topics ?? []
          const difference = dayjs().diff(course?.createdAt, "days")
          if (difference <= 12 && course?.currentState === "not-started") {
            topics.unshift("New")
          }
          return (
            <Col key={course.uid} xs={24} md={12}>
              <SmallCard
                onMouseEnter={() =>
                  queryClient.prefetchQuery([course.uid], () => fetchEnrolment(course?.uid, queryClient), {
                    staleTime: 1000 * 30,
                  })
                }
                progress={progressFactory.course(course).resultAsPercent}
                href={`/${course.publication.type}s/${course.uid}`}
                title={course?.publication?.content?.title}
                url={course?.publication?.featureImageUrl}
                desc={course?.publication?.content?.summary}
                topics={topics}
                meta1={course?.dueDate ? dayjs(course?.dueDate).format("DD/MM/YYYY") : t("noDueDate")}
                meta2={`${course.publication.materialCount} ${
                  course.publication.materialCount > 1 ? t("modules") : t("module")
                }`}
                course={course}
              />
            </Col>
          )
        })}
      </>
    )
  }
  return null
}

import { Card, Col, Row } from "antd"
import React from "react"
import { QuizCard } from "./QuizCard"
import { VideoCard } from "./VideoCard"

export function LessonCard({ card, setShowNext, showNext, index, position, style }) {
  if (card.content[0]?.material.includes('<video class="ql-video"')) {
    return (
      <Col xs={24} lg={24} style={style}>
        <VideoCard
          index={index}
          position={position}
          setShowNext={setShowNext}
          showNext={showNext}
          html={card.content[0]?.material}
          id={card.content[0]?.id}
        />
      </Col>
    )
  }
  return (
    <Col style={style} xs={24} lg={card.orientation === "portrait" ? 12 : 24}>
      {card.content.length < 2 ? (
        <Card
          className={`learning__card ${
            card.orientation === "portrait" ? "learning__card--portrait" : "learning__card--landscape"
          } ql-editor`}
        >
          {card.content[0]?.material.includes('class="interactive"') ? (
            <>
              <QuizCard html={card.content[0]?.material} id={card.content[0]?.id} />
            </>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: card.content[0]?.material }} />
          )}
        </Card>
      ) : (
        <Row gutter={16} style={style}>
          <Col span={12}>
            <Card className="learning__card learning__card--portrait ql-editor">
              {card.content[0]?.material.includes("interactive") ? (
                <QuizCard html={card.content[0]?.material} id={card.content[0]?.id} />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: card.content[0]?.material,
                  }}
                />
              )}
            </Card>
          </Col>
          <Col span={12}>
            <Card className="learning__card learning__card--portrait ql-editor">
              {card.content[1]?.material.includes("interactive") ? (
                <QuizCard html={card.content[1]?.material} id={card.content[1]?.id} />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: card.content[1]?.material,
                  }}
                />
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Col>
  )
}

import React, { useState, useEffect } from "react"
import { Button, Col, Form, Input, Row, Space } from "antd"
import { Draggable } from "../Draggable"
import uuidv4 from "../../hooks/uuid"
import { useTranslation } from "react-i18next"

export function MatchColumns({
  question,
  questionIndex,
  form,
  dispatch,
  isFacilitate = false,
  disabled = false,
  inReview = false,
}) {
  const [answers, setAnswers] = useState([])
  const [options, setOptions] = useState([])
  const { t } = useTranslation()

  const getDragItems = (key, answers) => {
    if (answers.length < question.answers.filter((question) => question.correct > -1).length) {
      dispatch({
        type: "throwError",
        payload: `Please select ${question.answers?.length}`,
      })
    } else dispatch({ type: "field", field: "canProceed", payload: true })

    form.setFieldsValue({
      [key]: answers,
    })
  }

  useEffect(() => {
    const answers = form.getFieldValue(`q-${questionIndex + 1}`) || []
    const ansUids = answers.map((x) => x.uid) || []
    const filteredQuestions = question.answers.filter((option) => !ansUids.includes(option?.uid))
    setAnswers(answers)
    setOptions(filteredQuestions)
  }, [])

  return (
    <>
      <Form.List className="" name={`q-${questionIndex + 1}`}>
        {(fields) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space className="sr-only" key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                <Form.Item hidden {...restField} name={[name, "uid"]}>
                  <Input placeholder="uid" />
                </Form.Item>
                <Form.Item hidden {...restField} name={[name, "correct"]} className="">
                  <Input placeholder="correct" />
                </Form.Item>
                <Form.Item hidden {...restField} name={[name, "title"]} className="">
                  <Input placeholder="title" />
                </Form.Item>
              </Space>
            ))}
          </>
        )}
      </Form.List>
      <Row gutter="16">
        <Col span={12} className="match__list">
          {question?.title?.split("[$]").map((title, i) => {
            if (title !== "") {
              return (
                <div key={uuidv4()} className="list__item">
                  <div className="missing__opt missing__opt--column">{title}</div>
                  <Button shape="circle" style={{ marginLeft: "8px" }} type="primary">
                    {i + 1}
                  </Button>
                </div>
              )
            }
            return null
          })}
        </Col>
        <Col span={12}>
          {inReview ? (
            <div className="fill__missing column">
              {form.getFieldValue(`q-${questionIndex + 1}`) &&
                form.getFieldValue(`q-${questionIndex + 1}`).map((ans, i) => (
                  <div
                    className={`missing__opt ${
                      isFacilitate !== "true" && i === parseInt(ans.correct)
                        ? "missing__opt--correct"
                        : "missing__opt--wrong"
                    }`}
                    key={ans.uid}
                  >
                    {ans.title}
                  </div>
                ))}
            </div>
          ) : (
            <Draggable onChange={getDragItems} question={`q-${questionIndex + 1}`} className="fill__missing column">
              {answers.map((answer) => (
                <p data-correct={answer.correct} data-uid={answer.uid} key={answer.uid} className="missing__opt">
                  {answer.title}
                </p>
              ))}
            </Draggable>
          )}
        </Col>
      </Row>
      {!inReview && (
        <>
          <p className="instruction">{t("dragItemFrom")}</p>
          <Draggable direction="horizontal" className="dropzone dropzone--smallMTop empty">
            {options.map((answer) => (
              <p data-correct={answer.correct} data-uid={answer.uid} key={answer.uid} className="missing__opt">
                {answer?.title || answer?.translations?.["1"]?.title}
              </p>
            ))}
          </Draggable>
        </>
      )}
    </>
  )
}

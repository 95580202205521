import React, { useState } from "react"
import { Button, notification, Space, Tag, Typography } from "antd"
import { Link, useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { useGetPerson } from "../network/usePerson"
import { useEnrolmentMutation } from "../network/useEnrolment"
import { useTranslation } from "react-i18next"

export function EnrolmentActionButton({ enrolment, size = "middle" }) {
  const personQuery = useGetPerson({ authenticated: true })
  const enrolmentMutation = useEnrolmentMutation(enrolment?.uid, true)

  const { push } = useHistory()
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  return (
    <Space>
      {enrolment?.isLocked ? (
        <>
          <Typography.Text style={{ color: "white" }}>
            This {enrolment?.publication?.type} requires the following to be completed first:
          </Typography.Text>
          <Link to={`/${enrolment?.preRequisite?.type}s/${enrolment?.preRequisite?.publicationEnrolmentUid}`}>
            <Button type="primary">{enrolment?.preRequisite?.title}</Button>
          </Link>
        </>
      ) : (
        <Button
          size={size}
          loading={loading}
          type="primary"
          onClick={async (event) => {
            event.preventDefault()
            if (enrolment.currentState === "not-started") {
              setLoading(true)
              notification.success({
                message: `Hey, ${personQuery.data?.firstName}!`,
                description: `Thank you for starting the ${enrolment?.publication?.translations["1"]?.title} ${enrolment?.publication?.type}, we hope you enjoy it.`,
              })
              await enrolmentMutation.mutateAsync(
                {
                  enrolmentUid: enrolment?.uid,
                  data: {
                    currentState: "in-progress",
                    startedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
                  },
                },
                {
                  onSettled: () => {
                    setLoading(false)
                  },
                }
              )
            }
            if (enrolment?.continue) {
              if (!enrolment?.continue?.moduleUid || !enrolment?.continue?.lessonUid) {
                if (enrolment.publication?.type === "course") {
                  push(
                    `/enrolments/${enrolment?.uid}/modules/${enrolment?.publication?.material?.[0]?.uid}/learning/${enrolment?.publication?.material?.[0]?.learning?.[0]?.uid}/${enrolment?.publication?.type}s`
                  )
                } else if (enrolment.publication.type === "program") {
                  push(
                    `/enrolments/${enrolment?.uid}/modules/${enrolment?.publication?.material?.[0]?.modules?.[0]?.uid}/learning/${enrolment?.publication?.material?.[0]?.modules?.[0]?.learning?.[0]?.uid}/${enrolment?.publication?.type}s`
                  )
                }
              } else {
                push(
                  `/enrolments/${enrolment?.uid}/modules/${enrolment?.continue?.moduleUid}/learning/${enrolment?.continue?.lessonUid}/${enrolment?.publication?.type}s`
                )
              }
            } else if (enrolment.publication?.type === "course") {
              push(
                `/enrolments/${enrolment?.uid}/modules/${enrolment?.publication?.material?.[0]?.uid}/learning/${enrolment?.publication?.material?.[0]?.learning?.[0]?.uid}/${enrolment?.publication?.type}s`
              )
            } else if (enrolment.publication.type === "program") {
              push(
                `/enrolments/${enrolment?.uid}/modules/${enrolment?.publication?.material?.[0]?.modules?.[0]?.uid}/learning/${enrolment?.publication?.material?.[0]?.modules?.[0]?.learning?.[0]?.uid}/${enrolment?.publication?.type}s`
              )
            }
          }}
          style={{
            textTransform: "capitalize",
          }}
        >
          {/* Add translations */}
          {enrolment?.currentState === "not-started" || enrolment?.currentState === "completed" ? (
            <>
              {enrolment?.publication?.type === "course" && t("goToCourse")}
              {enrolment?.publication?.type === "program" && t("goToProgram")}
              {enrolment?.publication?.type === "external" && t("goToExternal")}
            </>
          ) : null}
          {enrolment?.currentState === "in-progress" ? (
            <>
              {enrolment?.publication?.type === "course" && t("resumeCourse")}
              {enrolment?.publication?.type === "program" && t("resumeProgram")}
              {enrolment?.publication?.type === "external" && t("resumeExternal")}
            </>
          ) : null}
        </Button>
      )}
      {enrolment?.currentState === "completed" && (
        <Tag color="green">
          {t("Completed")}: {dayjs(enrolment?.completedAt).format("DD/MM/YYYY")}
        </Tag>
      )}
    </Space>
  )
}

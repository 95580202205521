import {
  Avatar,
  Result,
  Button,
  Card,
  Col,
  Collapse,
  Comment,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Rate,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd"
import React, { useMemo, useEffect, useState } from "react"
import {
  EditOutlined,
  SendOutlined,
  ClockCircleOutlined,
  StopOutlined,
  StarFilled,
  InfoCircleFilled,
  DollarOutlined,
  ExportOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons"
import { Link, useParams, useLocation } from "react-router-dom"
import dayjs from "dayjs"
import { ProgramModuleModal } from "./ProgramModuleModal"
import { useEnrolmentMutation, useGetEnrolment } from "../../../network/useEnrolment"
import { Hero } from "../../../components/Hero"
import { Container } from "../../../components/Container"
import { MinifiedCard } from "../../../components/cards/MinifiedCard"
import { SmallCard } from "../../../components/cards/SmallCard"
import { makeProfileImg } from "../../../hooks/makeProfileImg"
import { EnrolmentActionButton } from "../../../components/EnrolmentActionButton"
import { progressFactory } from "../../../helpers/progressFactory"
import { tabFactory } from "../../../helpers/tabsFactory"
import { TagFactory } from "../../../components/TagFactory"
import { useGetMaterial } from "../../../network/useMaterial"
import { isModuleLocked } from "../../../helpers/isModuleLocked"
import { fetchModule } from "../../../network/useModule"
import { useQueryClient } from "react-query"
import { useGetQueryParam } from "../../../hooks/useGetQueryParam"
import { CertificatePreviews } from "../../../components/CertificatePreviews"
import { useGetMedia } from "../../../network/useMedia"
import { useTranslation } from "react-i18next"
// import { useEnrolmentActivtyMutation } from '../../../network/useEnrolmentActivity'

const { Panel } = Collapse
const { TabPane } = Tabs
const { Title } = Typography

export function Programs() {
  const { programUid } = useParams()
  const programQuery = useGetEnrolment({ uid: programUid })
  const materialQuery = useGetMaterial({ enrolmentUid: programUid })
  const enrolmentMutation = useEnrolmentMutation(programUid)
  const [form] = Form.useForm()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { get: getModule } = useGetQueryParam("moduleUid")
  const { get: getSection } = useGetQueryParam("sectionUid")
  const { t } = useTranslation()

  const [noticeVisible, setNoticeVisible] = useState(false)

  const [mediaTypeUid, setMediaTypeUid] = useState(null)
  const [mediaType, setMediaType] = useState(null)
  const mediaQuery = useGetMedia({
    type: mediaType,
    typeUid: mediaTypeUid,
  })

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!programQuery.isLoading && programQuery.data) {
      setMediaType(programQuery.data?.publication?.type + "s")
      setMediaTypeUid(programQuery.data?.publication?.typeUid)
    }
  }, [programQuery.isLoading, programQuery.data])

  const programProgress = useMemo(() => {
    if (!programQuery.isLoading && programQuery.data) {
      if (programQuery.data?.publication?.noticeUrl) {
        setNoticeVisible(true)
      }
      return progressFactory.program(programQuery.data).resultAsPercent
    }
  }, [programQuery.isLoading, programQuery.data])

  const expired = useMemo(() => {
    if (!programQuery.isLoading && programQuery.data) {
      if (programQuery?.data?.currentState !== "completed") return ""
      if (!programQuery?.data?.completionValidUntil) return ""
      const difference = dayjs(programQuery?.data?.completionValidUntil).diff(dayjs(), "days")

      if (difference > 0) {
        if (difference > 365) {
          return (
            <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
              Expires in over a year
            </TagFactory>
          )
        } else {
          return (
            <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
              Expires {dayjs().to(programQuery?.data?.completionValidUntil)}
            </TagFactory>
          )
        }
      } else if (difference < 0) {
        return (
          <TagFactory icon={<StopOutlined />} status="Expired">
            Expired {dayjs(programQuery?.data?.completionValidUntil).from(dayjs())}
          </TagFactory>
        )
      }
    }
    return ""
  }, [programQuery.isLoading, programQuery.data])

  return (
    <>
      {!programQuery.isLoading ? (
        <>
          {programQuery.data && !programQuery.isError ? (
            <>
              <Link className="floatingBack floatingBack--fixed floatingBack--dark" to="/">
                {t("Back")}
              </Link>
              {getModule()?.[0] && getSection()?.[0] && (
                <ProgramModuleModal
                  moduleUid={getModule()?.[0]}
                  sectionUid={getSection()?.[0]}
                  programQuery={programQuery}
                />
              )}
              <Modal
                footer={null}
                visible={noticeVisible}
                onCancel={() => setNoticeVisible(false)}
                className="modal modal--noPadding"
              >
                <img
                  style={{ width: "100%", objectFit: "contain" }}
                  src={programQuery.data?.publication?.noticeUrl}
                  alt=""
                />
              </Modal>
              <Hero>
                <Hero.Slide
                  shadowless={programQuery.data.publication.translations?.["1"]?.title === "I•WIN Demo"}
                  img={programQuery.data.publication.featureImageUrl}
                >
                  <Comment
                    avatar={
                      programQuery.data.publication.publishedBy?.imageUrl ||
                      makeProfileImg(`${programQuery.data.publication.publishedBy?.name[0]}`)
                    }
                    author={<p style={{ color: "white", marginBottom: 0 }}>Publisher</p>}
                    content={programQuery.data.publication.publishedBy?.name}
                  />
                  <Space size={20} align="center">
                    <Hero.Title style={{ margin: 0 }}>
                      {programQuery.data.publication.translations?.["1"]?.title}
                    </Hero.Title>
                    <Space>
                      {programQuery.data?.publication?.noticeUrl ? (
                        <InfoCircleFilled onClick={() => setNoticeVisible(true)} size={24} />
                      ) : null}
                      {programQuery.data?.publication?.hasReward ? (
                        <Tooltip title="This enrolment will earn you a reward">
                          <DollarOutlined size={24} />
                        </Tooltip>
                      ) : null}
                    </Space>
                  </Space>
                  <Hero.Description>{programQuery.data.publication.translations["1"]?.summary}</Hero.Description>
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      alignItems: "center",
                    }}
                    gutter={32}
                  >
                    {programQuery?.isLoading || programQuery?.isFetching ? (
                      <Button type="primary" loading>
                        Determining Progress
                      </Button>
                    ) : (
                      <EnrolmentActionButton enrolment={programQuery.data} />
                    )}
                    {programQuery.data.publication?.reviewScore ? (
                      <div>
                        <StarFilled style={{ color: "#FFD700" }} />
                        <span>
                          {" "}
                          {programQuery.data.publication?.reviewScore} {t("Rating")}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  {expired}
                  {programProgress && programProgress > 0 && programProgress < 100 ? (
                    <div className="progress">
                      <Tooltip title={`Program Progress: ${programProgress}%`}>
                        <div className="percentage" style={{ width: `${programProgress}%` }} />
                      </Tooltip>
                    </div>
                  ) : null}
                </Hero.Slide>
              </Hero>
              <Container style={{ marginTop: "2.5rem", paddingBottom: "7rem" }}>
                <Tabs
                  style={{ marginTop: "1rem", padding: "0 1rem" }}
                  defaultActiveKey={tabFactory().get() || "Courses"}
                  onChange={(keyValue) => tabFactory().onChange(keyValue)}
                >
                  <TabPane style={{ marginTop: ".75rem" }} tab={t("About")} key="About">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: programQuery.data.publication.translations?.["1"]?.descriptionAsHTML,
                      }}
                    />
                  </TabPane>
                  <TabPane style={{ marginTop: ".75rem" }} tab={t("Curriculum")} key="Curriculum">
                    <Timeline>
                      {!materialQuery?.isLoading && materialQuery?.data?.length ? (
                        <>
                          {materialQuery?.data.map((section, sectionIndex) => (
                            <React.Fragment key={section.uid}>
                              <Timeline.Item dot={<Button type="primary">{sectionIndex + 1}</Button>} color="green">
                                <div style={{ marginLeft: "1rem" }}>
                                  <Space style={{ marginBottom: "1rem" }} size={16}>
                                    <Title style={{ margin: 0 }} level={5}>
                                      {section?.title}
                                    </Title>
                                    {programQuery?.data?.activity?.find(
                                      (x) => x?.typeUid === section?.uid && x?.status === "completed"
                                    ) && <Tag color="green">Completed</Tag>}
                                  </Space>
                                  <Row gutter={32}>
                                    {section?.modules?.length &&
                                      section?.modules.map((mod) => (
                                        <Col key={mod.uid} xs={12} lg={8}>
                                          <MinifiedCard
                                            title={mod?.title}
                                            url={mod?.featureImageUrl}
                                            meta={
                                              mod.programSectionModule?.dueDate
                                                ? dayjs(mod.programSectionModule?.dueDate).format("DD/MM/YYYY")
                                                : t("noDueDate")
                                            }
                                          />
                                        </Col>
                                      ))}
                                  </Row>
                                </div>
                              </Timeline.Item>
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <Empty
                          image="/empty.png"
                          imageStyle={{
                            height: 60,
                          }}
                          description={<span>Sorry, this program does not have any sections.</span>}
                        >
                          <Button href="/" type="primary">
                            Back to My Learning
                          </Button>
                        </Empty>
                      )}
                    </Timeline>
                  </TabPane>
                  <TabPane tab={t("Resources")} key="Resources">
                    {!mediaQuery.isLoading ? (
                      <Row gutter={[32, 32]}>
                        {mediaQuery.data && mediaQuery.data?.files?.length > 0 && !mediaQuery.isError ? (
                          <>
                            {mediaQuery.data?.files?.map((file) => (
                              <Col key={file.uid} xs={24} lg={8}>
                                <Card
                                  cover={
                                    file?.media?.type === "image" ? (
                                      <img
                                        alt="example"
                                        style={{
                                          aspectRatio: "16/9",
                                          objectFit: "cover",
                                        }}
                                        src={file?.media?.url}
                                      />
                                    ) : (
                                      <img
                                        alt="example"
                                        style={{
                                          aspectRatio: "16/9",
                                          objectFit: "cover",
                                        }}
                                        preview={false}
                                        src={`/${file?.media?.type}.png`}
                                      />
                                    )
                                  }
                                  actions={[
                                    <Tooltip title="Download" key="download">
                                      <a href={file?.media?.url} download target="_blank">
                                        <CloudDownloadOutlined />
                                      </a>
                                    </Tooltip>,
                                    <Tooltip key="Share" title="Share">
                                      <ExportOutlined
                                        onClick={() => {
                                          clipboard.copy(file?.media?.url)
                                          message.success("Copied link")
                                        }}
                                      />
                                    </Tooltip>,
                                  ]}
                                >
                                  <Card.Meta
                                    title={file.media.title}
                                    description={
                                      <Space direction="vertical">
                                        <TagFactory style={{ textTransform: "capitalize" }} status={file?.media?.type}>
                                          {file?.media?.type}
                                        </TagFactory>
                                      </Space>
                                    }
                                  />
                                </Card>
                              </Col>
                            ))}
                          </>
                        ) : (
                          <Result
                            style={{ width: "100%" }}
                            status="404"
                            title="No Resources Yet"
                            subTitle="Any resources uploaded will appear here."
                          />
                        )}
                      </Row>
                    ) : (
                      <Skeleton />
                    )}
                  </TabPane>
                  <TabPane style={{ marginTop: ".75rem" }} tab={t("Courses")} key="Courses">
                    {!programQuery.isLoading && materialQuery?.data?.length ? (
                      <Row gutter={["16"]}>
                        <Col xs={24} md={18}>
                          {materialQuery?.data.map((section, sectionIndex) => (
                            <Collapse className="no-padding" key={section.uid} ghost defaultActiveKey={["1", "2"]}>
                              <Panel
                                header={
                                  <Space size={16}>
                                    <Title style={{ margin: 0 }} level={5}>
                                      {section?.title}
                                    </Title>
                                    {programQuery?.data?.activity?.find(
                                      (x) => x?.typeUid === section?.uid && x?.status === "completed"
                                    ) && <Tag color="green">{t("Completed")}</Tag>}
                                  </Space>
                                }
                                key="1"
                              >
                                <Row gutter={["16"]}>
                                  {section?.modules?.length &&
                                    section?.modules.map((mod) => {
                                      const tags = []
                                      if (
                                        programQuery?.data?.activity?.find(
                                          (x) => x?.typeUid === mod?.uid && x?.status === "completed"
                                        )
                                      ) {
                                        tags?.push(t("Completed"))
                                      } else {
                                        tags?.push({
                                          value: `${t("Lessons")}: ${mod?.lessonCount}`,
                                          color: "blue",
                                        })
                                      }
                                      return (
                                        <Col key={mod.uid} xs={24} md={12}>
                                          <SmallCard
                                            onMouseEnter={() =>
                                              queryClient.prefetchQuery(
                                                [programQuery?.data?.uid, "module", mod?.uid],
                                                () => fetchModule(programQuery?.data?.uid, mod?.uid, queryClient),
                                                {
                                                  staleTime: 1000 * 60 * 2,
                                                }
                                              )
                                            }
                                            href={`/programs/${programUid}?moduleUid=${mod.uid}&sectionUid=${section?.uid}`}
                                            title={mod?.title}
                                            url={mod.featureImageUrl}
                                            meta1={
                                              mod.programSectionModule?.dueDate
                                                ? dayjs(mod.programSectionModule?.dueDate).format("DD/MM/YYYY")
                                                : t("noDueDate")
                                            }
                                            topics={tags}
                                            locked={
                                              programQuery?.data?.currentState === "completed"
                                                ? false
                                                : isModuleLocked(
                                                    mod,
                                                    programQuery?.data?.publication,
                                                    programQuery?.data?.activity,
                                                    sectionIndex,
                                                    programQuery?.data?.isLocked
                                                  )
                                            }
                                          />
                                        </Col>
                                      )
                                    })}
                                </Row>
                              </Panel>
                            </Collapse>
                          ))}
                        </Col>
                        <Col xs={24} md={6}>
                          <Title level={5}>{programQuery.data.feedback ? t("Review") : t("leaveAReview")}</Title>
                          {!programQuery.data.feedback && (
                            <>
                              <p className="badge--meta">{t("rateOutOf5")}</p>
                              <Form
                                name="review"
                                form={form}
                                onFinish={(values) => {
                                  enrolmentMutation.mutate(
                                    {
                                      enrolmentUid: programUid,
                                      data: {
                                        feedback: {
                                          ...values,
                                        },
                                      },
                                    },
                                    {
                                      onSuccess: () => {
                                        form.resetFields()
                                        queryClient.invalidateQueries(programUid)
                                      },
                                    }
                                  )
                                }}
                              >
                                <Form.Item style={{ margin: 0 }} name="rating" rules={[{ required: true }]}>
                                  <Rate />
                                </Form.Item>
                                <div className="filter no-marg-top">
                                  <Form.Item
                                    className="filter__input filter__input--noMargin"
                                    name="comment"
                                    rules={[{ required: true }]}
                                  >
                                    <Input
                                      bordered={false}
                                      placeholder={t("leaveAComment") + "..."}
                                      prefix={<EditOutlined />}
                                    />
                                  </Form.Item>
                                  <Button
                                    loading={enrolmentMutation.isLoading}
                                    htmlType="submit"
                                    type="primary"
                                    icon={<SendOutlined />}
                                    size="small"
                                  />
                                </div>
                              </Form>
                              <Divider />
                            </>
                          )}
                          {programQuery?.data?.feedback && (
                            <Comment
                              content={<p>{programQuery?.data?.feedback?.comment}</p>}
                              datetime={`${programQuery?.data?.feedback?.rating}/5`}
                            />
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <Empty
                        image="/empty.png"
                        imageStyle={{
                          height: 60,
                        }}
                        description={<span>Sorry, this program does not have any sections.</span>}
                      >
                        <Link to="/">
                          <Button type="primary">Back to My Learning</Button>
                        </Link>
                      </Empty>
                    )}

                    <Divider />
                  </TabPane>
                  {programQuery?.data?.currentState === "completed" ? (
                    <TabPane tab="Certificate" key={"Certificate"}>
                      <CertificatePreviews enrolment={programQuery.data} />
                    </TabPane>
                  ) : null}
                </Tabs>
              </Container>
            </>
          ) : (
            <Empty
              image="/empty.png"
              imageStyle={{
                height: 60,
              }}
              description={<span>Something went wrong fetching this program</span>}
            >
              <Button href="/" type="primary">
                Back to My Learning
              </Button>
            </Empty>
          )}
        </>
      ) : (
        <>
          <Skeleton.Input style={{ width: window.width, height: "40vh" }} active />
          <Container style={{ marginTop: "2.5rem" }}>
            <Row gutter="32">
              {[1, 1, 1].map((_, i) => (
                <Col xs={24} md={12} lg={8} key={i}>
                  <Card size="small" bordered={false} className="smallCard">
                    <Skeleton active />
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
      {/* <Switch>
        <Route
          exact
          path="/programs/:programUid/:moduleUid/:sectionUid"
          component={() => }
        />
      </Switch> */}
    </>
  )
}

import { useEffect, useState } from "react"

export const useDebounce = (value, delay, dispatch) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
      if (dispatch) dispatch({ type: "search", payload: value })
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}

import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useGetPublication = ({ enrolmentUid, filters }) => {
  const queryClient = useQueryClient()
  let params = {}

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key],
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()

  return useQuery(
    ["publication", enrolmentUid, params],
    async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/learn/publications/${enrolmentUid}${
            urlParams && urlParams.length ? `?${urlParams}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
              "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            },
          }
        )
        .then((res) => {
          return res.data.data
        }),
    {
      staleTime: 1000 * 60 * 15,
      retry: false,
      enabled: !!enrolmentUid,
      onError: (error) => {
        message.error(`Failed to fetch publication: ${error.response.data.message}`)
      },
    }
  )
}

export const useGetPublications = (filters) => {
  let params = {}

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      params = {
        ...params,
        [key]: filters[key],
      }
    }
  })

  const urlParams = new URLSearchParams(params).toString()
  const queryClient = useQueryClient()
  return useQuery(
    ["publications", params],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/learn/publications/explore${urlParams.length ? `?${urlParams}` : ""}`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 15,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch publications: ${error.response.data.message}`)
      },
    }
  )
}
export const useGetPublicationsFilters = () => {
  const queryClient = useQueryClient()
  return useQuery(
    "publication-filters",
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/learn/publications/filters`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 15,
      retry: false,
      onError: (error) => {
        message.error(`Failed to fetch publication filters: ${error.response.data.message}`)
      },
    }
  )
}
export const useGetEvents = () => {
  const queryClient = useQueryClient()
  return useQuery(
    "events",
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/learn/publications/events?requestEnrol=true`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      staleTime: 1000 * 60 * 15,
      retry: false,
      onError: (error) => {
        // message.error(`Failed to fetch events: ${error.response.data.message}`)
      },
    }
  )
}

export const useEnrolToIntegrationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) => {
      console.log("body::: ", body)
      return axios
        .post(
          `${process.env.REACT_APP_API_URL}/learn/publications/enrol/external/${body.integrationId}/${body.externalId}`,
          body.data,
          {
            headers: {
              Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
              "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            },
          }
        )
        .then((res) => res.data)
    },
    {
      onSuccess: (res) => {
        // message.success('Successfully enrolled')
        queryClient.invalidateQueries("enrolments")
      },
      onError: (error) => {
        message.error(`Failed to enrol: ${error.response.data.message}`)
      },
    }
  )
}
export const useEnrolToPublicationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/learn/publications/${body.publicationUid}/enrol/self`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        message.success("Successfully enrolled")
        queryClient.invalidateQueries("enrolments")
      },
      onError: (error) => {
        message.error(`Failed to enrol: ${error.response.data.message}`)
      },
    }
  )
}
export const useEnrolSomeoneElseToPublicationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/learn/publications/${body.publicationUid}/enrol/${body?.data?.personUid}`,
          body.data,
          {
            headers: {
              Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
              "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        message.success("Successfully enrolled")
        queryClient.invalidateQueries("enrolments")
      },
      onError: (error) => {
        message.error(`Failed to enrol: ${error.response.data.message}`)
      },
    }
  )
}

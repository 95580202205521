import { ArrowLeftOutlined, BarsOutlined, BookOutlined, HomeOutlined, ProjectOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Layout,
  Modal,
  PageHeader,
  Result,
  Row,
  Skeleton,
  Space,
  Steps,
  Typography,
} from "antd"
import React, { useEffect, useMemo, useState } from "react"
import Confetti from "react-confetti"
import { Link, useParams } from "react-router-dom"
import { CertificateDrawer } from "../../components/CertificateDrawer"
import { CertificateDrawerWithFetch } from "../../components/CertificateDrawerWithFetch"
import { useLesson } from "../../hooks/lesson-context"
import { useQueryParam } from "../../hooks/useQueryParam"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { useGetEnrolment } from "../../network/useEnrolment"
import { useGetLesson } from "../../network/useLearning"
import { useGetModule } from "../../network/useModule"
import { Assessments } from "./learning/Assessments"
import { Assignments } from "./learning/Assignments"
import { Lessons } from "./learning/lessons/Lessons"
import { useTranslation } from "react-i18next"

const { Sider } = Layout

export function Modules() {
  const queryParam = useQueryParam()
  const isAdmin = queryParam.get("isAdmin")
  const adminUrl = queryParam.get("adminUrl")
  const isFacilitate = queryParam.get("facilitate")
  const { t } = useTranslation()

  const params = useParams()
  const moduleQuery = useGetModule({
    enrolmentUid: params?.enrolmentUid,
    moduleUid: params?.moduleUid,
    filters: { isAdmin: isAdmin === "true", facilitate: isFacilitate === "true" },
  })
  const lessonQuery = useGetLesson({
    enrolmentUid: params?.enrolmentUid,
    lessonUid: params?.lessonUid,
    filters: { isAdmin: isAdmin === "true", facilitate: isFacilitate === "true" },
  })

  const enrolmentQuery = useGetEnrolment({
    uid: params?.enrolmentUid,
    filters: {
      isAdmin: isAdmin === "true",
    },
  })

  const { width, height } = useWindowDimensions()

  const { state, dispatch } = useLesson()
  const [showCertificate, setShowCertificate] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [siderVisible, setSiderVisible] = useState(true)

  useEffect(() => {
    if (!enrolmentQuery?.isLoading && enrolmentQuery?.data) {
      dispatch({
        type: "field",
        field: "isFacilitated",
        payload: enrolmentQuery?.data?.publication?.isFacilitated,
      })
    }
  }, [enrolmentQuery?.isLoading, enrolmentQuery?.data])

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [])

  const isPreviewing = useMemo(() => {
    if (isAdmin === "true") return true
    const { activity } = enrolmentQuery?.data || { activity: [] }
    const lessonUid = lessonQuery?.data?.current?.uid || ""

    return (
      activity.some(({ typeUid, status }) => typeUid === lessonUid && status === "completed") ||
      enrolmentQuery?.data?.currentState === "completed"
    )
  }, [enrolmentQuery, lessonQuery])

  useEffect(() => {
    dispatch({ type: "field", field: "actionRunning", payload: false })
  }, [params.lesson])

  useEffect(() => {
    if (
      !enrolmentQuery.isLoading &&
      enrolmentQuery.data &&
      enrolmentQuery?.currentState !== "completed" &&
      moduleQuery?.data &&
      !moduleQuery?.isLoading &&
      lessonQuery?.data &&
      !lessonQuery?.isLoading
    ) {
      let moduleCount = enrolmentQuery.data?.publication?.material?.length
      const completedModules =
        enrolmentQuery?.data?.activity?.filter(({ status, type }) => type === "module" && status === "completed")
          ?.length || 0

      if (params.enrolmentType !== "courses") {
        moduleCount = enrolmentQuery.data?.publication?.material?.reduce((acc, curr) => {
          return acc + curr?.modules?.length
        }, 0)
      }

      if (enrolmentQuery?.data?.currentState === "in-progress" && completedModules === moduleCount) {
        dispatch({
          type: "next",
          payload: {
            lessonUid: params?.lessonUid,
            enrolmentUid: params?.enrolmentUid,
            enrolmentType: params?.enrolmentType,
            currentModule: moduleQuery?.data,
            ...lessonQuery?.data?.next,
          },
        })
      }
    }
  }, [
    enrolmentQuery.isLoading,
    moduleQuery?.data,
    moduleQuery.isLoading,
    enrolmentQuery.data,
    lessonQuery?.data,
    lessonQuery?.isLoading,
  ])

  return (
    <Layout style={{ minHeight: "100vh", background: "#f8f8f881" }}>
      {!lessonQuery.isLoading && !moduleQuery?.isLoading && !enrolmentQuery?.isLoading ? (
        <>
          <button onClick={() => setDrawer(true)} className="floatingBack floatingBack--dark" to="/">
            Menu
          </button>
          {!moduleQuery.isError && !lessonQuery?.isError && !enrolmentQuery?.isError ? (
            <>
              {(state?.moduleOver || state?.learningOver) && <Confetti width={width} height={height} />}
              <Modal
                visible={state?.moduleOver}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                className="modal"
                onCancel={() => {
                  dispatch({
                    type: "field",
                    field: "moduleOver",
                    payload: false,
                  })
                  dispatch({ type: "field", field: "badge", payload: null })
                }}
              >
                <PageHeader
                  className="site-page-header"
                  onBack={() =>
                    dispatch({
                      type: "field",
                      field: "moduleOver",
                      payload: false,
                    })
                  }
                  title={
                    <>
                      <Typography.Title level={3} style={{ margin: 0, padding: 0 }}>
                        {t("wellDone")} {enrolmentQuery?.data?.person?.firstName}
                      </Typography.Title>
                      <p style={{ lineHeight: "12px" }} className="badge--meta">
                        {t("youEarnedABadge")}
                      </p>
                    </>
                  }
                />
                <Card className="badge__container">
                  <p className="badge--meta">{state?.badge?.name}</p>
                  <p className="badge--meta">{state?.badge?.description}</p>
                  <img className="learningBadge" src={state?.badge?.imageUrl} />
                </Card>
              </Modal>
              <Modal
                visible={state?.learningOver && !state?.actionRunning}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                className="modal"
                onCancel={() => {
                  dispatch({
                    type: "field",
                    field: "learningOver",
                    payload: false,
                  })
                }}
              >
                {state?.currentState === "completed" ? (
                  <div className="modal__body">
                    <Typography.Title level={3}>{t("Congratulations")}!</Typography.Title>
                    {/* <Typography.Title level={3}>Congratulations, {lessonQuery?.data?.firstName}!</Typography.Title> */}
                    <Typography.Text>
                      {params?.enrolmentType === "courses" ? t("thisCourseEarnedACertificate") : t("thisProgramEarned")}
                      {/* {t("This")} {params?.enrolmentType.slice(0, -1)} earned you a certificate.{" "} */}
                      <Typography.Link onClick={() => setShowCertificate(true)}>
                        {t("clickHereToViewIt")}
                      </Typography.Link>
                    </Typography.Text>
                    {enrolmentQuery.data?.publication?.hasReward ? (
                      <>
                        <br />
                        <br />
                        <Typography.Text>{t("youEarnedAReward")}</Typography.Text>
                      </>
                    ) : null}
                    {/* <CertificateDrawer
                      visible={showCertificate}
                      onClose={() => setShowCertificate(false)}
                      enrolment={enrolmentQuery?.data}
                    /> */}
                    <CertificateDrawerWithFetch
                      enrolmentUid={params?.enrolmentUid}
                      visible={showCertificate}
                      onClose={() => setShowCertificate(false)}
                      enrolment={enrolmentQuery?.data}
                    />

                    <Divider />
                    <Row gutter="16">
                      <Col xs={24} lg={12}>
                        <Link
                          to="/"
                          onClick={() => {
                            dispatch({
                              type: "field",
                              field: "learningOver",
                              payload: false,
                            })
                          }}
                        >
                          <Card
                            className="lesson__button lesson__button--prev"
                            bordered
                            hoverable
                            style={{ marginBottom: "1rem" }}
                          >
                            <Space size={16}>
                              <Button shape="circle" className="icon" icon={<HomeOutlined />} />
                              <Space size={0} direction="vertical" align="start">
                                <Typography.Text className="title" strong>
                                  {t("returnHome")}
                                </Typography.Text>
                                <Typography.Text className="description">{t("backToMyLearning")}</Typography.Text>
                              </Space>
                            </Space>
                          </Card>
                        </Link>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Link
                          onClick={() => {
                            dispatch({
                              type: "field",
                              field: "learningOver",
                              payload: false,
                            })
                          }}
                          to={`/${params?.enrolmentType}/${params?.enrolmentUid}`}
                        >
                          <Card
                            className="lesson__button lesson__button--next"
                            bordered
                            hoverable
                            style={{ marginBottom: "1rem" }}
                          >
                            <Space size={16}>
                              <Button
                                loading={state?.actionRunning}
                                type="primary"
                                shape="circle"
                                className="icon"
                                icon={<BookOutlined />}
                              />
                              <Space size={0} direction="vertical" align="start">
                                <Typography.Text className="title" strong>
                                  {params.enrolmentType === "courses" ? t("backToCourse") : t("backToProgram")}
                                  {/* {`Return to ${params?.enrolmentType.slice(0, -1)} overview`} */}
                                </Typography.Text>
                                <Typography.Text className="description">
                                  {enrolmentQuery?.data?.publication?.translations["1"]?.title}
                                </Typography.Text>
                              </Space>
                            </Space>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="modal__body">
                    <Typography.Title level={3}>
                      You have reached the end of your{" "}
                      {isAdmin === "true" || enrolmentQuery?.data?.currentState === "completed"
                        ? "preview "
                        : "learning "}
                      {enrolmentQuery?.data?.person?.firstName}!
                    </Typography.Title>

                    {isAdmin === "true" || enrolmentQuery?.data?.currentState === "completed" ? (
                      <Typography.Text>
                        {isAdmin === "true" ? (
                          ""
                        ) : (
                          <>
                            {t("thisProgramEarned")}.{" "}
                            <Typography.Link onClick={() => setShowCertificate(true)}>
                              {t("clickHereToViewIt")}
                            </Typography.Link>
                            {enrolmentQuery.data?.publication?.hasReward ? (
                              <>
                                <br />
                                <br />
                                <Typography.Text>{t("youEarnedAReward")}</Typography.Text>
                              </>
                            ) : null}
                          </>
                        )}
                      </Typography.Text>
                    ) : (
                      <Typography.Text>{t("missedModules")}</Typography.Text>
                    )}

                    <CertificateDrawer
                      visible={showCertificate}
                      onClose={() => setShowCertificate(false)}
                      enrolment={enrolmentQuery?.data}
                    />
                    <Divider />
                    <Row gutter="16">
                      <Col xs={24} lg={12}>
                        <Link to="/">
                          <Card
                            className="lesson__button lesson__button--prev"
                            bordered
                            hoverable
                            style={{ marginBottom: "1rem" }}
                          >
                            <Space size={16}>
                              <Button shape="circle" className="icon" icon={<HomeOutlined />} />
                              <Space size={0} direction="vertical" align="start">
                                <Typography.Text className="title" strong>
                                  {t("returnHome")}
                                </Typography.Text>
                                <Typography.Text className="description">{t("backToMyLearning")}</Typography.Text>
                              </Space>
                            </Space>
                          </Card>
                        </Link>
                      </Col>
                      <Col xs={24} lg={12}>
                        {adminUrl ? (
                          <a href={adminUrl}>
                            <Card
                              className="lesson__button lesson__button--next"
                              bordered
                              hoverable
                              style={{ marginBottom: "1rem" }}
                            >
                              <Space size={16}>
                                <Button
                                  loading={state?.actionRunning}
                                  type="primary"
                                  shape="circle"
                                  className="icon"
                                  icon={<BookOutlined />}
                                />
                                <Space size={0} direction="vertical" align="start">
                                  <Typography.Text className="title" strong>
                                    Admin
                                  </Typography.Text>
                                  <Typography.Text className="description">Continue editing content</Typography.Text>
                                </Space>
                              </Space>
                            </Card>
                          </a>
                        ) : (
                          <a href={`/${params?.enrolmentType}/${params?.enrolmentUid}`}>
                            <Card
                              className="lesson__button lesson__button--next"
                              bordered
                              hoverable
                              style={{ marginBottom: "1rem" }}
                            >
                              <Space size={16}>
                                <Button
                                  loading={state?.actionRunning}
                                  type="primary"
                                  shape="circle"
                                  className="icon"
                                  icon={<BookOutlined />}
                                />
                                <Space size={0} direction="vertical" align="start">
                                  <Typography.Text className="title" strong>
                                    {params.enrolmentType === "courses" ? t("backToCourse") : t("backToProgram")}
                                    {/* {`Return to ${params?.enrolmentType.slice(0, -1)} overview`} */}
                                  </Typography.Text>
                                  <Typography.Text className="description">
                                    {enrolmentQuery?.data?.publication?.translations["1"]?.title}
                                  </Typography.Text>
                                </Space>
                              </Space>
                            </Card>
                          </a>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </Modal>
              <Drawer placement="left" visible={drawer} onClose={() => setDrawer(false)} closeIcon={null}>
                <img
                  style={{
                    textAlign: "center",
                    margin: "17.5px 0 1.25rem 17.5px",
                    borderRadius: "0.625rem",
                    objectFit: "contain",
                  }}
                  width={45}
                  height={45}
                  src={enrolmentQuery?.data?.person?.imageUrl || "/iwinlogo.png"}
                  alt="iwin logo"
                />
                <Divider />
                <Card className="show--onMobile" style={{ margin: "0 1rem 1rem 1rem" }}>
                  <p className="badge--meta">{lessonQuery?.data?.current?.kind}</p>
                  <Typography.Title level={3} style={{ margin: 0 }}>
                    {lessonQuery?.data?.current?.title}
                  </Typography.Title>
                </Card>
                <div className="sidebar__actions">
                  {isAdmin !== "true" ? (
                    <>
                      <Link to={`/${params?.enrolmentType}/${params?.enrolmentUid}`}>
                        <Button block type="primary" icon={<ProjectOutlined />}>
                          {params.enrolmentType === "courses" ? t("backToCourse") : t("backToProgram")}
                          {/* Back to {params?.enrolmentType.slice(0, -1)} */}
                        </Button>
                      </Link>

                      <Link to={`/${params?.enrolmentType}/${params?.enrolmentUid}/${params?.moduleUid}`}>
                        <Button block icon={<BarsOutlined />}>
                          {t("backToModule")}
                          {/* Back to module */}
                        </Button>
                      </Link>
                    </>
                  ) : null}

                  {isAdmin === "true" && adminUrl ? (
                    <a target="_blank" href={adminUrl}>
                      <Button type="link">{t("endPreview")}</Button>
                    </a>
                  ) : (
                    <Link to={"/"}>
                      <Button type="link">{t("endLearning")}</Button>
                    </Link>
                  )}

                  <Divider />
                </div>

                <Collapse className="lesson__items" defaultActiveKey={["1"]} ghost>
                  <Collapse.Panel key="1" header={t("Learning")}>
                    <Steps
                      progressDot
                      current={moduleQuery?.data?.learning?.findIndex((x) => x?.uid === params?.lessonUid)}
                      direction="vertical"
                    >
                      {moduleQuery?.data?.learning.map((learning, i) => {
                        const currModIdx = moduleQuery?.data?.learning?.findIndex((x) => x?.uid === params?.lessonUid)
                        return (
                          <Steps.Step
                            key={learning.uid}
                            title={
                              process.env.REACT_APP_ENV !== "production" || isPreviewing ? (
                                <Link
                                  to={`/enrolments/${params?.enrolmentUid}/modules/${params?.moduleUid}/learning/${learning?.uid}/${params?.enrolmentType}`}
                                  style={{
                                    textTransform: "capitalize",
                                    textDecoration: "underline",
                                  }}
                                  className="badge--meta"
                                >
                                  {learning.kind}
                                </Link>
                              ) : (
                                <>
                                  {i < currModIdx ? (
                                    <Link
                                      to={`/enrolments/${params?.enrolmentUid}/modules/${params?.moduleUid}/learning/${learning?.uid}/${params?.enrolmentType}`}
                                      style={{
                                        textTransform: "capitalize",
                                        textDecoration: "underline",
                                      }}
                                      className="badge--meta"
                                    >
                                      {learning.kind}
                                    </Link>
                                  ) : (
                                    <p style={{ textTransform: "capitalize" }} className="badge--meta">
                                      {learning.kind}
                                    </p>
                                  )}
                                </>
                              )
                            }
                            description={learning?.title}
                          />
                        )
                      })}
                    </Steps>
                  </Collapse.Panel>
                </Collapse>
              </Drawer>
              <Sider
                width={300}
                theme="light"
                collapsedWidth="0"
                collapsible
                collapsed={siderVisible}
                onCollapse={(collapsed, type) => {
                  setSiderVisible(collapsed)
                }}
                className="learning__sidebar"
              >
                <img
                  style={{
                    textAlign: "center",
                    margin: "17.5px 0 1.25rem 17.5px",
                    borderRadius: "0.625rem",
                    objectFit: "contain",
                  }}
                  width={45}
                  height={45}
                  src={enrolmentQuery?.data?.person?.imageUrl || "/iwinlogo.png"}
                  alt="iwin logo"
                />
                <Divider />
                <Card className="show--onMobile" style={{ margin: "0 1rem 1rem 1rem" }}>
                  <p className="badge--meta"> {lessonQuery?.data?.current?.kind}</p>
                  <Typography.Title level={3} style={{ margin: 0 }}>
                    {lessonQuery?.data?.current?.title}
                  </Typography.Title>
                </Card>
                <div className="sidebar__actions">
                  {isAdmin !== "true" ? (
                    <>
                      <Link to={`/${params?.enrolmentType}/${params?.enrolmentUid}`}>
                        <Button block type="primary" icon={<ProjectOutlined />}>
                          {params.enrolmentType === "courses" ? t("backToCourse") : t("backToProgram")}
                        </Button>
                      </Link>

                      <Link to={`/${params?.enrolmentType}/${params?.enrolmentUid}/${params?.moduleUid}`}>
                        <Button block icon={<BarsOutlined />}>
                          {t("backToModule")}
                        </Button>
                      </Link>
                    </>
                  ) : null}

                  {isAdmin === "true" && adminUrl ? (
                    <a target="_blank" href={adminUrl}>
                      <Button type="link">{t("endPreview")}</Button>
                      {/* <Button type="link">End Preview</Button> */}
                    </a>
                  ) : (
                    <Link to={"/"}>
                      <Button type="link">{t("endLearning")}</Button>
                    </Link>
                  )}

                  <Divider />
                </div>

                <Collapse className="lesson__items" defaultActiveKey={["1"]} ghost>
                  <Collapse.Panel key="1" header={t("Learning")}>
                    <Steps
                      progressDot
                      current={moduleQuery?.data?.learning?.findIndex((x) => x?.uid === params?.lessonUid)}
                      direction="vertical"
                    >
                      {moduleQuery?.data?.learning.map((learning, i) => {
                        const currModIdx = moduleQuery?.data?.learning?.findIndex((x) => x?.uid === params?.lessonUid)
                        return (
                          <Steps.Step
                            key={learning.uid}
                            title={
                              process.env.REACT_APP_ENV !== "production" || isPreviewing ? (
                                <Link
                                  to={`/enrolments/${params?.enrolmentUid}/modules/${params?.moduleUid}/learning/${learning?.uid}/${params?.enrolmentType}`}
                                  style={{
                                    textTransform: "capitalize",
                                    textDecoration: "underline",
                                  }}
                                  className="badge--meta"
                                >
                                  {learning.kind === "lesson" && t("Lesson")}
                                  {learning.kind === "assessment" && t("Assessment")}
                                  {learning.kind === "assignment" && t("Assignment")}
                                </Link>
                              ) : (
                                <>
                                  {i < currModIdx ? (
                                    <Link
                                      to={`/enrolments/${params?.enrolmentUid}/modules/${params?.moduleUid}/learning/${learning?.uid}/${params?.enrolmentType}`}
                                      style={{
                                        textTransform: "capitalize",
                                        textDecoration: "underline",
                                      }}
                                      className="badge--meta"
                                    >
                                      {learning.kind === "lesson" && t("Lesson")}
                                      {learning.kind === "assessment" && t("Assessment")}
                                      {learning.kind === "assignment" && t("Assignment")}
                                    </Link>
                                  ) : (
                                    <p style={{ textTransform: "capitalize" }} className="badge--meta">
                                      {learning.kind === "lesson" && t("Lesson")}
                                      {learning.kind === "assessment" && t("Assessment")}
                                      {learning.kind === "assignment" && t("Assignment")}
                                    </p>
                                  )}
                                </>
                              )
                            }
                            description={learning?.title}
                          />
                        )
                      })}
                    </Steps>
                  </Collapse.Panel>
                </Collapse>
              </Sider>
              <div className="learning__content">
                {(() => {
                  switch (lessonQuery?.data?.current?.kind) {
                    case "lesson":
                      return (
                        <Lessons enrolmentQuery={enrolmentQuery} lessonQuery={lessonQuery} moduleQuery={moduleQuery} />
                      )
                    case "assessment":
                      return (
                        <Assessments
                          enrolmentQuery={enrolmentQuery}
                          lessonQuery={lessonQuery}
                          moduleQuery={moduleQuery}
                        />
                      )
                    case "assignment":
                      return (
                        <Assignments
                          enrolmentQuery={enrolmentQuery}
                          lessonQuery={lessonQuery}
                          moduleQuery={moduleQuery}
                        />
                      )
                    default:
                      return (
                        <Result
                          status="404"
                          title="404"
                          subTitle="Sorry, this lesson does not have a current object."
                          extra={
                            <Link to={`/${enrolmentQuery?.data?.publication?.type}s/${params?.enrolmentUid}`}>
                              <Button icon={<ArrowLeftOutlined />} type="primary">
                                Click here to go back
                              </Button>
                            </Link>
                          }
                        />
                      )
                  }
                })()}
              </div>
            </>
          ) : (
            <Empty
              image="/empty.png"
              imageStyle={{
                height: 60,
              }}
              description={<span>Cannot find module</span>}
            >
              <Button href="/" type="primary">
                Back to {">"} My Learning
              </Button>
            </Empty>
          )}
        </>
      ) : (
        <Skeleton active />
      )}
    </Layout>
  )
}

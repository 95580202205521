import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useEnrolmentActivtyMutation } from "../../../../network/useEnrolmentActivity"
import { useGetPerson } from "../../../../network/usePerson"
import { useScormMessenger } from "../../../../hooks/useScormMessenger"
import { trackAction, useLesson } from "../../../../hooks/lesson-context"
import { useQueryClient } from "react-query"
import { Button, Col, Result, Row, Space, Typography, message } from "antd"
import { fetchActivity } from "../../../../network/useEnrolment"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import db from "../../../../firebase"
import ReactConfetti from "react-confetti"

export function Scorm({ setActions, materialAsJSON, lessonQuery, moduleQuery, activity, isPreviewing }) {
  const [autoMount, setAutoMount] = useState(() => {
    if (isPreviewing) {
      return false
    } else {
      return true
    }
  })

  const { enrolmentUid, lessonUid, enrolmentType } = useParams()
  const { dispatch } = useLesson()

  const personQuery = useGetPerson({ authenticated: true })
  const mutator = useEnrolmentActivtyMutation()
  const queryClient = useQueryClient()
  const { terminated, completedForPreview } = useScormMessenger(
    enrolmentUid,
    personQuery?.data,
    activity,
    materialAsJSON,
    lessonUid,
    isPreviewing
  )
  useEffect(() => {
    if (isPreviewing) {
      setAutoMount(false)
    }
  }, [lessonUid, materialAsJSON])
  useEffect(() => {
    if (terminated) {
      ;(async () => {
        if (!isPreviewing) {
          await trackAction({
            publicationEnrolmentUid: enrolmentUid,
            dispatch,
            mutator,
            payload: {
              typeUid: lessonUid,
              type: "scorm",
              status: "completed",
            },
          })
        }
        dispatch({
          type: "next",
          payload: {
            isPreviewing,
            queryClient,
            lessonUid,
            enrolmentUid,
            enrolmentType,
            currentModule: moduleQuery?.data,
            ...lessonQuery?.data?.next,
          },
        })
      })()
    }
  }, [terminated])

  const iframe = useMemo(() => {
    if (materialAsJSON) {
      return (
        <iframe
          id="scormPlayer"
          src={`${materialAsJSON.url}`}
          frameBorder="0"
          title={lessonQuery?.data?.current?.title}
          name="scormPlayer"
        />
      )
    }
  }, [materialAsJSON, lessonUid, enrolmentUid])

  return (
    <>
      {terminated && iframe ? null : (
        <>
          {autoMount ? (
            iframe
          ) : (
            <div
              style={{
                marginTop: "3rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1rem",
              }}
            >
              <ReactConfetti />
              <Typography.Title>Congrats! You've completed this lesson already</Typography.Title>
              <Typography.Text>You can do one of the following:</Typography.Text>
              <Space style={{ marginTop: "1rem" }}>
                <Button onClick={() => setAutoMount(true)}>Preview Scorm</Button>
                <Button
                  onClick={() => {
                    dispatch({
                      type: "next",
                      payload: {
                        isPreviewing,
                        queryClient,
                        lessonUid,
                        enrolmentUid,
                        enrolmentType,
                        currentModule: moduleQuery?.data,
                        ...lessonQuery?.data?.next,
                      },
                    })
                  }}
                  type="primary"
                >
                  Go Next
                </Button>
              </Space>
            </div>
          )}
        </>
      )}
    </>
  )
}

import { Form, Input, Typography } from "antd"
import { LockOutlined } from "@ant-design/icons"
import React from "react"
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input"
import en from "world_countries_lists/data/countries/en/world.json"

export function CustomEmailPassword({
  maxLength,
  type,
  title = "Register Now",
  description,
  prefix,
  suffix,
  addonAfter,
  addonBefore,
  emailPlaceholder,
  passwordPlaceholder = "Password...",
}) {
  return (
    <ConfigProvider locale={en}>
      <Typography.Title style={{ color: "white" }} level={3}>
        {title}
      </Typography.Title>
      {description && <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>{description}</Typography.Text>}
      <Form.Item
        name="identifier"
        rules={[{ required: true, message: "This field is required" }]}
        style={{ width: "100%" }}
        initialValue={{
          short: "za",
        }}
      >
        <CountryPhoneInput
          size="small"
          maxLength={maxLength}
          style={{ width: "100%" }}
          placeholder={emailPlaceholder}
        />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: "Please enter your password" }]}>
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          label="Password"
          placeholder={passwordPlaceholder}
        />
      </Form.Item>
    </ConfigProvider>
  )
}

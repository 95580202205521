import { Card, Col, Divider, Row, Skeleton, Statistic, Typography } from "antd"
import React, { useEffect } from "react"
import { TeamOutlined } from "@ant-design/icons"
import { useLocation } from "react-router-dom"
import { SmallCard } from "../../components/cards/SmallCard"
import { Container } from "../../components/Container"
import { useGetPerson } from "../../network/usePerson"
import { useGetTeams } from "../../network/useTeams"
import { useTranslation } from "react-i18next"

export function Teams() {
  const { t } = useTranslation()
  const personQuery = useGetPerson({ authenticated: true })
  const teamsQuery = useGetTeams()
  const { pathname } = useLocation()

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [pathname])
  return (
    <Container style={{ marginTop: "5.75rem" }}>
      {!personQuery.isLoading && personQuery.data && (
        <>
          <p className="badge--meta">
            {t("Hi")} {personQuery.data.firstName},
          </p>
          <Typography.Title level={3}>{t("yourTeams")}</Typography.Title>
        </>
      )}
      <Divider />
      <Row gutter={32}>
        {!teamsQuery.isLoading ? (
          <>
            {teamsQuery.data && !teamsQuery.isError && (
              <>
                <Col xs={24} lg={18}>
                  <Row gutter={32}>
                    {teamsQuery.data?.map((team) => (
                      <Col key={team.uid} xs={24} sm={12}>
                        <SmallCard
                          desc={team.description}
                          title={team.name}
                          url={team.featureImageUrl}
                          meta1={`${team.members?.length} ${t("members")}`}
                          href={`/teams/${team.uid}`}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col xs={24} lg={6} style={{ marginBottom: "7rem" }}>
                  <Card>
                    <Statistic
                      title={t("teams")}
                      value={teamsQuery.data?.length}
                      valueStyle={{ color: "#7fba7a" }}
                      prefix={<TeamOutlined />}
                    />
                  </Card>
                </Col>
              </>
            )}
          </>
        ) : (
          <Skeleton active />
        )}
      </Row>
    </Container>
  )
}

import { Avatar, Button, Drawer, Form, Input, Select, Space, Typography, message } from "antd"
import React, { useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useGetSingleTeam } from "../../network/useTeams"
import { tabFactory } from "../../helpers/tabsFactory"
import { addDoc, collection } from "firebase/firestore"
import db from "../../firebase"
import { useGetPerson } from "../../network/usePerson"

const { Option } = Select

export const TeamCommunication = () => {
  const personQuery = useGetPerson({ authenticated: true })

  const [visible, setVisible] = useState(true)

  const [form] = Form.useForm()

  const { replace } = useHistory()
  const { teamUid } = useParams()
  const personUids = tabFactory("personUid").get()?.split(",")

  const teamQuery = useGetSingleTeam({ teamUid })
  const defaultPeople = useMemo(() => {
    if (teamQuery?.data && !teamQuery.isLoading) {
      return teamQuery?.data?.members?.filter((x) => {
        return personUids?.find((y) => {
          return y === x?.member?.uid
        })
      })
    }
    return []
  }, [teamQuery?.data, teamQuery.isLoading, personUids])

  console.log("defaultPeople::: ", defaultPeople)

  const handleClose = () => {
    setVisible(false)
    setTimeout(() => replace(`/teams/${teamUid}`), 500)
  }

  return (
    <Drawer
      onClose={handleClose}
      width={window.innerWidth > 550 ? 550 : window.innerWidth - 20}
      title="Send Communication"
      placement="right"
      visible={visible}
    >
      <Form
        onFinish={async (values) => {
          try {
            await addDoc(collection(db, "notifications"), {
              message: {
                subject: values?.subject,
                text: values?.body,
              },
              to: values.recipients,
              from: `${personQuery?.data?.firstName} ${personQuery?.data?.lastName} <noreply@winwinza.com>`,
            })
            message.success("Email sent!")
            handleClose()
            form.resetFields()
          } catch (error) {
            message.error(error?.message)
          }
        }}
        initialValues={{
          recipients: defaultPeople ? defaultPeople?.map((x) => x?.member?.email) : [],
        }}
        name="comms"
        form={form}
        layout="vertical"
      >
        <Form.Item rules={[{ required: true, message: "This field is required" }]} name="recipients" label="Recipients">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select recipients"
            optionLabelProp="label"
            loading={teamQuery.isLoading}
          >
            {!teamQuery.isLoading &&
              teamQuery?.data &&
              teamQuery?.data?.members?.map((member) => (
                <Option key={member?.uid} value={member?.member?.email} label={member?.member?.person?.firstName}>
                  <Space>
                    {member?.member?.person?.imageUrl ? (
                      <Avatar src={member?.member?.person?.imageUrl} />
                    ) : (
                      <Avatar style={{ backgroundColor: "#f56a00" }}>{member?.member?.person?.firstName?.[0]}</Avatar>
                    )}
                    <Typography>
                      {member?.member?.person?.firstName} {member?.member?.person?.lastName}
                    </Typography>
                  </Space>
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item rules={[{ required: true, message: "This field is required" }]} name="subject" label="Subject">
          <Input placeholder="Email Subject" />
        </Form.Item>
        <Form.Item rules={[{ required: true, message: "This field is required" }]} name="body" label="Body">
          <Input.TextArea rows={4} placeholder="Body" />
        </Form.Item>
        <Button htmlType="submit" block type="primary">
          Send
        </Button>
      </Form>
    </Drawer>
  )
}

import { Avatar, Menu, Space, Typography } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import { UserOutlined } from "@ant-design/icons"
import { useAuthSignOut } from "@react-query-firebase/auth"
import { auth } from "../firebase"
import { useTranslation } from "react-i18next"

export function NavDropdown() {
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const { t } = useTranslation()

  const signOut = async () => {
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.localStorage.setItem("returnUrl", window.location.pathname)
    await signOutMutation()
    // queryClient.invalidateQueries()
  }

  const items = [
    {
      label: (
        <Link to="/profile">
          <Space size={16} style={{ padding: "0.225rem" }}>
            <Avatar
              className="menu__dropDown"
              icon={<UserOutlined />}
              style={{ height: "1.75rem", width: "1.75rem" }}
            />
            <Typography.Text style={{ color: "#808291" }}>{t("Profile")}</Typography.Text>
          </Space>
        </Link>
      ),
      key: "profile",
      style: {
        marginBottom: ".125rem",
      },
    },
    {
      label: (
        <Space size={16} style={{ padding: "0.225rem" }}>
          <Avatar className="menu__dropDown" icon={<UserOutlined />} style={{ height: "1.75rem", width: "1.75rem" }} />
          <Typography.Text style={{ color: "#808291" }}>{t("Signout")}</Typography.Text>
        </Space>
      ),
      key: "signout",
      onClick: () => signOut(),
      style: {
        marginBottom: ".125rem",
      },
    },
  ]

  return <Menu items={items} />
}

import { ConfigProvider, message } from "antd"
import axios from "axios"
import { useQuery } from "react-query"

export const useGetTenant = ({ tenantId }) => {
  const IWIN_PRIMARY_COLOR = "#294F6D"
  const headers = {}
  if (tenantId) headers["X-Tenant-Id"] = tenantId

  return useQuery(
    ["tenant", tenantId],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/companies/config/auth`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200 && res.data.data) {
            ConfigProvider.config({
              theme: {
                primaryColor:
                  res.data.data?.primaryColour || IWIN_PRIMARY_COLOR,
              },
            })
            return res.data.data
          }
          return null
        }),
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      onError: (error) => {
        message.error(
          `Failed to fetch tenant: ${
            error?.response?.data?.message || error?.message
          }`
        )
      },
    }
  )
}

export const useGetQueryParam = (key) => {
  if (!key) return new Error("Key prop must be provided")
  return {
    get: () => {
      const value = new URLSearchParams(window.location.search).get(key)

      return value?.split(",")
    },
    set: (value) => {
      if (Array.isArray(value)) value?.toString()
      const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${key}=${value}`
      window.history.pushState({ path: url }, "", url)
    },
  }
}

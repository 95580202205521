import dayjs from "dayjs"
import { useEffect, useContext, useReducer, createContext } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { useEnrolmentActivtyMutation } from "../network/useEnrolmentActivity"
import { useQueryParam } from "./useQueryParam"

const INITIAL_LESSON_STATE = {
  visible: null,
  lessonOver: false,
  moduleOver: false,
  sectionOver: false,
  learningOver: false,
  actionRunning: false,
  initialized: false,
  showCertificate: false,
  enrolmentUid: null,
  nextUrl: null,
  badge: null,
  isFacilitated: false,
  currentState: null,
}

const LessonContext = createContext()

function lessonReducer(state, action) {
  const { type, payload, field } = action
  switch (type) {
    case "next": {
      console.log("next payload::: ", payload)
      // payload is next object from lesson response
      function handleNext({
        lessonUid,
        moduleUid,
        enrolmentUid,
        enrolmentType,
        currentModule,
        isPreviewing,
        nextUrlByPathname,
      }) {
        if (!lessonUid && !moduleUid) {
          const obj = {
            ...state,
            learningOver: true,
            enrolmentUid,
          }

          if (!isPreviewing) {
            obj.actionToTrack = {
              typeUid: currentModule?.uid,
              type: "module",
              status: "completed",
              context: {
                completedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
                badgeUid: currentModule?.badge?.uid,
              },
            }
          }
          return obj
        }
        const returnObj = {
          ...state,
          enrolmentUid,
          moduleOver: currentModule?.badge && currentModule?.uid !== moduleUid,
          badge:
            currentModule?.badge && !isPreviewing
              ? {
                  imageUrl: currentModule?.badge?.imageUrl,
                  name: currentModule?.badge?.name,
                  description: currentModule?.badge?.description,
                }
              : null,
          nextUrl: `${
            nextUrlByPathname ? "$" : ""
          }/enrolments/${enrolmentUid}/modules/${moduleUid}/learning/${lessonUid}/${enrolmentType}`,
        }
        if (!isPreviewing && moduleUid !== currentModule?.uid) {
          returnObj.actionToTrack = {
            typeUid: currentModule?.uid,
            type: "module",
            status: "completed",
            context: {
              completedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
              badgeUid: currentModule?.badge?.uid,
            },
          }
        }
        return returnObj
      }
      return handleNext(payload)
    }
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

async function trackAction({ payload, publicationEnrolmentUid, dispatch, mutator, queryClient }) {
  const classroom = window.localStorage.getItem("classroom")
  const classroomId = window.localStorage.getItem("classroomId")
  const classroomEnrolmentUid = window.localStorage.getItem("classroomEnrolmentUid")
  const classroomPersonUid = window.localStorage.getItem("classroomPersonUid")

  if (!payload || !publicationEnrolmentUid || !dispatch || !mutator) {
    throw new Error("Please ensure payload, mutatorFn, state and dispatch are passed")
  }
  try {
    dispatch({ type: "field", field: "actionRunning", payload: true })
    const trackedAction = await mutator?.mutateAsync({
      publicationEnrolmentUid,
      data:
        classroom && classroomId && classroomEnrolmentUid && classroomPersonUid
          ? Array.isArray(payload)
            ? payload.map((x) => ({ ...x, personUid: classroomPersonUid, classroom: true }))
            : { ...payload, personUid: classroomPersonUid, classroom: true }
          : payload,
    })
    queryClient.setQueryData([
      publicationEnrolmentUid,
      (old) => (old ? { ...old, ...trackedAction?.data?.enrolment } : ""),
    ])
    if (trackedAction?.data?.enrolment?.currentState) {
      dispatch({
        type: "field",
        field: "currentState",
        payload: trackedAction?.data?.enrolment?.currentState,
      })
    }
    if (trackedAction?.data?.enrolment?.currentState === "completed") {
      dispatch({ type: "field", field: "actionToTrack", payload: null })
      dispatch({ type: "field", field: "learningOver", payload: true })
    }
    dispatch({ type: "field", field: "actionRunning", payload: false })
  } catch (error) {
    dispatch({ type: "field", field: "actionRunning", payload: false })
  } finally {
    dispatch({ type: "field", field: "actionRunning", payload: false })
  }
}

function LessonProvider({ children }) {
  const [state, dispatch] = useReducer(lessonReducer, INITIAL_LESSON_STATE)
  const mutator = useEnrolmentActivtyMutation()
  const queryClient = useQueryClient()
  const queryParam = useQueryParam()
  const isAdmin = queryParam.get("isAdmin")
  const isFacilitate = queryParam.get("facilitate")
  const adminUrl = queryParam.get("adminUrl")

  const value = { state, dispatch, queryClient }
  const { push } = useHistory()

  useEffect(() => {
    async function track() {
      await trackAction({
        payload: state?.actionToTrack,
        publicationEnrolmentUid: state?.actionToTrack?.publicationEnrolmentUid ?? state?.enrolmentUid,
        dispatch,
        mutator,
        queryClient,
      })
      dispatch({ type: "field", field: "actionToTrack", payload: null })
    }
    if (state?.actionToTrack) {
      track()
    }
  }, [state?.actionToTrack, state?.learningOver])

  useEffect(() => {
    if (state?.nextUrl && !state?.moduleOver) {
      // NOTE: This was a request by Stu. But is now commented out
      // if (state?.isFacilitated) {
      //   queryClient.invalidateQueries()
      //   window.location?.pathname?.split("/")[7]
      //     ? (window.location.pathname = `/${window.location?.pathname?.split("/")[7]}/${state?.enrolmentUid}`)
      //     : push("?")
      // } else {
      //   push(`${state?.nextUrl}${isAdmin === "true" ? `?isAdmin=true${adminUrl ? `&adminUrl=${adminUrl}` : ""}` : ""}`)
      // }
      dispatch({ type: "field", field: "nextUrl", payload: null })

      let params = {}
      if (isAdmin === "true" || isAdmin) params.isAdmin = true
      if (isFacilitate === "true" || isFacilitate) params.facilitate = true
      if (typeof adminUrl === "string" || adminUrl) params.adminUrl = adminUrl

      const urlParams = new URLSearchParams(params).toString()

      if (state?.nextUrl?.includes("$") && !state?.actionRunning) {
        window.location.pathname = `${state?.nextUrl.split("$")[1]}${
          urlParams && urlParams.length ? `?${urlParams}` : ""
        }`
      } else if (!state?.nextUrl?.includes("$")) {
        push(`${state?.nextUrl}${urlParams && urlParams.length ? `?${urlParams}` : ""}`)
      }
    }
  }, [state?.nextUrl, state?.actionRunning, state?.moduleOver])

  return <LessonContext.Provider value={value}>{children}</LessonContext.Provider>
}

function useLesson(blocked) {
  if (blocked) {
    return null
  }
  const context = useContext(LessonContext)
  if (context === undefined) {
    throw new Error("useLesson must be used within a LessonProvider")
  }

  return context
}

export { LessonProvider, useLesson, trackAction }

import { Spin } from "antd"
import React from "react"
import { useGetCertificate } from "../network/useMedia"

export const CertificatePreviews = ({ enrolment }) => {
  const certificateQuery = useGetCertificate({ enrolmentUid: enrolment.uid })
  return (
    <>
      {certificateQuery.isLoading ? (
        <Spin />
      ) : (
        <embed
          title="Completion Certificate"
          style={{ width: "100%", height: "100dvh" }}
          src={"data:application/pdf;base64," + certificateQuery.data}
          alt="certificate base 64 url"
        />
      )}
    </>
  )
}

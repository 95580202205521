import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Result,
  Row,
  Skeleton,
  Typography,
} from "antd"
import React, { useEffect, useReducer, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { useAuthCreateUserWithEmailAndPassword, useAuthReauthenticateWithCredential } from "@react-query-firebase/auth"
import {
  EmailAuthProvider,
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
} from "firebase/auth"
import { CustomEmailPassword } from "../../components/auth/CustomEmailPassword"
import { useHandleTenantId } from "../../hooks/useHandleTenantId"
import { useQueryParam } from "../../hooks/useQueryParam"
import { auth } from "../../firebase"
import {
  useLinkWithEmailMutation,
  useLinkWithIdentificationNumber,
  useLinkWithMobileMutation,
} from "../../network/usePerson"
import { EmailPassword } from "../../components/auth/EmailPassword"
import { useLoggerMutation } from "../../network/useLogger"
import { CloseCircleOutlined } from "@ant-design/icons"
import { useQueryClient } from "react-query"

function registerReducer(state, action) {
  const { type, payload, field } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export function Register() {
  const queryParam = useQueryParam()
  const REGISTER_STATE = {
    tenantId: queryParam.get("tenantId"),
    type: null,
    loading: false,
    showMFA: false,
    verificationId: null,
    code: null,
    support: false,
    acceptTerms: false,
    verifyEmail: JSON.parse(window.localStorage.getItem("waitingForVerification")) ?? false,
    resendEmailLoading: false,
    checkingEmailVerifiedLoading: false,
  }

  const [state, dispatch] = useReducer(registerReducer, REGISTER_STATE)

  const DEFAULT_BG =
    "https://images.unsplash.com/photo-1612999805994-1eabaef7bb88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
  const { tenant, isLoading } = useHandleTenantId(state, dispatch)

  auth.tenantId = state.tenantId || process.env.REACT_APP_TENANT_ID
  const registerMutation = useAuthCreateUserWithEmailAndPassword(auth)
  const authorizationMutation = useLinkWithMobileMutation()
  const linkEmailMutation = useLinkWithEmailMutation()
  const linkIdentificationNumber = useLinkWithIdentificationNumber()
  const reAuthMutation = useAuthReauthenticateWithCredential()
  const logMutation = useLoggerMutation()
  const buttonRef = useRef(null)
  const history = useHistory()
  const queryClient = useQueryClient()

  const handleRegistration = async (payload) => {
    try {
      const res = await registerMutation.mutateAsync(payload)
      if (!res?.user?.uid) {
        throw new Error("Unable to register user")
      }

      return res
    } catch (error) {
      dispatch({ type: "field", field: "loading", payload: false })
      message.error("Account already linked. Please sign-in")
    }
  }

  const handleAuthorization = async (payload) => {
    try {
      await authorizationMutation.mutateAsync({ data: payload })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: "field", field: "loading", payload: false })
    }
  }

  const handleMFAEnrolment = async (mobile, credential) => {
    const recaptchaVerifier = new RecaptchaVerifier(buttonRef.current, { size: "invisible" }, auth)

    const multiFactorSession = await multiFactor(getAuth().currentUser).getSession()
    const phoneInfoOptions = {
      phoneNumber: mobile,
      session: multiFactorSession,
    }

    await reAuthMutation.mutateAsync({
      user: auth.currentUser,
      credential,
    })

    const verificationId = await new PhoneAuthProvider(auth).verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
    dispatch({
      type: "field",
      field: "verificationId",
      payload: verificationId,
    })
    dispatch({ type: "field", field: "showMFA", payload: true })
  }

  const verifyCode = async (code) => {
    const cred = PhoneAuthProvider.credential(state?.verificationId, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    await multiFactor(auth.currentUser).enroll(multiFactorAssertion)
  }

  const description = (
    <Link to={`/auth/sign-in?tenantId=${tenant?.tenantId}`}>
      <Typography.Link style={{ color: "white", textDecoration: "underline" }}>Sign In</Typography.Link>
    </Link>
  )

  useEffect(() => {
    if (!auth.currentUser && window.localStorage.getItem("waitingForVerification")) {
      window.localStorage.removeItem("waitingForVerification")
    }
  }, [auth.currentUser])

  useEffect(() => {
    queryClient.invalidateQueries()
  }, [])

  return (
    <div
      className="auth"
      style={{
        backgroundImage: `url(${tenant?.featureImageUrl || DEFAULT_BG})`,
      }}
    >
      <Modal
        visible={state?.support}
        cancelText="Cancel"
        onCancel={() => dispatch({ type: "field", field: "support", payload: false })}
        footer={null}
        width="55%"
        className="modal modal--noPadding"
      >
        <iframe
          title="support form"
          src={tenant?.supportUrl}
          width="100%"
          style={{
            border: 0,
            height: "82vh",
            boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)",
          }}
        />
      </Modal>
      <Modal
        visible={state.showMFA}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Form
          onFinish={async (values) => {
            await verifyCode(values.code)
            logMutation.mutate({
              data: {
                event: "register",
                context: {
                  type: state?.type,
                },
              },
            })
            history.push("/")
          }}
        >
          <PageHeader
            className="site-page-header"
            onBack={() => {
              dispatch({ type: "field", field: "showMFA", payload: false })
              dispatch({ type: "field", field: "loading", payload: false })
            }}
            title="Confirm your number"
          />
          <Form.Item name="code" label="Enter the code we sent over SMS">
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Enter Code
          </Button>
        </Form>
      </Modal>
      <Modal
        title="Verify Email"
        visible={state?.verifyEmail}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Result
          title="Verify Your Email Address"
          subTitle="We've just sent you a mail with a link to verify your email address."
          extra={[
            <Button
              loading={state?.checkingEmailVerifiedLoading}
              disabled={state?.checkingEmailVerifiedLoading}
              onClick={async () => {
                dispatch({ type: "field", field: "checkingEmailVerifiedLoading", payload: true })
                await linkEmailMutation.mutateAsync(
                  {
                    data: {
                      email: auth.currentUser.email,
                      authUid: auth.currentUser.uid,
                    },
                  },
                  {
                    onError: (error) => {
                      dispatch({ type: "field", field: "checkingEmailVerifiedLoading", payload: false })
                      dispatch({
                        type: "field",
                        field: "loading",
                        payload: false,
                      })
                    },
                    onSuccess: () => {
                      dispatch({ type: "field", field: "checkingEmailVerifiedLoading", payload: false })
                      logMutation.mutate({
                        data: {
                          event: "register",
                          context: {
                            type: state?.type,
                          },
                        },
                      })
                      window.localStorage.removeItem("waitingForVerification")
                      history.push("/")
                    },
                  }
                )
              }}
              type="primary"
              key="console"
            >
              I have verified!
            </Button>,
            <Button
              loading={state?.resendEmailLoading}
              disabled={state?.resendEmailLoading}
              onClick={async () => {
                dispatch({ type: "field", field: "resendEmailLoading", payload: true })
                await sendEmailVerification(auth.currentUser)
                  .then(() => {
                    message.success("Verification email sent")
                  })
                  .finally(() => {
                    dispatch({ type: "field", field: "resendEmailLoading", payload: false })
                  })
              }}
              key="buy"
            >
              Resend Email
            </Button>,
          ]}
        ></Result>
      </Modal>
      <Row>
        <Col xs={24} md={12} lg={10} xl={8} className="auth__pannel">
          <Form
            name="login"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={async (values) => {
              dispatch({ type: "field", field: "loading", payload: true })
              const payload = {
                email: values["email"] || values.identifier,
                password: values.password,
              }
              switch (state?.type) {
                case "mobile": {
                  payload.email = `+${values.identifier?.code}${values.identifier?.phone}${
                    tenant?.providerConfig?.[state?.type]?.suffix
                  }`
                  break
                }
                case "identificationNumber": {
                  payload.email = payload.email + tenant?.providerConfig?.[state?.type]?.suffix
                  break
                }
              }

              const authentication = await handleRegistration(payload)
              if (tenant?.linkRegistration) {
                if (state?.type === "mobile") {
                  await handleAuthorization({
                    mobile: `+${values.identifier?.code}${values.identifier?.phone}`,
                    authUid: authentication?.user?.uid,
                  })
                } else if (state?.type === "email") {
                  if (authentication.user.emailVerified || tenant.tenantId === "AB-InBev-79h0x") {
                    await linkEmailMutation.mutateAsync(
                      {
                        data: {
                          email: payload.email,
                          authUid: authentication?.user?.uid,
                        },
                      },
                      {
                        onError: (error) => {
                          console.log("errorr::: ", error)
                          dispatch({
                            type: "field",
                            field: "loading",
                            payload: false,
                          })
                        },
                      }
                    )
                    logMutation.mutate({
                      data: {
                        event: "register",
                        context: {
                          type: state?.type,
                        },
                      },
                    })
                    history.push("/")
                  } else {
                    await sendEmailVerification(auth.currentUser).then(() => {
                      message.success("Verification email sent")
                      window.localStorage.setItem("waitingForVerification", true)
                      dispatch({ type: "field", field: "verifyEmail", payload: true })
                    })
                  }
                } else if (state?.type === "identificationNumber") {
                  await linkIdentificationNumber.mutateAsync({
                    data: {
                      identificationNumber: values["email"],
                      authUid: authentication?.user?.uid,
                    },
                  })
                  logMutation.mutate({
                    data: {
                      event: "register",
                      context: {
                        type: state?.type,
                      },
                    },
                  })
                  history.push("/")
                }
              }
              if (tenant?.multiFactorEnabled) {
                const credential = EmailAuthProvider.credential(payload.email, payload.password)
                await handleMFAEnrolment(`+${values.identifier?.code}${values.identifier?.phone}`, credential)
              }
              dispatch({ type: "field", field: "loading", payload: false })
            }}
          >
            {!isLoading ? (
              <>
                {state?.type && tenant?.linkRegistration ? (
                  <>
                    {tenant?.imageUrl && <img src={tenant?.imageUrl} alt="company" className="companyLogo" />}
                    {(() => {
                      switch (state?.type) {
                        case "mobile":
                          return (
                            <CustomEmailPassword
                              description={<>Create an account with your mobile number. {description}</>}
                              type="number"
                              maxLength={9}
                              addonBefore="+27"
                              emailPlaceholder="Mobile number..."
                              passwordPlaceholder="Set new password..."
                            />
                          )
                        case "identificationNumber":
                          return (
                            <EmailPassword
                              emailPlaceholder="Identification number..."
                              emailError="please enter a valid identification number"
                              type={"text"}
                              title="Link Existing Account"
                              description={<>Create an account with your identification number {description}</>}
                            />
                          )
                        case "email":
                          return (
                            <EmailPassword
                              title="Link Existing Account"
                              description={<>Create an account with an email and password. {description}</>}
                            />
                          )
                        default:
                          return (
                            <EmailPassword
                              title="Link Existing Account"
                              description={<>Create an account with an email and password. {description}</>}
                            />
                          )
                      }
                    })()}

                    {tenant.tenantId.includes("Brew") && (
                      <Collapse ghost className="no-padding" style={{ marginBottom: "0.625rem" }}>
                        <Collapse.Panel header="Terms & Conditions" key={"1"}>
                          <>
                            <Alert
                              style={{ marginBottom: "1rem" }}
                              message="By supplying the information above, I give consent to receive information about the Brew Masters Academy and vouchers that I will receive via SMS or Email. This information will strictly be used for the Brew Masters Academy Platform and will not be shared with third parties."
                              type="info"
                              showIcon
                            />
                          </>
                        </Collapse.Panel>
                      </Collapse>
                    )}
                    {tenant.tenantId.includes("Brew") && (
                      <Checkbox
                        value={state?.acceptTerms}
                        style={{ marginBottom: "0.625rem" }}
                        onChange={() =>
                          dispatch({
                            type: "field",
                            field: "acceptTerms",
                            payload: !state?.acceptTerms,
                          })
                        }
                      >
                        Accept Terms
                      </Checkbox>
                    )}

                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={state?.loading}
                      block
                      style={{
                        color: "white",
                        textDecoration: "underline",
                        marginBottom: "1rem",
                      }}
                      id="recaptcha-container"
                      ref={buttonRef}
                      disabled={(tenant.tenantId.includes("Brew") && !state?.acceptTerms) || false}
                    >
                      Register
                    </Button>
                    {tenant?.linkRegistration &&
                      tenant?.linkRegistration?.providers
                        ?.filter((x) => x !== state?.type)
                        ?.map((provider) => (
                          <Button
                            disabled={state?.loading}
                            style={{ color: "white", textDecoration: "underline" }}
                            type="link"
                            block
                            onClick={() =>
                              dispatch({
                                type: "field",
                                field: "type",
                                payload: provider,
                              })
                            }
                            htmlType="button"
                            key={provider}
                          >
                            Link with{" "}
                            {provider
                              .split(/(?=[A-Z])/)
                              .map((s) => s.toLowerCase())
                              .join(" ")}
                          </Button>
                        ))}
                    {tenant?.supportUrl && (
                      <Button
                        key="password-reset"
                        onClick={() =>
                          dispatch({
                            type: "field",
                            field: "support",
                            payload: true,
                          })
                        }
                        style={{
                          color: "white",
                          textDecoration: "underline",
                        }}
                        type="link"
                      >
                        Trouble registering?
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Typography.Title style={{ color: "white" }} level={1}>
                      Link Existing Account
                    </Typography.Title>
                    <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>
                      Registration is not enabled for your tenant
                    </Typography.Text>
                    <Link to={`/auth/sign-in?tenantId=${tenant?.tenantId}`}>
                      <Button type="primary">Go to login page</Button>
                    </Link>
                  </>
                )}
              </>
            ) : (
              <Skeleton active paragraph="Loading company configurations" />
            )}
          </Form>
        </Col>
      </Row>
    </div>
  )
}

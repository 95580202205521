export const langsToTranslate = {
  myLearning: "My Learning",
  Teams: "Teams",
  teams: "teams",
  Explore: "Explore",
  explore: "explore",
  help: "help",
  Help: "Help",
  profile: "profile",
  Profile: "Profile",
  signout: "signout",
  Signout: "Signout",

  goToCourse: "Go to course",
  goToProgram: "Go to program",
  goToExternal: "Go to external",
  yourCourses: "Your Courses",
  yourPrograms: "Your Programs",

  search: "search",
  Search: "Search",
  selectTopic: "Select topic",

  quickLinks: "Quick Links",

  programs: "programs",
  Programs: "Programs",
  program: "program",
  Program: "Program",
  courses: "courses",
  Courses: "Courses",
  showMore: "Show More",

  sortBy: "Sort by",
  none: "none",
  dueDate: "Due Date",
  enrollmentDate: "Enrollment Date",
  duration: "duration",
  Duration: "Duration",

  hi: "hi",
  Hi: "Hi",
  hey: "hey",
  Hey: "Hey",

  yourTeams: "Your Teams",
  members: "members",
  Members: "Members",
  member: "member",
  Member: "Member",

  forum: "forum",
  Forum: "Forum",
  letsMakeANewPost: "Let's make a new post",
  letYourTeamKnowWhatsOnYourMind: "Let your team know what's on your mind",
  teamUploadTitle: "Click or drag files to this area to upload",
  teamUploadDescription:
    "Support for Single or Bulk Upload. Strictly Prohibited: Uploading Company Data or Other Banned Files.",
  post: "post",
  Post: "Post",
  posts: "posts",
  Posts: "Posts",
  comments: "comments",
  Comments: "Comments",
  likes: "likes",
  Likes: "Likes",
  deletePost: "Delete Post",

  enrolments: "enrolments",
  Enrolments: "Enrolments",
  enrolment: "enrolment",
  Enrolment: "Enrolment",

  searchByEnrolmentTitle: "Search by enrolment title...",
  all: "all",
  All: "All",

  name: "name",
  Name: "Name",
  type: "type",
  Type: "Type",
  createdAt: "Created At",
  person: "person",
  Person: "Person",
  startedAt: "Started At",
  status: "status",
  Status: "Status",
  validUntil: "Valid Until",
  completedAt: "Completed At",
  completed: "completed",
  Completed: "Completed",
  action: "action",
  Action: "Action",
  requestTraining: "Request Training",
  view: "view",
  View: "View",
  more: "more",
  More: "More",
  sendCommunication: "Send Communication",

  leaderboard: "Leaderboard",
  "1stPlace": "1st place",
  "2ndPlace": "2nd place",
  "3rdPlace": "3rd place",
  position: "position",
  Position: "Position",
  avgScore: "Avg. Score",
  noModulesCompleted: "No. of Modules Completed",

  people: "people",
  People: "People",
  communication: "communication",
  Communication: "Communication",
  details: "details",
  Details: "Details",
  online: "online",
  Online: "Online",

  activity: "activity",
  Activity: "Activity",

  modules: "modules",
  Modules: "Modules",
  module: "module",
  Module: "Module",

  assessment: "assessment",
  Assessment: "Assessment",
  assessments: "assessments",
  Assessments: "Assessments",

  assignments: "assignments",
  Assignments: "Assignments",
  assignment: "assignment",
  Assignment: "Assignment",

  bookings: "bookings",
  Bookings: "Bookings",
  booking: "booking",
  Booking: "Booking",

  score: "score",
  Score: "Score",
  scores: "scores",
  Scores: "Scores",

  submissions: "submissions",
  Submissions: "Submissions",
  submission: "submission",
  Submission: "Submission",

  failed: "failed",
  Dailed: "Dailed",

  actions: "actions",
  Actions: "Actions",

  grantAdditionalAttempt: "Grant additional attempt",
  dateRange: "Date Range",
  training: "training",
  Training: "Training",

  reject: "reject",
  Reject: "Reject",

  approve: "approve",
  Approve: "Approve",
  approved: "approved",
  Approved: "Approved",

  declined: "declined",
  Declined: "Declined",
  decline: "decline",
  Decline: "Decline",

  pending: "pending",
  Pending: "Pending",

  requested: "requested",
  Requested: "Requested",
  request: "request",
  Request: "Request",

  selectInPersonTrainingCourse: "Select In-Person Training Course",
  selectADate: "Select a date",
  createTrainingRequest: "Create Training Request",

  learning: "learning",
  Learning: "Learning",

  recipients: "recipients",
  Recipients: "Recipients",
  recipient: "recipient",
  Recipient: "Recipient",

  subject: "subject",
  Subject: "Subject",

  body: "body",
  Body: "Body",

  send: "send",
  Send: "Send",

  edit: "edit",
  Edit: "Edit",
  dashboard: "dashboard",
  Dashboard: "Dashboard",
  trainingRequest: "Training Request",
  trainingRequests: "Training Requests",
  badges: "badges",
  Badges: "Badges",
  learningHistory: "Learning History",
  selectAFilter: "Select a filter",
  title: "title",
  Title: "Title",
  certificate: "certificate",
  Certificate: "Certificate",

  profileSettings: "Profile Settings",
  preferences: "preferences",
  Preferences: "Preferences",
  hobbiesAndInterests: "Hobbies & Interests",
  profilePhoto: "Profile Photo",
  profilePhotoCaption: "We recommend an image of at least",
  profilePhotoUpload: "Click to upload",
  accountInfo: "Account Info",
  profileBio: "About yourself in a few words",

  submit: "submit",
  Submit: "Submit",

  preferredLanguage: "Preferred Language",
  alertFrequency: "Alert Frequency",
  immediately: "immediately",
  Immediately: "Immediately",

  hourly: "hourly",
  Hourly: "Hourly",

  daily: "daily",
  Daily: "Daily",

  weekly: "weekly",
  Weekly: "Weekly",

  yourHobbies: "Your Hobbies",
  addHobby: "Add Hobby",
  yourInterests: "Your Interests",
  addInterest: "Add Interest",

  add: "add",
  Add: "Add",

  gladToSeeYou: "Glad to see you",
  pleaseSelectAChat: "Please select a chat",
  searchForAPerson: "Search for a person",

  channels: "channels",
  Channels: "Channels",

  chat: "chat",
  Chat: "Chat",
  chats: "chats",
  Chats: "Chats",

  yourMessage: "Your message...",
  createANewChat: "Create new chat",

  noDueDate: "No due date",

  card: "card",
  Card: "Card",
  cards: "cards",
  Cards: "Cards",

  page: "page",
  Page: "Page",
  pages: "pages",
  Pages: "Pages",

  scorm: "scorm",
  Scorm: "Scorm",
  scorms: "scorms",
  Scorms: "Scorms",

  lesson: "lesson",
  Lesson: "Lesson",
  lessons: "lessons",
  Lessons: "Lessons",

  course: "Course",
  resumeCourse: "Resume Course",
  resumeProgram: "Resume Program",
  resumeExternal: "Resume External Training",

  goToModule: "Go to module",

  section: "section",
  Section: "Section",
  sections: "sections",
  Sections: "Sections",

  rating: "rating",
  Rating: "Rating",

  question: "question",
  Question: "Question",
  questions: "questions",
  Questions: "Questions",

  submitAssessment: "Submit Assessment",

  youHaveFailedThisAssessmentLimit:
    "Unfortunately you have failed this assessment and have used all remaining attempts. Please contact your manager and click the button below when they have granted you another attempt.",
  assessmentFailed: "Unfortunately you have failed this assessment! On the bright side, you have",
  remainingAttempts: "Remaining Attempts",
  reloadAssessment: "Reload Assessment",

  passed: "passed",
  Passed: "Passed",

  tryAgain: "Try Again",

  answeredCorrectly: "Answered Correctly",
  result: "result",
  Result: "Result",

  min: "min",
  Min: "Min",
}

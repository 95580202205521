import React, { useMemo } from "react"
import { Button, Card, Tag, Typography } from "antd"
import { Link } from "react-router-dom"
import { useGetPerson } from "../../network/usePerson"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

export function ProgramCard({ href, title = "Title", desc, url, hasProgramCertificate, progress, enrolment, ...rest }) {
  const { t } = useTranslation()
  const personQuery = useGetPerson({ authenticated: true })
  const isNew = useMemo(() => {
    const difference = dayjs().diff(enrolment?.createdAt, "days")
    if (difference <= 12 && enrolment?.currentState === "not-started") {
      return true
    }

    return false
  }, [])
  return (
    <Card
      {...rest}
      className="program__card"
      style={{
        backgroundImage: url
          ? `url('${url}')`
          : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
      }}
    >
      {isNew ? (
        <Tag style={{ marginBottom: "0.625rem" }} color="green">
          New
        </Tag>
      ) : null}
      <Typography.Title
        className="title"
        level={4}
        ellipsis={{
          rows: 2,
          expandable: true,
          symbol: <span className="expand">more</span>,
        }}
      >
        {title}
      </Typography.Title>
      <Typography.Paragraph style={{ color: "white" }} ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
        {desc}
      </Typography.Paragraph>
      <Link to={href}>
        <Button type="primary">{t("goToProgram")}</Button>
      </Link>
      {progress && progress > 0 && progress < 100 ? (
        <div className="progress">
          <div className="percentage" style={{ width: `${progress}%` }} />
        </div>
      ) : null}
    </Card>
  )
}

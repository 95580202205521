import { BookOutlined, InfoCircleOutlined, MessageOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Badge, Card, Col, Divider, Input, Row, Space, Tag } from "antd"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import { doc, onSnapshot, setDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import db from "../firebase"
import { tabFactory } from "../helpers/tabsFactory"
import { useGetPerson } from "../network/usePerson"
import { TeamStatCards } from "./TeamStatCards"
import { useTranslation } from "react-i18next"
dayjs.extend(LocalizedFormat)

export const TeamPeoplePane = ({ team, isAdmin }) => {
  const [search, setSearch] = useState(tabFactory("pSearch").get() || "")
  const [onlineMembers, setOnlineMembers] = useState([])
  const [onlineTeamListExists, setOnlineTeamListExists] = useState(false)
  const { teamUid } = useParams()
  const personQuery = useGetPerson({ authenticated: true })
  const { t, i18n } = useTranslation()

  useEffect(() => {
    async function setOnline() {
      await setDoc(doc(db, "onlineTeams", teamUid), { online: [] })
    }

    const unsubscribe = onSnapshot(doc(db, "onlineTeams", teamUid), async (doc) => {
      if (doc.exists()) {
        setOnlineMembers(doc.data().online)
      } else {
        setOnline()
      }
      setOnlineTeamListExists(true)
    })
    return () => unsubscribe()
  }, [team.uid])

  useEffect(() => {
    const foundMyTeamMemberUid = team?.members?.find((x) => {
      return x?.member?.personUid === personQuery?.data?.uid
    })?.member?.uid

    const setMembers = async (arrayOfStrings) => {
      await setDoc(doc(db, "onlineTeams", teamUid), { online: arrayOfStrings })
    }
    if (onlineTeamListExists) {
      setMembers([
        ...onlineMembers?.filter((x) => x?.teamMemberUid !== foundMyTeamMemberUid),
        {
          teamMemberUid: foundMyTeamMemberUid,
          status: "online",
        },
      ])
    }
    return async () => {
      await setDoc(doc(db, "onlineTeams", teamUid), {
        online: [
          ...onlineMembers?.filter((x) => x?.teamMemberUid !== foundMyTeamMemberUid),
          {
            teamMemberUid: foundMyTeamMemberUid,
            status: "offline",
            lastSeen: dayjs().format(),
          },
        ],
      })
    }
  }, [onlineTeamListExists])
  console.log("teamOnline::: ", onlineMembers)
  return (
    <>
      <TeamStatCards team={team} />
      <div className="filter" onFinish={(values) => {}}>
        <Input
          className="filter__input filter__input--noMargin"
          bordered={false}
          placeholder={t("team.tabs.people.search") + "..."}
          prefix={<SearchOutlined />}
          onInput={(e) => {
            tabFactory("pSearch").onChange(e?.target?.value)
            setSearch(e?.target?.value)
          }}
          value={tabFactory("pSearch").get() || ""}
        />
      </div>
      <Divider />
      <Row gutter={[16, 16]} style={{ paddingBottom: "7rem" }}>
        {team?.members
          ?.filter((member) => {
            const fullName = `${member?.member?.person?.firstName} ${member?.member?.person?.lastName}`.toLowerCase()
            const searchValue = search.toLowerCase()

            if (search === "" || !search) return true
            else return fullName.includes(searchValue)
          })
          ?.map((member) => {
            return (
              <Col key={member?.uid} xs={24} md={12} xl={8}>
                <Card
                  actions={
                    isAdmin
                      ? [
                          <Link to={`/teams/${teamUid}/communication?personUid=${member?.member?.uid}`}>
                            <Space direction="vertical">
                              <MessageOutlined />
                              <span>{t("team.tabs.people.communication")}</span>
                            </Space>
                          </Link>,
                          <Link to={`/teams/${teamUid}/booking?personUid=${member?.member?.person?.uid}`}>
                            <Space direction="vertical">
                              <BookOutlined />
                              <span>{t("team.tabs.people.training")}</span>
                            </Space>
                          </Link>,
                          <Link to={`/teams/${teamUid}/people/${member?.member?.person?.uid}`}>
                            <Space direction="vertical">
                              <InfoCircleOutlined />
                              <span>{t("team.tabs.people.details")}</span>
                            </Space>
                          </Link>,
                        ]
                      : [
                          <Link to={`/teams/${teamUid}/people/${member?.member?.person?.uid}`}>
                            <Space direction="vertical">
                              <InfoCircleOutlined />
                              <span>{t("team.tabs.people.details")}</span>
                            </Space>
                          </Link>,
                        ]
                  }
                >
                  <Card.Meta
                    avatar={
                      member?.member?.person?.imageUrl ? (
                        <Avatar src={member?.member?.person?.imageUrl} />
                      ) : (
                        <Avatar icon={<UserOutlined />} />
                      )
                    }
                    title={`${member?.member?.person?.firstName} ${member?.member?.person?.lastName}`}
                    description={
                      <Space>
                        <Tag color="green">
                          {member?.member?.person?.enrolments?.length}

                          {i18n.language === "en" ? (
                            <>Enrolment {member?.member?.person?.enrolments?.length === 1 ? "" : "s"}</>
                          ) : (
                            <> {t("team.tabs.people.enrolments")}</>
                          )}
                        </Tag>
                        {onlineMembers?.find((x) => x?.teamMemberUid === member?.member?.uid) &&
                          onlineMembers?.find((x) => x?.teamMemberUid === member?.member?.uid)?.status === "online" && (
                            <Badge color="green" text={t("team.tabs.people.online")}></Badge>
                          )}
                        {onlineMembers?.find((x) => x?.teamMemberUid === member?.member?.uid) &&
                          onlineMembers?.find((x) => x?.teamMemberUid === member?.member?.uid)?.status ===
                            "offline" && (
                            <Badge
                              color="gray"
                              text={dayjs(
                                onlineMembers?.find((x) => x?.teamMemberUid === member?.member?.uid)?.lastSeen
                              ).fromNow()}
                            ></Badge>
                          )}
                      </Space>
                    }
                  />
                </Card>
              </Col>
            )
          })}
      </Row>
    </>
  )
}

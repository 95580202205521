import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Empty,
  Image,
  Input,
  List,
  Menu,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Statistic,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd"
import React, { useEffect, useReducer, useState } from "react"
import {
  GlobalOutlined,
  SearchOutlined,
  DeploymentUnitOutlined,
  PhoneOutlined,
  BookOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons"
import { Link } from "react-router-dom"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import dayjs from "dayjs"
import { Container } from "../../components/Container"
import { useGetPerson, useGetPersonCounts } from "../../network/usePerson"
import { useGetEnrolments } from "../../network/useEnrolments"
import { tabFactory } from "../../helpers/tabsFactory"
import { profileReducer } from "../../reducers/profileReducer"
import { seritiProfileTables, tables } from "../../constants/tables"
import db, { auth } from "../../firebase"
import { TagFactory } from "../../components/TagFactory"
import { stripHtml } from "../../helpers/stripHtml"
import { generateKey } from "../../hooks/generateKey"
import { useIsThungela } from "../../hooks/useIsThungela"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { SmallCard } from "../../components/cards/SmallCard"
import { useQueryClient } from "react-query"
import { fetchEnrolment } from "../../network/useEnrolment"
import { ConditionalLink } from "../../components/ConditionalLink"
import { useAuthSignOut } from "@react-query-firebase/auth"
import { HelpDrawer } from "../../components/HelpDrawer"
import { useTranslation } from "react-i18next"

const { TabPane } = Tabs

export function Profile() {
  const [state, dispatch] = useReducer(profileReducer, {
    initialized: false,
    tableData: [],
    sort: null,
    filters: {
      include: "completed",
      contentType: "digital,physical,mixed",
      offset: 0,
      limit: 10,
    },
  })
  const [training, setTraining] = useState([])
  const [loading, setLoading] = useState(false)

  const [helpOpen, setHelpOPen] = useState(false)
  const [drawerFormType, setDrawerFormType] = useState(null)

  const personQuery = useGetPerson({ authenticated: true })
  const isThungela = useIsThungela(personQuery?.data?.company?.tenantId)

  const { width } = useWindowDimensions()
  const queryClient = useQueryClient()
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const { t } = useTranslation()

  const enrolmentsQuery = useGetEnrolments(state?.filters)
  const coursesCount = useGetPersonCounts({ personUid: personQuery?.data?.uid, key: "course" })
  const programCount = useGetPersonCounts({ personUid: personQuery?.data?.uid, key: "program" })
  useEffect(() => {
    if (personQuery?.data?.uid) {
      setLoading(true)
      const unsub = onSnapshot(
        query(collection(db, "trainingRequests"), where("assignedToUid", "==", personQuery?.data?.uid)),
        (snap) => {
          const arr = []
          snap.forEach((doc) => {
            arr.push({ ...doc.data(), id: doc.id })
          })
          setTraining(arr)
          setLoading(false)
        }
      )
      return () => unsub()
    }
  }, [personQuery.data])

  useEffect(() => {
    if (!enrolmentsQuery.isLoading && enrolmentsQuery.data) {
      dispatch({
        type: "initialize",
        payload: enrolmentsQuery?.data?.enrolments,
      })
    }
  }, [enrolmentsQuery.isLoading, enrolmentsQuery.data])

  return (
    <div className="profile">
      {!personQuery.isLoading ? (
        <>
          {personQuery.data && !personQuery.isError ? (
            <>
              <Container style={{ paddingBottom: "7rem" }}>
                <Row gutter={32}>
                  <Col xs={24} lg={6}>
                    <ConditionalLink to={"/profile/edit"} condition={width < 922}>
                      <Card className="profile__card">
                        <Avatar
                          className="avatar"
                          style={{ marginBottom: "1.125rem" }}
                          src={
                            personQuery.data?.imageUrl ||
                            `https://api.dicebear.com/7.x/initials/svg?seed=${personQuery.data.firstName[0]}${personQuery.data.lastName[0]}`
                          }
                        />
                        <div className="details">
                          <Typography.Title level={2}>
                            {personQuery.data.firstName} {personQuery.data.lastName}
                          </Typography.Title>
                          {personQuery.data?.companyPerson && (
                            <Space className="hide--onMobile" style={{ marginBottom: "1.125rem" }} align="center">
                              <GlobalOutlined />
                              <Typography.Link>
                                {personQuery.data?.companyPerson?.company?.name || "Company"}
                              </Typography.Link>
                            </Space>
                          )}
                          <p>{personQuery.data?.detail?.biography}</p>
                          <Space style={{ width: "100%" }} direction="vertical">
                            {personQuery.data?.contact?.email && (
                              <Space>
                                <p>{personQuery.data?.contact?.email}</p>
                              </Space>
                            )}
                            {personQuery.data?.contact?.tel && (
                              <Space className="hide--onMobile">
                                <PhoneOutlined />
                                <Typography.Text>{personQuery.data?.contact?.tel}</Typography.Text>
                              </Space>
                            )}
                          </Space>
                          <Divider className="hide--onMobile" />
                          <Link className="hide--onMobile" to="/profile/edit">
                            <Button type="primary" block>
                              {t("Edit")}
                            </Button>
                          </Link>
                        </div>
                      </Card>
                    </ConditionalLink>
                    <div style={{ display: "flex" }}>
                      <Button
                        style={{ marginBottom: "1rem" }}
                        className="show--onMobile"
                        block
                        type="primary"
                        onClick={async () => {
                          window.localStorage.clear()
                          window.sessionStorage.clear()
                          await signOutMutation()
                        }}
                      >
                        {t("Signout")}
                      </Button>
                      <HelpDrawer
                        personQuery={personQuery}
                        visible={helpOpen}
                        onClose={() => setHelpOPen(false)}
                        drawerFormType={drawerFormType}
                      />
                      {personQuery?.data?.companyPerson?.company?.tenantId.includes("Thungela") ||
                      personQuery?.data?.companyPerson?.company?.tenantId.includes("Tempero") ? (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key={"support query"}>
                                <a href="mailto:trservicedesk@thungela.com?subject=Support%20Query&body=Which%20platform%20are%20you%20encountering%20the%20issue%20with%3F%20%0D%0A%0D%0ALXP%0D%0A%0D%0ATalent%20Management%0D%0A%0D%0APerformance%20Management%0D%0A%0D%0A%0D%0A%0D%0AQuery%20type%0D%0A%0D%0AAccess%0D%0A%0D%0ARegistration%0D%0A%0D%0AReporting%0D%0A%0D%0ACompletion%0D%0A%0D%0ABookings%0D%0A%0D%0AReset%20Assessment%0D%0A%0D%0AOther%20-%20Please%20explain%0D%0A%0D%0A%0D%0A%0D%0APlease%20copy%20the%20URL%20of%20the%20page%20where%20you%20are%20encountering%20the%20issue.%0D%0A%0D%0A%0D%0A%0D%0APlease%20attach%20screenshots%20of%20the%20issue%20so%20that%20we%20can%20better%20assist%20you.">
                                  Support Query
                                </a>
                              </Menu.Item>
                              <Menu.Item
                                key={"Course creation request"}
                                onClick={() => {
                                  setHelpOPen(true)
                                  setDrawerFormType("course")
                                }}
                              >
                                Course Creation Request
                              </Menu.Item>
                              <Menu.Item
                                key={"Content Creation Form"}
                                onClick={() => {
                                  setHelpOPen(true)
                                  setDrawerFormType("content")
                                }}
                              >
                                Content Creation Form
                              </Menu.Item>
                              <Menu.Item
                                key={"Training Request Form"}
                                onClick={() => {
                                  setHelpOPen(true)
                                  setDrawerFormType("training")
                                }}
                              >
                                Training Request Form
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button style={{ marginBottom: "1rem" }} className="show--onMobile" block type="link">
                            Help
                          </Button>
                        </Dropdown>
                      ) : personQuery?.data?.companyPerson?.company?.tenantId.includes("Brew") ? (
                        <Button
                          style={{ marginBottom: "1rem" }}
                          className="show--onMobile"
                          block
                          type="link"
                          href={
                            "mailto:support@brewmastersacademy.com?subject=Support%20Query&body=Query%20type%0D%0A%0D%0AAccess%0D%0A%0D%0ARegistration%0D%0A%0D%0AReporting%0D%0A%0D%0ACompletion%0D%0A%0D%0ABookings%0D%0A%0D%0AReset%20Assessment%0D%0A%0D%0AOther%20-%20Please%20explain%0D%0A%0D%0A%0D%0A%0D%0APlease%20copy%20the%20URL%20of%20the%20page%20where%20you%20are%20encountering%20the%20issue.%0D%0A%0D%0A%0D%0A%0D%0APlease%20attach%20screenshots%20of%20the%20issue%20so%20that%20we%20can%20better%20assist%20you."
                          }
                        >
                          Help
                        </Button>
                      ) : (
                        <Button
                          style={{ marginBottom: "1rem" }}
                          className="show--onMobile"
                          block
                          type="link"
                          href={
                            "mailto:support@winwinza.com?subject=Support%20Query&body=Query%20type%0D%0A%0D%0AAccess%0D%0A%0D%0ARegistration%0D%0A%0D%0AReporting%0D%0A%0D%0ACompletion%0D%0A%0D%0ABookings%0D%0A%0D%0AReset%20Assessment%0D%0A%0D%0AOther%20-%20Please%20explain%0D%0A%0D%0A%0D%0A%0D%0APlease%20copy%20the%20URL%20of%20the%20page%20where%20you%20are%20encountering%20the%20issue.%0D%0A%0D%0A%0D%0A%0D%0APlease%20attach%20screenshots%20of%20the%20issue%20so%20that%20we%20can%20better%20assist%20you."
                          }
                        >
                          Help
                        </Button>
                      )}
                    </div>
                  </Col>
                  <Col className="profile__content" xs={24} lg={18}>
                    <Tabs
                      defaultActiveKey={tabFactory().get() || "Dashboard"}
                      onChange={(keyValue) => tabFactory().onChange(keyValue)}
                    >
                      <TabPane tab={t("Dashboard")} key="Dashboard">
                        <Row style={{ marginBottom: "1.5rem" }} gutter={["16", "16"]}>
                          <Col style={{ marginBottom: 16 }} xs={24} sm={24} lg={8}>
                            <Card loading={coursesCount.isLoading}>
                              <Statistic
                                title={
                                  <>
                                    <BookOutlined size={12} />

                                    {t("Courses")}
                                  </>
                                }
                                value={coursesCount.data}
                                valueStyle={{ color: "#1997f7" }}
                              />
                            </Card>
                          </Col>
                          <Col style={{ marginBottom: 16 }} xs={24} sm={24} lg={8}>
                            <Card loading={programCount.isLoading}>
                              <Statistic
                                title={
                                  <>
                                    <UnorderedListOutlined size={12} />
                                    {t("Programs")}
                                  </>
                                }
                                value={programCount.data}
                                valueStyle={{ color: "#FEC22D" }}
                              />
                            </Card>
                          </Col>
                          <Col style={{ marginBottom: 16 }} xs={24} sm={24} lg={8}>
                            <Card loading={enrolmentsQuery.isLoading}>
                              <Statistic
                                title={
                                  <>
                                    <DeploymentUnitOutlined size={12} />
                                    {t("Badges")}
                                  </>
                                }
                                value={personQuery?.data?.badges?.length}
                                valueStyle={{ color: "#335191" }}
                              />
                            </Card>
                          </Col>
                        </Row>
                        <Typography.Title level={4}>{t("learningHistory")}</Typography.Title>
                        <div className="filter">
                          <div className="filter__input">
                            <Input
                              disabled={enrolmentsQuery.isLoading || !state?.initialized}
                              onChange={(e) =>
                                dispatch({
                                  type: "search",
                                  payload: e?.target?.value,
                                })
                              }
                              bordered={false}
                              placeholder={t("Search") + "..."}
                              prefix={<SearchOutlined />}
                            />
                          </div>
                          <div className="filter__actions hide--aboveMedium" style={{ width: "20%" }}>
                            <Select
                              style={{ width: "100%" }}
                              placeholder={t("selectAFilter") + "..."}
                              // placeholder="Select a filter..."
                              value={state?.sort}
                              disabled={enrolmentsQuery.isLoading || !state?.initialized}
                              onChange={(value) => dispatch({ type: "sort", payload: value })}
                              allowClear
                            >
                              <Select.Option value="all">{t("All")}</Select.Option>
                              <Select.Option value="completed">{t("Completed")}</Select.Option>
                              <Select.Option value="in-progress">In Progress</Select.Option>
                              <Select.Option value="failed">{t("Failed")}</Select.Option>
                              <Select.Option value="not-started">Not Started</Select.Option>
                            </Select>
                          </div>
                        </div>
                        {width > 992 ? (
                          <Table
                            scroll={{ x: 1300 }}
                            loading={enrolmentsQuery?.isLoading || !state.initialized}
                            dataSource={state?.tableData}
                            columns={
                              personQuery?.data?.company?.tenantId.includes("Seriti")
                                ? seritiProfileTables.columns
                                : tables.profile.columns
                            }
                            pagination={{
                              total: enrolmentsQuery?.data?.totalData ?? 0,
                              pageSize: 10,
                              defaultCurrent: 1,
                              onChange: (page) => dispatch({ type: "page", payload: page }),
                            }}
                          />
                        ) : (
                          <>
                            {!enrolmentsQuery?.isLoading && state.initialized ? (
                              <>
                                {state?.tableData?.map((row, i) => {
                                  return (
                                    <SmallCard
                                      onMouseEnter={() =>
                                        queryClient.prefetchQuery(
                                          [row?.enrolment?.uid],
                                          () => fetchEnrolment(row?.enrolment?.uid, queryClient),
                                          {
                                            staleTime: 1000 * 30,
                                          }
                                        )
                                      }
                                      key={row.key}
                                      href={`/${row?.enrolment?.type?.toLowerCase()}s/${row?.enrolment?.uid}`}
                                      title={row?.enrolment?.title}
                                      topics={row?.status?.filter((x) => x)}
                                      url={row?.enrolment?.src}
                                      meta1={row?.type}
                                      certificate={row?.certificate}
                                    />
                                  )
                                })}
                              </>
                            ) : null}
                          </>
                        )}
                      </TabPane>
                      {!isThungela && (
                        <TabPane tab={t("Badges")} key="Badges">
                          <Image.PreviewGroup>
                            <Row gutter={[16, 16]}>
                              {personQuery.data?.badges?.map((badge) => (
                                <Col key={badge?.uid} xs={24} sm={8}>
                                  <Card cover={<Image className="profile__badge" src={badge?.imageUrl} />}>
                                    <Card.Meta title={badge?.name} description={badge?.description} />
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          </Image.PreviewGroup>
                        </TabPane>
                      )}
                      <TabPane tab={t("trainingRequests")} key="trainingRequests">
                        {!loading ? (
                          <>
                            {training?.length ? (
                              <List
                                itemLayout="horizontal"
                                dataSource={training}
                                size="large"
                                renderItem={(item) => (
                                  <List.Item
                                    actions={[
                                      <Space className="hide--aboveMedium" key="activityActions">
                                        <Tag color="green">{t("Training")}</Tag>
                                        <Avatar.Group
                                          maxCount={2}
                                          maxStyle={{
                                            color: "#f56a00",
                                            backgroundColor: "#fde3cf",
                                          }}
                                        >
                                          {item.members.map((member) => (
                                            <Tooltip key={generateKey(member.uid)} title={member.name}>
                                              <Avatar src={member.imageUrl} />
                                            </Tooltip>
                                          ))}
                                        </Avatar.Group>
                                      </Space>,
                                    ]}
                                  >
                                    <List.Item.Meta
                                      // style={{ width: "55%" }}
                                      avatar={<Avatar src={item?.training?.imageUrl} />}
                                      title={
                                        <Space>
                                          {item?.training?.title}
                                          <TagFactory status={item?.status}>
                                            {item?.status?.toSentenceCase()}
                                          </TagFactory>
                                        </Space>
                                      }
                                      description={
                                        <Space direction="vertical">
                                          <Typography.Paragraph
                                            ellipsis={{
                                              rows: 2,
                                              expandable: true,
                                              symbol: "more",
                                            }}
                                          >
                                            {stripHtml(item?.training?.descriptionAsHTML)}
                                          </Typography.Paragraph>
                                          <Badge
                                            color="purple"
                                            text={
                                              <span className="badge--meta">
                                                {t("dateRange")}: {dayjs(item?.date[0]).format("DD/MM/YYYY")} -{" "}
                                                {dayjs(item?.date[1]).format("DD/MM/YYYY")}
                                              </span>
                                            }
                                          />
                                        </Space>
                                      }
                                    />
                                  </List.Item>
                                )}
                              />
                            ) : (
                              <Empty />
                            )}
                          </>
                        ) : (
                          <Spin />
                        )}
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <Empty />
          )}
        </>
      ) : (
        <Container>
          <Row gutter={32}>
            <Col xs={6}>
              <Skeleton.Button active className="profile__card--loading" />
            </Col>
            <Col xs={18}>
              <Skeleton active style={{ width: "100%", marginTop: "calc(2.5rem * 1.6)" }} />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

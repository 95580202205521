import React, { useState, useMemo } from "react"
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Comment,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
} from "antd"
import { useHistory, useParams } from "react-router-dom"
import { useGetSingleTeam } from "../../network/useTeams"
import { makeProfileImg } from "../../hooks/makeProfileImg"
import { useGetTraining } from "../../network/useEnrolments"
import { useTrainingMutation } from "../../network/useTraining"
import { tabFactory } from "../../helpers/tabsFactory"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(LocalizedFormat)

export function TeamBooking() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(true)
  const [trainingUid, setTrainingUid] = useState(null)
  const [filterString, setFilterString] = useState("")

  const { replace } = useHistory()
  const { teamUid } = useParams()
  const teamQuery = useGetSingleTeam({ teamUid })
  const trainingQuery = useGetTraining()
  const trainingMutation = useTrainingMutation()

  const personUid = tabFactory("personUid").get()
  const onCancel = () => {
    setVisible(false)
    setTimeout(() => replace(`/teams/${teamUid}`), 500)
  }
  //   initial status requested
  const createTrainingRequest = async (values) => {
    const payload = {
      ...values,
      teamUid,
      status: "requested",
    }

    try {
      setLoading(true)
      await trainingMutation.mutateAsync({
        data: payload,
      })
      onCancel()
    } catch (error) {
      message.error(`Something went wrong: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  const dateOptions = useMemo(() => {
    const training = trainingQuery.data?.find((x) => x?.uid === trainingUid)
    if (!training) return ""
    return (
      <Form.Item rules={[{ required: true }]} name="dates">
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={[16, 16]}>
            {training?.events?.map((event) => {
              return (
                <Col span={12} key={event?.uid}>
                  <Card>
                    <Radio
                      value={{
                        bookingUid: event?.uid,
                        date: [event?.startDate, event?.endDate],
                      }}
                      id={event?.uid}
                      className="option__choice"
                    >
                      <Space size={32}>
                        <div>
                          <Typography.Paragraph style={{ margin: 0 }} type="secondary">
                            Start Date
                          </Typography.Paragraph>
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            {dayjs(event?.startDate).format("ll")}
                          </Typography.Title>
                        </div>
                        <div>
                          <Typography.Paragraph style={{ margin: 0 }} type="secondary">
                            End Date
                          </Typography.Paragraph>
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            {dayjs(event?.endDate).format("ll")}
                          </Typography.Title>
                        </div>
                      </Space>
                    </Radio>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Radio.Group>
      </Form.Item>
    )
  }, [trainingUid])
  const onTrainingChange = (changedFields) => {
    if ("training" in changedFields === false) return null

    setTrainingUid(changedFields?.training?.uid)
    form.setFieldsValue({
      date: null,
    })
  }

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="modal"
      onCancel={() => onCancel()}
      closeIcon={null}
    >
      <PageHeader className="site-page-header" onBack={() => onCancel()} title="Request Training" />
      <Divider />
      <Typography.Title level={5}>Team Members</Typography.Title>
      <Form
        onValuesChange={onTrainingChange}
        form={form}
        name="trainingForm"
        layout="vertical"
        initialValues={
          personUid
            ? {
                members: [
                  {
                    uid: personUid,
                    name: `${
                      teamQuery?.data?.members?.find((x) => x?.member?.person?.uid === tabFactory("personUid").get())
                        ?.member?.person?.firstName
                    } ${
                      teamQuery?.data?.members?.find((x) => x?.member?.person?.uid === tabFactory("personUid").get())
                        ?.member?.person?.lastName
                    }`,
                    imageUrl: makeProfileImg(
                      teamQuery?.data?.members?.find((x) => x?.member?.person?.uid === tabFactory("personUid").get())
                        ?.member?.person?.imageUrl ||
                        teamQuery?.data?.members?.find((x) => x?.member?.person?.uid === tabFactory("personUid").get())
                          ?.member?.person?.firstName?.[0]
                    ),
                  },
                ],
              }
            : {}
        }
        onFinish={() => {
          form.validateFields().then(async (values) => {
            const {
              dates: { bookingUid, date },
            } = values
            const payload = {
              ...values,
              assignedToUid: teamUid,
              bookingUid,
              date,
            }
            await createTrainingRequest(payload)
          })
        }}
      >
        <Form.Item name="members">
          <Checkbox.Group style={{ width: "100%" }}>
            <Row gutter={["16", "16"]}>
              {personUid ? (
                <Col
                  key={
                    teamQuery?.data?.members?.find((x) => x?.member?.person?.uid === tabFactory("personUid").get())?.uid
                  }
                  className="chat__person"
                  xs={24}
                  md={12}
                >
                  <Comment
                    style={{ width: "100%" }}
                    author="Member"
                    avatar={
                      <Avatar
                        src={
                          teamQuery?.data?.members?.find(
                            (x) => x?.member?.person?.uid === tabFactory("personUid").get()
                          )?.member.person?.imageUrl ||
                          makeProfileImg(
                            `${
                              teamQuery?.data?.members?.find(
                                (x) => x?.member?.person?.uid === tabFactory("personUid").get()
                              )?.member?.person?.firstName[0]
                            }${
                              teamQuery?.data?.members?.find(
                                (x) => x?.member?.person?.uid === tabFactory("personUid").get()
                              )?.member?.person?.lastName[0]
                            }`
                          )
                        }
                      />
                    }
                    content={`${
                      teamQuery?.data?.members?.find((x) => x?.member?.person?.uid === tabFactory("personUid").get())
                        ?.member?.person?.firstName
                    } ${
                      teamQuery?.data?.members?.find((x) => x?.member?.person?.uid === tabFactory("personUid").get())
                        ?.member?.person?.lastName
                    } `}
                  />
                </Col>
              ) : (
                teamQuery.data?.members?.map((person, i) => (
                  <Col key={person?.uid} className="chat__person" xs={24} md={12}>
                    {" "}
                    <Checkbox
                      id={person.uid}
                      className="option__choice"
                      value={{
                        uid: person?.member?.person?.uid,
                        name: `${person?.member?.person?.firstName} ${person?.member?.person?.lastName}`,
                        imageUrl:
                          person?.member.person?.imageUrl ||
                          makeProfileImg(
                            `${person?.member?.person?.firstName[0]}${person?.member?.person?.lastName[0]}`
                          ),
                      }}
                    >
                      <Comment
                        style={{ width: "100%" }}
                        author="Member"
                        avatar={
                          <Avatar
                            src={
                              person?.member.person?.imageUrl ||
                              makeProfileImg(
                                `${person?.member?.person?.firstName[0]}${person?.member?.person?.lastName[0]}`
                              )
                            }
                          />
                        }
                        content={`${person?.member?.person?.firstName} ${person?.member?.person?.lastName} `}
                      />
                    </Checkbox>
                  </Col>
                ))
              )}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Divider />
        <Typography.Title level={5}>Select In-person Training Course</Typography.Title>
        <div className="filter">
          <Input
            value={filterString}
            onChange={(e) => setFilterString(e.target?.value)}
            className="filter__input filter__input--noMargin"
            bordered={false}
            placeholder="Search..."
          />
        </div>
        {!trainingQuery.isLoading ? (
          <>
            {trainingQuery?.data ? (
              <Form.Item rules={[{ required: true }]} name="training">
                <Radio.Group style={{ width: "100%" }}>
                  <Row gutter={["16", "16"]}>
                    {trainingQuery.data
                      ?.filter((x) => {
                        if (filterString === "") return true
                        else if (x?.content?.title?.toLowerCase().includes(filterString.toLowerCase())) return true
                      })
                      ?.map((training) => (
                        <Col key={training?.uid} className="chat__person" xs={24} md={12}>
                          <Radio
                            id={training.uid}
                            // onChange={onTrainingChange}
                            className="option__choice"
                            value={{
                              uid: training.uid,
                              type: training?.type,
                              typeUid: training?.typeUid,
                              title: training.content.title || "",
                              summary: training.content.summary || "Summary",
                              descriptionAsHTML: training.content.descriptionAsHTML || "",
                              isFacilitated: training?.isFacilitated,
                              featureImageUrl:
                                training?.featureImageUrl || makeProfileImg(`${training.content.title[0] || "A"}`),
                            }}
                          >
                            <Comment
                              style={{ width: "100%" }}
                              author="Course"
                              avatar={
                                <Avatar
                                  src={training?.featureImageUrl || makeProfileImg(`${training?.content?.title[0]}`)}
                                />
                              }
                              content={`${training?.content?.title}`}
                            />
                          </Radio>
                        </Col>
                      ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
            ) : (
              <Empty />
            )}
          </>
        ) : (
          <Spin />
        )}
        <Typography.Title level={5}>Select a date</Typography.Title>
        {dateOptions}
        <Button loading={loading} type="primary" htmlType="submit">
          Create Training Request
        </Button>
      </Form>
    </Modal>
  )
}

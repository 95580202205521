import React, { useState } from "react"
import { Button, Card, Layout, message, Space, Typography } from "antd"
import { RightOutlined } from "@ant-design/icons"
import { useEnrolmentActivtyMutation } from "../../../../network/useEnrolmentActivity"
import { trackAction, useLesson } from "../../../../hooks/lesson-context"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

const { Footer } = Layout

export function Page({ materialAsHTML, lessonQuery, moduleQuery, isPreviewing }) {
  const { enrolmentUid, lessonUid, enrolmentType } = useParams()
  const { state, dispatch } = useLesson()
  const { t } = useTranslation()

  const mutator = useEnrolmentActivtyMutation()

  return (
    <Layout style={{ background: "#f8f8f881" }}>
      <div className="ql-editor" dangerouslySetInnerHTML={{ __html: materialAsHTML }} />
      <Footer style={{ background: "none" }} className="lesson__footer non-fixed">
        <Space size={16}>
          <Card
            className="lesson__button lesson__button--next"
            bordered
            hoverable
            onClick={async () => {
              if (!isPreviewing) {
                await trackAction({
                  publicationEnrolmentUid: enrolmentUid,
                  dispatch,
                  mutator,
                  payload: {
                    typeUid: lessonUid,
                    type: "lesson",
                    status: "completed",
                  },
                })
              }

              if (!state?.actionRunning) {
                dispatch({
                  type: "next",
                  payload: {
                    isPreviewing,
                    lessonUid,
                    enrolmentUid,
                    enrolmentType,
                    currentModule: moduleQuery?.data,
                    ...lessonQuery?.data?.next,
                  },
                })
              } else {
                message?.info("Saving progress...")
              }
            }}
          >
            <Space size={16}>
              <Button
                loading={state?.actionRunning}
                type="primary"
                shape="circle"
                className="icon"
                icon={<RightOutlined />}
              />
              <Space size={0} direction="vertical" align="start" className="button__content">
                <Typography.Text className="title" strong>
                  {t("finishReading")}
                </Typography.Text>
                <Typography.Text className="description" style={{ color: "white" }}>
                  {t("andContinueLearning")}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Space>
      </Footer>
    </Layout>
  )
}

import {
  useAuthSendPasswordResetEmail,
  useAuthSignInWithEmailAndPassword,
  useAuthSignOut,
} from "@react-query-firebase/auth"
import { Button, Col, Divider, Form, Input, message, Modal, PageHeader, Row, Spin, Typography } from "antd"
import {
  getMultiFactorResolver,
  getRedirectResult,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from "firebase/auth"
import React, { useEffect, useReducer, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { CustomEmailPassword } from "../../components/auth/CustomEmailPassword"
import { EmailPassword } from "../../components/auth/EmailPassword"
import { Microsoft } from "../../components/auth/Microsoft"
import { SupportModal } from "../../components/SupportModal"
import { auth } from "../../firebase"
import { useHandleTenantId } from "../../hooks/useHandleTenantId"
import { useQueryParam } from "../../hooks/useQueryParam"
import { useLoggerMutation } from "../../network/useLogger"
import { usePreAuthMutation } from "../../network/usePerson"
import { useQueryClient } from "react-query"

function signInReducer(state, action) {
  const { type, payload, field } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export function SignInWrapper() {
  const queryParam = useQueryParam()
  const SIGN_IN_STATE = {
    type: null,
    tenantId: queryParam.get("tenantId"),
    reset: false,
    loading: false,
    authenticated: false,
    authorized: false,
    returnUrl: queryParam.get("returnUrl"),
    mfa: false,
    resolver: false,
    verificationId: false,
    support: false,
  }

  const [state, dispatch] = useReducer(signInReducer, SIGN_IN_STATE)
  const DEFAULT_BG =
    "https://images.unsplash.com/photo-1612999805994-1eabaef7bb88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
  const { tenant, isLoading, isError, awaitTenant } = useHandleTenantId(state, dispatch)
  const buttonRef = useRef(null)
  const history = useHistory()

  const queryClient = useQueryClient()

  auth.tenantId = state.tenantId || process.env.REACT_APP_TENANT_ID
  const { mutate: signOutMutation } = useAuthSignOut(auth)
  const resetMutation = useAuthSendPasswordResetEmail(auth)
  const emailMutation = useAuthSignInWithEmailAndPassword(auth)
  const externalAuthPreMutation = usePreAuthMutation()
  const logMutation = useLoggerMutation()

  useEffect(() => {
    queryClient.invalidateQueries()
  }, [])

  const handleEmailAuthentication = async (payload) => {
    try {
      const res = await emailMutation.mutateAsync(payload)
      window.localStorage.setItem("tenantId", res?.user?.tenantId)
      logMutation.mutate({
        data: {
          event: "login",
          context: {
            type: state?.type,
          },
        },
      })
      window.location.pathname = queryParam.get("returnUrl") || "/"
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        const fnResolver = getMultiFactorResolver(auth, error)
        dispatch({ type: "field", field: "resolver", payload: fnResolver })
        if (fnResolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          const phoneInfoOptions = {
            multiFactorHint: fnResolver.hints[0],
            session: fnResolver.session,
          }
          const recaptchaVerifier = new RecaptchaVerifier(buttonRef.current, { size: "invisible" }, auth)
          const verificationId = await new PhoneAuthProvider(auth).verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          )
          dispatch({
            type: "field",
            field: "verificationId",
            payload: verificationId,
          })
          dispatch({ type: "field", field: "mfa", payload: true })
          window.localStorage.setItem("tenantId", tenant?.tenantId)
        }
      } else {
        console.error(error)
        message.error(`Error Authenticating: ${error.message}`)
        dispatch({ type: "field", field: "loading", payload: false })
      }
    }
  }

  getRedirectResult(auth).then(async (res) => {
    if (res) {
      dispatch({ type: "field", field: "loading", payload: true })
      try {
        await externalAuthPreMutation.mutateAsync({
          data: {
            email: res.user.email,
            authUid: res.user.uid,
            microsoftProfile: res,
          },
        })
        logMutation.mutate({
          data: {
            event: "login",
            context: {
              type: "sso",
            },
          },
        })
        // history.push("/")
        window.location.pathname = "/"
      } catch (error) {
        message.error("Failed to sign in using credentials provided")
        dispatch({ type: "field", field: "loading", payload: false })
        window.localStorage.clear()
        window.sessionStorage.clear()
        await signOutMutation()
      }
    }
  })

  const verifyCode = async (code) => {
    const cred = PhoneAuthProvider.credential(state?.verificationId, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    await state?.resolver.resolveSignIn(multiFactorAssertion)
  }

  const emailModifier = ({ prefix = null, suffix = null, email }) => {
    let modifiedEmail = email
    if (prefix) {
      modifiedEmail = prefix + modifiedEmail
    }
    if (suffix) {
      modifiedEmail += suffix
    }
    return modifiedEmail
  }

  const description = (
    <Link to={`/auth/register?tenantId=${tenant?.tenantId}`}>
      <Typography.Link style={{ color: "white", textDecoration: "underline" }}>Link existing account.</Typography.Link>
    </Link>
  )
  const selfReg = (
    <Link to={`/auth/self-register?tenantId=${tenant?.tenantId}`}>
      <Typography.Link style={{ color: "white", textDecoration: "underline" }}>Register Now.</Typography.Link>
    </Link>
  )

  return (
    <>
      <SupportModal
        visible={state?.support}
        setVisible={() => dispatch({ type: "field", field: "support", payload: false })}
        support={tenant?.support}
      />
      <Modal
        visible={state?.mfa}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className="modal"
        closable={false}
      >
        <PageHeader
          className="site-page-header"
          onBack={() => {
            dispatch({ type: "field", field: "mfa", payload: false })
            dispatch({ type: "field", field: "loading", payload: false })
          }}
          title="Confirm your number"
        />
        <Form
          layout="vertical"
          onFinish={async (values) => {
            await verifyCode(values.code)
            // history.push("/")
            window.location.pathname = "/"
          }}
        >
          <Form.Item name="code" label="Enter the code we sent over SMS">
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Enter Code
          </Button>
        </Form>
      </Modal>
      <Modal
        visible={state?.reset}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className="modal"
        onCancel={() => {
          dispatch({ type: "field", field: "reset", payload: false })
        }}
      >
        <Typography.Title level={3}>Reset Password</Typography.Title>
        <Divider />
        <Form
          name="reset-password"
          layout="vertical"
          onFinish={async (values) => {
            await resetMutation.mutateAsync({
              email: values.email,
              actionCodeSettings: {
                handleCodeInApp: true,
                url: `${process.env.REACT_APP_URL}/auth/sign-in`,
              },
            })
            message.info("Check your email for password reset link")
            dispatch({ type: "field", field: "reset", payload: false })
          }}
        >
          <Form.Item label="Email" name="email" rules={[{ required: true, message: "Email is required" }]}>
            <Input type="email" placeholder="Enter your email" />
          </Form.Item>
          <Button loading={resetMutation.isLoading} htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
      <div
        className="auth"
        style={{
          backgroundImage: `url(${tenant?.featureImageUrl || DEFAULT_BG})`,
        }}
      >
        <Row>
          <Col xs={24} md={12} lg={10} xl={8} className="auth__pannel">
            <Form
              name="login"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={async (values) => {
                dispatch({ type: "field", field: "loading", payload: true })
                const payload = {
                  email: values[state?.type] || values["email"] || values.identifier,
                  password: values.password,
                }
                switch (state?.type) {
                  case "identificationNumber": {
                    payload.email = emailModifier({
                      suffix: tenant?.providerConfig?.identificationNumber?.suffix,
                      email: payload?.email,
                    })
                    break
                  }
                  case "employeeNumber": {
                    payload.email = emailModifier({
                      suffix: tenant?.providerConfig?.employeeNumber?.suffix,
                      email: payload?.email,
                    })
                    break
                  }
                  case "mobile": {
                    payload.email = emailModifier({
                      prefix: `+${payload.email?.code}`,
                      suffix: tenant?.providerConfig?.mobile?.suffix,
                      email: payload.email.phone,
                    })
                    break
                  }
                }
                await handleEmailAuthentication(payload)
              }}
            >
              {!awaitTenant && !isLoading && !isError ? (
                <>
                  {tenant?.imageUrl && <img src={tenant?.imageUrl} alt="company" className="companyLogo" />}
                  {(() => {
                    switch (state?.type) {
                      case "email":
                        return (
                          <EmailPassword
                            description={
                              <>
                                Sign in with your email and password. <br />
                                {!isLoading && tenant?.linkRegistration && description}
                                <br />
                                {!isLoading && tenant?.selfRegistrationEnabled && selfReg}
                              </>
                            }
                          />
                        )
                      case "employeeNumber":
                        return (
                          <CustomEmailPassword
                            type="text"
                            title="Sign In"
                            description={
                              <>
                                Sign in with your employee number and password. <br />
                                {!isLoading && tenant?.linkRegistration && description}
                                <br />
                                {!isLoading && tenant?.selfRegistrationEnabled && selfReg}
                              </>
                            }
                            emailPlaceholder="Employee Number..."
                          />
                        )
                      case "mobile":
                        return (
                          <CustomEmailPassword
                            description={
                              <>
                                Sign in with your mobile number. <br />
                                {!isLoading && tenant?.linkRegistration && description}
                                <br />
                                {!isLoading && tenant?.selfRegistrationEnabled && selfReg}
                              </>
                            }
                            title="Sign In"
                            type="number"
                            emailPlaceholder="Mobile number..."
                          />
                        )
                      case "microsoft":
                        return (
                          <Microsoft
                            customText={tenant?.providerConfig?.[state.type]?.title}
                            description={
                              <>
                                Sign in with your work account using Single Sign on. <br />
                                {!isLoading &&
                                  !state.tenantId.includes("Seriti") &&
                                  tenant?.linkRegistration &&
                                  description}
                                <br />
                                {!isLoading && tenant?.selfRegistrationEnabled && selfReg}
                              </>
                            }
                            auth={auth}
                            state={state}
                            dispatch={dispatch}
                            tenant={tenant?.microsoftTenant}
                          />
                        )
                      case "identificationNumber":
                        return (
                          <EmailPassword
                            emailPlaceholder="Identification number..."
                            emailError="please enter a valid identification number"
                            type={"text"}
                            title={tenant?.providerConfig?.[state.type]?.title ?? "Sign in with identification number"}
                            description={<>Sign in with your identification number {description}</>}
                          />
                        )
                      default:
                        return (
                          <EmailPassword
                            description={
                              <>
                                Sign in with your email and password. <br />
                                {!isLoading && tenant?.linkRegistration && description}
                                <br />
                                {!isLoading && tenant?.selfRegistrationEnabled && selfReg}
                              </>
                            }
                          />
                        )
                    }
                  })()}
                  {state?.type !== "microsoft" && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={state?.loading || isLoading}
                      block
                      style={{ marginBottom: "1rem" }}
                      id="recaptcha-container"
                      ref={buttonRef}
                    >
                      Sign in
                    </Button>
                  )}
                  {state?.type === "email" && (
                    <Button
                      style={{ color: "white", textDecoration: "underline" }}
                      onClick={() =>
                        dispatch({
                          type: "field",
                          field: "reset",
                          payload: true,
                        })
                      }
                      type="link"
                    >
                      Forgot your password?
                    </Button>
                  )}
                  {tenant?.providers
                    ?.filter((x) => x !== state?.type)
                    ?.map((provider) => (
                      <Button
                        disabled={state?.loading}
                        style={{ color: "white", textDecoration: "underline" }}
                        type="link"
                        block
                        onClick={() =>
                          dispatch({
                            type: "field",
                            field: "type",
                            payload: provider,
                          })
                        }
                        htmlType="button"
                        key={provider}
                      >
                        {tenant?.providerConfig?.[provider]?.title ||
                          "Sign in with " +
                            provider
                              .split(/(?=[A-Z])/)
                              .map((s) => s.toLowerCase())
                              .join(" ")}
                      </Button>
                    ))}
                  {tenant?.classroomEnabled && (
                    <Button
                      href={`/auth/classroom${state?.tenantId ? `?tenantId=${state?.tenantId}` : ""}`}
                      type="link"
                      htmlType="button"
                      block
                      style={{ marginBottom: "1rem" }}
                    >
                      Join a classroom
                    </Button>
                  )}
                  {tenant?.support?.length ? (
                    <Button
                      key="password-reset"
                      style={{ color: "white", textDecoration: "underline" }}
                      onClick={() =>
                        dispatch({
                          type: "field",
                          field: "support",
                          payload: true,
                        })
                      }
                      type="link"
                    >
                      Trouble signing in?
                    </Button>
                  ) : null}
                </>
              ) : awaitTenant && isLoading ? (
                <Spin tip="Fetching your authentication options" />
              ) : (
                awaitTenant &&
                isError && (
                  <>
                    <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>
                      We are unable to fetch you authentication options
                    </Typography.Text>
                    <a href={`/auth/sign-in?${state?.tenantId ? `?tenantId=${state?.tenantId}` : ""}`}>
                      <Button block type="primary">
                        Please refresh
                      </Button>
                    </a>
                  </>
                )
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </>
  )
}

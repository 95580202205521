import React from "react"
import { Col, Form, Radio, Row } from "antd"

export function TrueAndFalse({
  question,
  questionIndex,
  dispatch,
  isFacilitate = false,
  disabled = false,
  required = false,
  inReview = false,
}) {
  const isWrong = (answer) => {
    if (!inReview) return ""
    const radio = document.getElementById(answer.uid)
    if (radio?.checked && answer.correct === 0) {
      return "option__choice--wrong"
    }
    return ""
  }

  return (
    <Form.Item
      name={`q-${questionIndex + 1}`}
      rules={[
        {
          required,
          message: "Please select an answer",
        },
      ]}
    >
      <Radio.Group style={{ width: "100%" }}>
        <Row gutter={["16", "16"]}>
          {question.answers.map((answer) => (
            <Col key={answer.uid} style={{ marginBottom: "1rem" }} xs={24} sm={24} lg={12}>
              <Radio
                id={answer.uid}
                onChange={() =>
                  dispatch({
                    type: "field",
                    field: "canProceed",
                    payload: true,
                  })
                }
                className={`option__choice ${
                  isFacilitate !== "true" && inReview && answer.correct === 1 ? "option__choice--correct" : ""
                } ${isWrong(answer)}`}
                value={answer.correct}
              >
                {answer?.title || answer?.translations?.["1"]?.title}
              </Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </Form.Item>
  )
}

import React, { useEffect, useState, useMemo } from "react"
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Image,
  message,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Statistic,
  Tag,
  Typography,
} from "antd"
import { CheckSquareOutlined, BookOutlined, ContainerOutlined, LockOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { progressFactory } from "../../../helpers/progressFactory"
import { useGetModule } from "../../../network/useModule"
import { useEnrolmentMutation } from "../../../network/useEnrolment"
import { isModuleLocked } from "../../../helpers/isModuleLocked"
import { useQueryClient } from "react-query"
import { fetchLesson } from "../../../network/useLearning"
import { useGetPerson } from "../../../network/usePerson"
import { useTranslation } from "react-i18next"

export function ProgramModuleModal({ moduleUid, sectionUid, programQuery }) {
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const { replace, push } = useHistory()
  const { t } = useTranslation()

  const moduleQuery = useGetModule({
    enrolmentUid: programQuery?.data?.uid,
    moduleUid,
  })
  const personQuery = useGetPerson({ authenticated: true })
  const enrolmentMutation = useEnrolmentMutation(programQuery?.data?.uid)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!moduleQuery.isLoading) {
      if (!moduleQuery.data) {
        message.error("Sorry, we could not find the module you were looking for")
        setTimeout(() => replace(`/courses/${programQuery?.data?.uid}`), 500)
      } else {
        queryClient.prefetchQuery(
          [programQuery?.data?.uid, "lesson", moduleQuery?.data?.learning?.[0]?.uid],
          () => fetchLesson(programQuery?.data?.uid, moduleQuery?.data?.learning?.[0]?.uid, queryClient),
          {
            staleTime: 1000 * 60 * 5,
          }
        )
      }
    }
  }, [moduleQuery.isLoading, moduleQuery.data])

  const progressPercentage = useMemo(() => {
    if (!moduleQuery.isLoading && moduleQuery.data) {
      return progressFactory.module(moduleQuery.data, moduleQuery?.data?.activity || []).resultAsPercent
    }
  }, [moduleQuery.isLoading, moduleQuery.data])

  const handleClick = async (href) => {
    if (programQuery?.data?.currentState !== "not-started") push(href)
    else {
      setLoading(true)
      notification.success({
        message: `${t("Hey")}, ${personQuery.data?.firstName}!`,
        description: `${t("thankYouForStartingThe")} ${programQuery.data?.publication?.translations["1"]?.title} ${t(
          "program"
        )}, ${t("weHopeYourEnjoyIt")}.`,
      })
      const res = await enrolmentMutation.mutateAsync(
        {
          enrolmentUid: programQuery?.data?.uid,
          data: {
            currentState: "in-progress",
            startedAt: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
          },
        },
        {
          onSettled: () => {
            setLoading(false)
          },
        }
      )
      if (res.data?.length) {
        queryClient.invalidateQueries(programQuery?.data?.uid)
      }
      push(href)
    }
  }

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="modal modal--noPadding"
      onCancel={() => {
        setVisible(false)
        setTimeout(() => replace(`/programs/${programQuery?.data?.uid}`), 500)
      }}
    >
      {!moduleQuery.isLoading && moduleQuery.data ? (
        <>
          <div
            className="modal__image"
            style={{
              backgroundImage: moduleQuery?.data?.featureImageUrl
                ? `url('${moduleQuery?.data?.featureImageUrl}')`
                : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
            }}
          />
          <div className="modal__body">
            <Space style={{ marginBottom: "0.625rem" }}>
              {progressPercentage === 100 && <Tag color="green">{t("Completed")}</Tag>}
            </Space>
            <Typography.Title level={2}>{moduleQuery.data?.title}</Typography.Title>
            <Typography.Paragraph className="desc">
              {/* <div
                dangerouslySetInnerHTML={{
                  __html:
                    moduleQuery?.data?.moduleVersion?.module?.translations["1"]
                      ?.descriptionAsHTML,
                }}
              /> */}
            </Typography.Paragraph>

            <Space style={{ width: "100%", marginBottom: "2.5rem" }}>
              <Badge
                color="green"
                text={
                  <span className="badge--meta">
                    {moduleQuery.data.dueDate ? dayjs(moduleQuery.data.dueDate).format("DD/MM/YYYY") : t("noDueDate")}
                  </span>
                }
              />
              <Badge
                color="purple"
                text={
                  <span className="badge--meta">
                    {t("Lessons")}: {moduleQuery.data.learning?.length || 0}
                  </span>
                }
              />
            </Space>

            {!moduleQuery?.isLoading && !programQuery?.isLoading ? (
              <>
                {moduleQuery?.data?.currentState !== "completed" &&
                isModuleLocked(
                  moduleQuery.data,
                  programQuery?.data?.publication,
                  programQuery?.data?.activity,
                  programQuery.data?.publication?.material?.findIndex((x) => x?.uid === sectionUid),
                  programQuery?.data?.isLocked
                ) ? (
                  <Tag color="orange" icon={<LockOutlined />}>
                    {t("thisModuleIsLocked")}
                  </Tag>
                ) : (
                  <Button
                    loading={loading}
                    onClick={() =>
                      handleClick(
                        `/enrolments/${programQuery?.data?.uid}/modules/${
                          moduleQuery?.data?.continue?.moduleUid || moduleQuery?.data?.uid
                        }/learning/${
                          moduleQuery?.data?.continue?.lessonUid || moduleQuery?.data?.learning?.[0]?.uid
                        }/programs`
                      )
                    }
                    type="primary"
                  >
                    {t("goToModule")}
                  </Button>
                )}
              </>
            ) : (
              <Button loading>Loading Progress</Button>
            )}

            <Divider />
            <Row gutter={16}>
              <Col xs={24} lg={8}>
                <Card>
                  <Statistic
                    title={t("Lessons")}
                    value={moduleQuery.data?.lessonCount}
                    valueStyle={{ color: "#7fba7a" }}
                    prefix={<BookOutlined />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card>
                  <Statistic
                    title={t("Assessments")}
                    value={moduleQuery.data?.learning.filter((x) => x.kind === "assessment")?.length || 0}
                    valueStyle={{ color: "#fec22d" }}
                    prefix={<ContainerOutlined />}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <Card>
                  <Statistic
                    title={t("Assignments")}
                    value={moduleQuery.data?.learning.filter((x) => x.kind === "assignment")?.length || 0}
                    valueStyle={{ color: "#335191" }}
                    prefix={<CheckSquareOutlined />}
                  />
                </Card>
              </Col>
            </Row>
            <Divider />
            <Typography.Title level={3}>{t("Learning")}</Typography.Title>
            <ul className="learning--list">
              {moduleQuery.data?.learning.map((item) => {
                const complete = moduleQuery?.data?.activity?.some(
                  (x) => x?.uid === item?.uid && (x.status === "completed" || x?.status === "submitted")
                )
                const failed = moduleQuery?.data?.activity?.some((x) => x?.uid === item?.uid && x.status === "failed")
                return (
                  <li key={item.uid} className={complete ? "completed" : failed ? "failed" : ""}>
                    <Typography.Text strong>{item?.title}</Typography.Text>
                    <Space>
                      <Tag
                        className="capitalize"
                        color={item.kind === "lesson" ? "green" : item.kind === "assessment" ? "blue" : "purple"}
                      >
                        {`${item.kind}${item?.type ? `: ${item?.type}` : ""}`}
                      </Tag>
                    </Space>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      ) : (
        <Spin />
      )}
    </Modal>
  )
}

import {
  Avatar,
  Button,
  Comment,
  Empty,
  Form,
  Input,
  Layout,
  message as Alert,
  Modal,
  PageHeader,
  Skeleton,
  Space,
  Tabs,
  Typography,
  Upload,
  Row,
  Col,
} from "antd"
import React, { useEffect, useState, useRef } from "react"
import { MessageOutlined, SendOutlined, InboxOutlined, UploadOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"
import { arrayUnion, doc, onSnapshot, updateDoc } from "firebase/firestore"
import dayjs from "dayjs"
import db from "../../firebase"
import { useGetPerson } from "../../network/usePerson"
import { ChatPolicy } from "../../components/ChatPolicy"
import { nonHookUploader } from "../../hooks/nonHookUploader"
import { MessageText } from "../../components/messages/MessageText"
import { MessageImage } from "../../components/messages/MessageImage"
import { tabFactory } from "../../helpers/tabsFactory"
import { useMedia } from "../../network/useMedia"

const { Dragger } = Upload
const { Content, Footer, Sider } = Layout

export function SelectedChat({ channels = [] }) {
  const mutator = useMedia()
  const [loading, setLoading] = useState(false)
  const [msgsLoading, setMsgsLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [width, setWidth] = useState(0)

  const scrollToRef = useRef(null)
  const personQuery = useGetPerson({ authenticated: true })
  const { chatUid } = useParams()
  const el = document?.getElementById("messages-list")
  useEffect(() => {
    setWidth(`calc(${el?.clientWidth}px - 1.75rem * 2)`)

    function handleResize() {
      setWidth(`calc(${el?.clientWidth}px - 1.75rem * 2)`)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [el])

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "channels", chatUid), (doc) => {
      setMessages(doc.data().messages)
      setMsgsLoading(false)
    })
    return () => unsub()
  }, [])

  useEffect(() => {
    scrollToRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [messages])

  const formatMessages = (messages) => {
    const messagesToReturn = []
    if (messages.message && messages.message !== "") {
      messagesToReturn.push({
        content: {
          text: messages.message,
        },
        createdAt: dayjs().format("DD/MM/YYYY"),
        type: "text",
        id: personQuery.data?.uid,
        sender: {
          imageUrl: personQuery.data?.imageUrl,
          name: `${personQuery.data?.firstName} ${personQuery.data?.lastName}` || "User",
          org: personQuery.data?.companyPerson?.company?.name || "",
          uid: personQuery.data?.uid,
        },
      })
    }

    if (messages?.docs?.fileList?.length) {
      messages?.docs?.fileList.forEach((file) => {
        messagesToReturn.push({
          content: {
            text: file.name || "file",
            url: file.imageUrl,
            size: file.size,
          },
          createdAt: dayjs().format("DD/MM/YYYY"),
          type: "media",
          format: file.type,
          id: personQuery.data?.uid,
          sender: {
            imageUrl: personQuery.data?.imageUrl,
            name: `${personQuery.data?.firstName} ${personQuery.data?.lastName}` || "User",
            org: personQuery.data?.companyPerson?.company?.name || "",
            uid: personQuery.data?.uid,
          },
        })
      })
    }

    return messagesToReturn
  }

  async function sendMessages(messages) {
    setLoading(true)
    try {
      const formattedMessages = formatMessages(messages)

      await updateDoc(doc(db, "channels", chatUid), {
        messages: arrayUnion(...formattedMessages),
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Alert.error(error.message)
    }
  }

  const props = {
    name: "file",
    multiple: true,
    listType: "picture",
    customRequest: (req) => nonHookUploader({ ...req, mutator }),
    maxCount: 4,
  }

  return (
    <Content className="chat--wrapper">
      <Row span={24}>
        <Col xs={24} id="messages-list" className="chat--content">
          <Typography.Title level={1}>{channels?.find((x) => x.id === chatUid)?.title}</Typography.Title>
          {!personQuery.isLoading && personQuery.data?.companyPerson.company.tenantId.includes("Thungela") ? (
            <ChatPolicy
              title="Anglo American Group IM Facilities Usage Stipulation"
              message="Access to Anglo American Group IM facilities is reserved exclusively for authorised users who agree to comply with Anglo American's Group IM policies which are available on Eureka!. By using this application, you are explicitly agreeing to comply with these policies. For further information or assistance on any Information Management matters, please contact the Anglo American IT Service Desk."
            />
          ) : null}
          <ul className="message--list">
            {!msgsLoading || !personQuery.isLoading ? (
              <>
                {messages?.map((msg, i) => {
                  switch (msg.type) {
                    case "text":
                      return (
                        <MessageText key={i} index={i} message={msg} messageList={messages} personQuery={personQuery} />
                      )
                    case "media":
                      return (
                        <MessageImage
                          key={i}
                          index={i}
                          message={msg}
                          messageList={messages}
                          personQuery={personQuery}
                        />
                      )
                    default:
                      return (
                        <MessageText key={i} index={i} message={msg} messageList={messages} personQuery={personQuery} />
                      )
                  }
                })}
              </>
            ) : (
              <Skeleton active />
            )}
            <div ref={scrollToRef} />
          </ul>
        </Col>
        {/* <Col xs={0} xl={6} className="chat--details">
          <Typography.Title level={5}>Members</Typography.Title>
          {channels
            .find((x) => x.id === chatUid)
            ?.members.map((member) => (
              <Comment
                key={member.uid}
                author="Member"
                avatar={<Avatar src={member.imageUrl} />}
                content={member.name}
              />
            ))}
        </Col> */}
        <Col
          span={24}
          style={{
            width,
          }}
          className="filter footer--input"
        >
          <Form
            form={form}
            initialValues={{
              message: "",
            }}
            name="message_form"
            className="filter__input"
            onFinish={async (values) => {
              await sendMessages(values)
            }}
          >
            <Modal
              visible={visible}
              className="modal"
              onCancel={() => setVisible(false)}
              onOk={() => setVisible(false)}
            >
              <PageHeader
                className="site-page-header"
                onBack={() => setVisible(false)}
                title="Upload"
                style={{ marginBottom: "1rem" }}
              />

              <Form.Item name="docs">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag files to this area to upload. (Max: 4)</p>
                  <p className="ant-upload-hint">
                    Single or bulk uploading is supported. Please ensure your file sizes are kept to a minimun.
                  </p>
                </Dragger>
              </Form.Item>
            </Modal>
            <Form.Item style={{ margin: 0, width: "100%" }} name="message">
              <Input bordered={false} placeholder="Your message..." prefix={<MessageOutlined />} />
            </Form.Item>
            <Space>
              <Button
                loading={loading}
                htmlType="button"
                onClick={() => setVisible(true)}
                type="ghost"
                icon={<UploadOutlined />}
              />
              <Button loading={loading} htmlType="submit" type="primary" icon={<SendOutlined />} />
            </Space>
          </Form>
        </Col>
      </Row>
    </Content>
  )
}

import { Button, Divider, Modal, Skeleton, Space, Tag, Tree, Typography } from "antd"
import dayjs from "dayjs"
import React, { useState, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"
import relativeTime from "dayjs/plugin/relativeTime"
import { ClockCircleOutlined, CloseOutlined, StopOutlined } from "@ant-design/icons"
import { TagFactory } from "../../components/TagFactory"
import { TeamModalTitle } from "../../components/TeamModalTitle"
import { useGetPublication } from "../../network/usePublications"
import { useGetSingleTeam } from "../../network/useTeams"

dayjs.extend(relativeTime)

export function TeamEnrolmentModal() {
  const [visible, setVisible] = useState(true)

  const { enrolmentUid, teamUid, personUid } = useParams()
  const { replace } = useHistory()
  const teamQuery = useGetSingleTeam({ teamUid })
  const enrolmentsQuery = useGetPublication({
    enrolmentUid,
    filters: {
      companyPersonUids: teamQuery?.data?.members
        ?.filter((y) => y?.member?.personUid === personUid)
        ?.map((x) => x?.member?.uid),
    },
  })

  function closeModal() {
    setVisible(false)
    setTimeout(() => replace(`/teams/${teamUid}`), 500)
  }

  const handleExpiring = (learner) => {
    if (learner?.currentState !== "completed") {
      return ""
    }
    if (!learner?.completionValidUntil) {
      return ""
    }
    const difference = dayjs(learner?.completionValidUntil).diff(dayjs(), "days")

    if (difference > 0) {
      if (difference > 365) {
        return (
          <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
            Expires in over a year
          </TagFactory>
        )
      } else {
        return (
          <TagFactory icon={<ClockCircleOutlined />} status="Expiring">
            Expires {dayjs().to(learner?.completionValidUntil)}
          </TagFactory>
        )
      }
    } else if (difference < 0) {
      return (
        <TagFactory icon={<StopOutlined />} status="Expired">
          Expired {dayjs(learner?.completionValidUntil).from(dayjs())}
        </TagFactory>
      )
    }
  }

  const treeData = useMemo(() => {
    if (!enrolmentsQuery?.isLoading && enrolmentsQuery?.data) {
      const data = enrolmentsQuery?.data?.enrolments?.map((learner) => {
        return {
          key: learner?.uid,
          title: (
            <TeamModalTitle
              title={`${learner?.person?.firstName} ${learner?.person?.lastName}`}
              tag={
                <Space>
                  {handleExpiring(learner)}
                  <TagFactory status={learner?.currentState}>
                    {learner?.currentState.replace("-", " ").toSentenceCase()}
                  </TagFactory>
                </Space>
              }
              imageUrl={learner?.person?.imageUrl}
            />
          ),
          children: enrolmentsQuery?.data?.material?.map((module) => {
            return {
              key: `${learner?.uid}-${module?.uid}`,
              title: (
                <TeamModalTitle
                  title={module?.moduleVersion?.module.translations["1"].title}
                  imageUrl={module?.moduleVersion?.module?.featureImageUrl}
                />
              ),
              children: module?.learning?.map((learning) => {
                return {
                  key: `${learner?.uid}-${module?.uid}-${learning?.uid}`,
                  title: (
                    <TeamModalTitle title={learning?.translations["1"].title}>
                      <Space>
                        <Tag color="green">{learning.duration || "No Duration"}</Tag>
                        <Tag color="blue">
                          {learning.kind} {learning.type ? `: ${learning.type}` : ""}
                        </Tag>
                      </Space>
                    </TeamModalTitle>
                  ),
                }
              }),
            }
          }),
        }
      })
      return data
    }

    if (enrolmentsQuery.isError) closeModal()
  }, [enrolmentsQuery?.isLoading, enrolmentsQuery.data])

  return (
    <Modal
      visible={visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className="modal modal--noPadding"
      onCancel={closeModal}
      closeIcon={
        <Button type="primary" size="small">
          <CloseOutlined />
        </Button>
      }
    >
      <div
        className="modal__image"
        style={{
          backgroundImage: enrolmentsQuery?.data?.content?.featureImageUrl
            ? `url('${enrolmentsQuery?.data?.content?.featureImageUrl}')`
            : "linear-gradient(to right, var(--ant-primary-color) , var(--ant-primary-color-active))",
        }}
      />
      <div className="modal__body">
        <Typography.Title level={2}>{enrolmentsQuery?.data?.content?.title}</Typography.Title>
        <Typography.Paragraph className="desc" ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: enrolmentsQuery?.data?.content?.descriptionAsHTML,
            }}
          />
        </Typography.Paragraph>

        <Divider />
        <Typography.Title level={3}>Learners</Typography.Title>
        {!enrolmentsQuery?.isLoading ? (
          <Tree.DirectoryTree className="dropdown--tree" showIcon={false} treeData={treeData} />
        ) : (
          <Skeleton />
        )}
      </div>
    </Modal>
  )
}

import React, { useEffect, useRef, useState } from "react"
import { makeId } from "../hooks/uuid"

// For reference - > https://www.youtube.com/watch?v=jfYWwQrtzzY
export const Draggable = React.memo(
  ({
    index,
    question = "",
    children,
    className = "",
    direction = "vertical",
    onChange,
    dropzone = false,
  }) => {
    const [empty, setEmpty] = useState(Array.isArray(children))
    const containerRef = useRef(null)

    const dragstart = (e) => {
      e.dataTransfer.setData("text/plain", e.currentTarget.dataset.uid)
      e.currentTarget.classList.add("dragging")
      // containerRef.current.classList.add('dragging')
    }

    const dragend = (e) => {
      e.preventDefault()
      setEmpty(!Array.from(containerRef.current?.children)?.length)
      e.currentTarget.classList.remove("dragging")
      if (onChange) {
        onChange(
          question,
          Array.from(containerRef.current.children).map((answer) => {
            return {
              uid: answer.dataset.uid,
              correct: answer.dataset.correct,
              title: answer.innerHTML,
            }
          }),
          index
        )
        // innerHTML
      }
    }

    const dragover = (e) => {
      e.preventDefault()
      const afterEl = getDragAfterEl(
        containerRef.current,
        direction === "vertical" ? e.clientY : e.clientX
      )
      const draggable = document.querySelector(".dragging")
      if (!afterEl) {
        containerRef.current.appendChild(draggable)
      } else {
        containerRef.current.insertBefore(draggable, afterEl)
      }
    }

    const drop = (e) => {
      e.preventDefault()
    }

    const getDragAfterEl = (container, coord) => {
      const draggableEls = [
        ...container.querySelectorAll(".draggable:not(.dragging"),
      ]
      return draggableEls.reduce(
        (closest, child) => {
          const box = child.getBoundingClientRect()
          let offset
          if (direction === "vertical")
            offset = coord - box.top - box.height / 2
          else offset = coord - box.left - box.width / 2
          if (offset < 0 && offset > closest.offset)
            return { offset, element: child }
          return closest
        },
        { offset: Number.NEGATIVE_INFINITY }
      )?.element
    }

    useEffect(() => {
      const draggables = document.querySelectorAll(".draggable")
      draggables.forEach((child) => {
        child.addEventListener("dragstart", dragstart)
        child.addEventListener("dragend", dragend)
      })
      containerRef.current.addEventListener("dragover", dragover)
      containerRef.current.addEventListener("drop", drop)

      return () =>
        draggables.forEach((child) => {
          child.removeEventListener("dragstart", dragstart)
          child.removeEventListener("dragend", dragend)
        })
    }, [containerRef.current])

    useEffect(() => {
      setEmpty(!React.Children.toArray(children).length)
    }, [])

    return (
      <div
        ref={containerRef}
        className={`${className} drag-container ${empty ? "empty" : ""}`}
      >
        {children && (
          <>
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                id: makeId(6),
                className: `${child.props.className} draggable`,
                draggable: true,
              })
            )}
          </>
        )}
      </div>
    )
  }
)

import { message } from "antd"
import axios from "axios"
import { getAuth, getIdToken } from "firebase/auth"
import { useMutation, useQuery, useQueryClient } from "react-query"

export const useMedia = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body) =>
      axios
        .post(`${process.env.REACT_APP_API_URL}/media/upload`, body.data, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data),
    {
      onError: (error) => {
        message.error(`Failed to upload: ${error.response.data.message}`)
      },
    }
  )
}
export const useGet64Query = ({ url }) => {
  const params = new URLSearchParams({ url }).toString()
  const queryClient = useQueryClient()
  return useQuery(
    [url],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/media/url-to-base64${params.length ? `?${params}` : ""}`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      enabled: !!url,
      staleTime: 1000 * 60 * 30,
      onError: (error) => {
        message.error(`Failed to fetch course: ${error.response.data.message}`)
      },
    }
  )
}
export const useGetMedia = ({ type, typeUid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["media", type, typeUid],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/learn/${type}/${typeUid}/resources`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      enabled: !!(type && typeUid),
      staleTime: 1000 * 60 * 30,
      onError: (error) => {
        message.error(`Failed to fetch course: ${error.response.data.message}`)
      },
    }
  )
}
export const useGetCertificate = ({ enrolmentUid }) => {
  const queryClient = useQueryClient()
  return useQuery(
    ["certificate", enrolmentUid],
    async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/learn/enrolments/${enrolmentUid}/certificate?base64=true`, {
          headers: {
            Authorization: `Bearer ${await getIdToken(getAuth().currentUser)}`,
            "X-Tenant-Id": queryClient.getQueryData("token").claims.firebase.tenant,
          },
        })
        .then((res) => res.data.data),
    {
      enabled: !!enrolmentUid,
      staleTime: 1000 * 60 * 30,
      onError: (error) => {
        message.error(`Failed to fetch course: ${error.response.data.message}`)
      },
    }
  )
}

import { useAuthSignInWithCustomToken } from "@react-query-firebase/auth"
import { Button, Col, Form, Input, Row, Spin, Typography, message } from "antd"
import React, { useEffect, useReducer } from "react"
import { useHistory } from "react-router-dom"
import { auth } from "../../firebase"
import { useHandleTenantId } from "../../hooks/useHandleTenantId"
import { useQueryParam } from "../../hooks/useQueryParam"
import { useGetEnrolment } from "../../network/useEnrolment"

function signInReducer(state, action) {
  const { type, payload, field } = action
  switch (type) {
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}
export const Classroom = () => {
  const queryParam = useQueryParam()
  auth.tenantId = queryParam.get("tenantId")
  const signInWithCustomToken = useAuthSignInWithCustomToken(auth)
  const SIGN_IN_STATE = {
    type: null,
    tenantId: queryParam.get("tenantId"),
    reset: false,
    loading: false,
    authenticated: false,
    returnUrl: queryParam.get("returnUrl"),
    resolver: false,
    support: false,
    enrolmentUid: null,
  }

  const history = useHistory()
  const [state, dispatch] = useReducer(signInReducer, SIGN_IN_STATE)
  const DEFAULT_BG =
    "https://images.unsplash.com/photo-1612999805994-1eabaef7bb88?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
  const { tenant, isLoading, isError, awaitTenant } = useHandleTenantId(state, dispatch)

  const enrolment = useGetEnrolment({ uid: state?.enrolmentUid })

  useEffect(() => {
    if (enrolment.data && !enrolment.isLoading) {
      const eData = enrolment.data
      history.push(
        `/enrolments/${eData.uid}/modules/${eData.publication.material[0].uid}/learning/${eData.publication.material[0].learning[0].uid}/${eData.publication.type}s`
      )
    }
  }, [enrolment.data, enrolment.isLoading])

  return (
    <div
      className="auth"
      style={{
        backgroundImage: `url(${tenant?.featureImageUrl || DEFAULT_BG})`,
      }}
    >
      <Row>
        <Col xs={24} md={12} lg={10} xl={8} className="auth__pannel">
          <Form
            name="login"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={async (values) => {
              dispatch({ type: "field", field: "loading", payload: true })

              const response = await fetch(`${process.env.REACT_APP_API_URL}/classroom/auth`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                  "Content-Type": "application/json",
                  "X-Tenant-Id": queryParam.get("tenantId"),
                },
              })

              const data = await response.json()
              if (!response.ok) {
                window.localStorage.clear()
                message.warning(data.message)
              }
              console.log("data::: ", data)
              const authWithCustomToken = await signInWithCustomToken.mutateAsync(data.token)

              window.localStorage.setItem("classroomPersonUid", data.personUid)
              window.localStorage.setItem("classroomId", values.id)
              window.localStorage.setItem("classroom", true)
              window.localStorage.setItem("classroomEnrolmentUid", data.enrolmentUid)
              window.localStorage.setItem("classroomOnly", true)
              window.localStorage.setItem("tenantId", queryParam.get("tenantId"))
              dispatch({ type: "field", field: "enrolmentUid", payload: data.enrolmentUid })
            }}
          >
            <>
              {!awaitTenant && !isLoading && !isError ? (
                <>
                  {tenant?.imageUrl && <img src={tenant?.imageUrl} alt="company" className="companyLogo" />}
                  <Typography.Title style={{ color: "white" }} level={3}>
                    Classroom
                  </Typography.Title>
                  <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>
                    Join your classroom with your ID and Code
                  </Typography.Text>
                  <Form.Item name="id" rules={[{ required: true, message: "Please enter your classroom ID" }]}>
                    <Input type="id" label="Classroom ID" placeholder="Classroom ID" />
                  </Form.Item>
                  <Form.Item name="code" rules={[{ required: true, message: "Please enter your classroom code" }]}>
                    <Input label="Classroom Code" placeholder="Classroom Code" />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={state?.loading}
                    block
                    style={{ marginBottom: "1rem" }}
                  >
                    Go to classroom
                  </Button>
                  <Button href="/auth/sign-in" type="link" htmlType="button" block style={{ marginBottom: "1rem" }}>
                    Sign in
                  </Button>
                </>
              ) : awaitTenant && isLoading ? (
                <Spin tip="Fetching your authentication options" />
              ) : (
                awaitTenant &&
                isError && (
                  <>
                    <Typography.Text style={{ color: "white", marginBottom: "2rem" }}>
                      We are unable to fetch you authentication options
                    </Typography.Text>
                    <a href={`/auth/sign-in?${state?.tenantId ? `?tenantId=${state?.tenantId}` : ""}`}>
                      <Button block type="primary">
                        Please refresh
                      </Button>
                    </a>
                  </>
                )
              )}
            </>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

import React from "react"

export const BoldText = ({ text }) => {
  const renderTextWithBold = (text) => {
    const parts = text?.split("**")
    if (!parts) return text
    return parts?.map((part, index) => {
      if (index % 2 === 0) {
        return part
      } else {
        return <strong key={index}>{part}</strong>
      }
    })
  }
  return renderTextWithBold(text)
}

import { doc, onSnapshot } from "firebase/firestore"
import React from "react"
import db from "../firebase"

export const useAwaitScorm = (docId) => {
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    // eslint-disable-next-line
    if (!docId) return
    setLoading(true)
    const unsub = onSnapshot(
      doc(db, "backgroundProcesses", docId.toString()),
      (doc) => {
        setData(doc.data())
        if (doc.data()?.synced) {
          setLoading(false)
        }
      }
    )
    return () => unsub()
  }, [docId])

  return { data, loading }
}

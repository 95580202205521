import { SearchOutlined, UserOutlined } from "@ant-design/icons"
import {
  Avatar,
  Card,
  Col,
  Collapse,
  Grid,
  Input,
  List,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import { collection, getDocs, query, where } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import db from "../firebase"
import { tabFactory } from "../helpers/tabsFactory"
import uuidv4 from "../hooks/uuid"
import { useGetPerson } from "../network/usePerson"
import { useGetTeamActivity } from "../network/useTeams"
import { ActivityAssessmentReset } from "./ActivityAssessmentReset"
import { BookingCard } from "./cards/BookingCard"
import { TrainingBookingCard } from "./cards/TrainingBookingCard"
import { TagFactory } from "./TagFactory"
const { useBreakpoint } = Grid

dayjs.extend(LocalizedFormat)

function getPersonUids(team) {
  const uids = []
  for (let i = 0; i < team.members.length; i++) {
    uids.push(team.members[i].member.person.uid)
  }
  return uids
}

export const TeamActivityPane = ({ team, isAdmin }) => {
  const { teamUid } = useParams()
  const [activity, setActivity] = useState([])
  const [status, setStatus] = useState(tabFactory("status").get() || "All")
  const [segment, setSegment] = useState(tabFactory("segment").get() || "All")
  const [q, setQ] = useState("")
  const [refetch, setRefetch] = useState(false)
  const personQuery = useGetPerson({ authenticated: true })
  const [loading, setLoading] = useState(false)
  const activityQuery = useGetTeamActivity({
    teamUid,
  })
  const { t } = useTranslation()

  const vertical = ["xs", "sm", "md"]
  const screens = useBreakpoint()
  const direction = useMemo(() => {
    const arr = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => screen[0])

    return vertical.includes(arr?.[arr.length - 1]) ? "column" : "row"
  }, [screens])

  useEffect(() => {
    if (!activityQuery.isLoading && activityQuery.data) {
      const data = []
      Object.keys(activityQuery?.data)?.forEach((key) => {
        activityQuery?.data?.[key]?.forEach((item) => {
          data?.push({
            ...item,
            type: key,
            renderAs: "ActivityDropdown",
          })
        })
      })

      async function getBookings() {
        if (isAdmin) {
          setLoading(true)
          const q = query(collection(db, "trainingRequests"), where("teamUid", "==", teamUid))
          const snap = await getDocs(q)

          snap.forEach((doc) => {
            data.push({
              ...doc?.data(),
              renderAs: "Booking",
              docId: doc.id,
            })
          })
        }
        setActivity(data)
        setLoading(false)
      }
      getBookings()
    }
    return () => {}
  }, [activityQuery.isLoading, activityQuery.data, refetch])

  const segmentMap = useMemo(() => {
    let obj = {
      All: null,
      Modules: {
        key: "type",
        value: "module",
      },
      Assessments: {
        key: "type",
        value: "assessment",
      },
      Assignments: {
        key: "type",
        value: "assignment",
      },
    }
    if (isAdmin) {
      obj.Bookings = {
        key: "renderAs",
        value: "Booking",
        options: [
          { value: { key: "status", value: "All" }, label: "All" },
          { value: { key: "status", value: "pending" }, label: "Pending" },
          { value: { key: "status", value: "declined" }, label: "Declined" },
          { value: { key: "status", value: "requested" }, label: "Requested" },
          { value: { key: "status", value: "approved" }, label: "Approved" },
        ],
      }
    }
    return obj
  }, [isAdmin])

  const memoizedData = useMemo(() => {
    if (activity) {
      return !segmentMap[segment]
        ? activity.filter(
            (x) =>
              x?.title?.toLowerCase()?.includes(q?.toLowerCase()) ||
              x?.training?.title?.toLowerCase()?.includes(q?.toLowerCase()) ||
              x?.training?.courseName?.toLowerCase()?.includes(q?.toLowerCase())
          )
        : activity.filter((item) => {
            const segmentKey = segmentMap[segment].key
            const segmentValue = segmentMap[segment].value

            const statusFilter = status !== "All" && segment === "Bookings"
            const qFilter =
              item?.title?.toLowerCase()?.includes(q?.toLowerCase()) ||
              item?.training?.title?.toLowerCase()?.includes(q?.toLowerCase()) ||
              item?.training?.courseName?.toLowerCase()?.includes(q?.toLowerCase())
            return (
              Object.prototype.hasOwnProperty.call(item, segmentKey) &&
              item[segmentKey] === segmentValue &&
              (!statusFilter || item.status === status) &&
              qFilter
            )
          })
    } else {
      return []
    }
  }, [segment, q, activity])

  console.log("memo::: ", memoizedData)

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <div className="filter">
            <div name="keyword" className="filter__input filter__input--noMargin">
              <Input
                value={q}
                onChange={(e) => setQ(e.target.value)}
                bordered={false}
                placeholder={t("Search") + "..."}
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
        </Col>
        <Col
          span={24}
          style={{ display: "flex", flexDirection: direction, width: "100%", alignItems: "center", gap: "1rem" }}
        >
          <Segmented
            style={{ flexGrow: 1, width: direction === "column" ? "100%" : "fitContent", marginBottom: "1rem" }}
            options={Object.keys(segmentMap)}
            block
            value={tabFactory("segment").get() || "All"}
            onChange={(value) => {
              tabFactory("segment").onChange(value)
              tabFactory("status").onChange("All")
              setSegment(value)
            }}
          />
          {segmentMap[segment]?.options ? (
            <Select
              style={{
                width: direction === "column" ? "100%" : "25%",
                marginBottom: "1rem",
              }}
              value={tabFactory("status").get() || null}
              onChange={(value) => {
                const parsedValue = JSON.parse(value)
                tabFactory(parsedValue?.key).onChange(parsedValue.value)
                setStatus(parsedValue.value)
              }}
              allowClear
              placeholder="Filter by status"
            >
              {segmentMap[segment]?.options?.map((opt) => (
                <Select.Option key={opt.label} value={JSON.stringify(opt.value)}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          ) : null}
        </Col>
        <Col span={24} style={{ paddingBottom: "7rem" }}>
          {loading || activityQuery?.isLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "3rem 0",
              }}
            >
              <Spin style={{ margin: "0 auto" }} tip="Fetching team activity" />
            </div>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={memoizedData}
              size="large"
              renderItem={(item) =>
                item?.renderAs === "ActivityDropdown" ? (
                  <ActivityDropdown item={item} isAdmin={isAdmin} />
                ) : item?.isNonExistantTraining ? (
                  <TrainingBookingCard
                    isAdmin={isAdmin}
                    item={item}
                    person={personQuery?.data}
                    setRefetch={setRefetch}
                  />
                ) : (
                  <BookingCard isAdmin={isAdmin} item={item} person={personQuery?.data} setRefetch={setRefetch} />
                )
              }
            />
          )}
        </Col>
      </Row>
    </>
  )
}

const ActivityDropdown = ({ item, isAdmin }) => {
  const vertical = ["xs", "sm", "md"]
  const screens = useBreakpoint()
  const personQuery = useGetPerson({ authenticated: true })
  const direction = useMemo(() => {
    const arr = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => screen[0])

    return vertical.includes(arr?.[arr.length - 1]) ? "vertical" : "horizontal"
  }, [screens])

  return (
    <List.Item
      style={{
        borderRadius: "0.625rem",
        marginBottom: "1rem",
      }}
    >
      <Collapse accordion className="collapse-center no-padding" ghost style={{ width: "100%" }}>
        <Collapse.Panel
          extra={[
            <Avatar.Group key={uuidv4()} maxCount={2}>
              {item?.people.map((person, i) => {
                return (
                  <Tooltip
                    key={`${item?.uid}-${person?.uid}`}
                    title={`${person?.person?.firstName} ${person?.person?.lastName || ""}`}
                  >
                    {person?.person?.imageUrl ? (
                      <Avatar src={person?.person?.imageUrl} />
                    ) : (
                      <Avatar icon={<UserOutlined />} />
                    )}
                  </Tooltip>
                )
              })}
            </Avatar.Group>,
          ]}
          header={
            <div>
              <Typography.Paragraph type="secondary" style={{ margin: 0, textTransform: "capitalize" }}>
                {item?.type}
              </Typography.Paragraph>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {item?.title}
              </Typography.Title>
            </div>
          }
          key={uuidv4()}
        >
          <Card size="small">
            <Timeline style={{ margin: "1.5rem 1.5rem 0 1.5rem" }}>
              {item?.people?.map((person, i) => (
                <Timeline.Item key={`${i}-${person?.uid}`} style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Space direction={direction}>
                      {person?.person?.imageUrl ? (
                        <Avatar src={person?.person?.imageUrl} />
                      ) : (
                        <Avatar icon={<UserOutlined />} />
                      )}
                      <Typography.Paragraph type="secondary" style={{ margin: 0 }}>
                        {person?.person?.firstName || ""} {person?.person?.lastName || ""}
                      </Typography.Paragraph>

                      {isAdmin || person?.person?.uid === personQuery?.data?.uid ? (
                        <>
                          {person?.status ? (
                            <TagFactory status={person?.status} style={{ textTransform: "capitalize" }}>
                              {person?.status}: {dayjs(person?.createdAt).format("ll")}
                            </TagFactory>
                          ) : null}
                        </>
                      ) : (
                        <Tag color="blue" style={{ textTransform: "capitalize" }}>
                          Submitted: {dayjs(person?.createdAt).format("ll")}
                        </Tag>
                      )}

                      {isAdmin && item?.type === "assessment" && person?.context?.submissions ? (
                        <>
                          <TagFactory>
                            Score:{" "}
                            {
                              person?.context?.submissions?.[person?.context?.submissions?.length - 1]
                                ?.resultAsPercentage
                            }
                            %
                          </TagFactory>
                          <TagFactory status="requested">
                            Submissions: {person?.context?.submissions?.length}
                          </TagFactory>
                        </>
                      ) : null}
                    </Space>
                    {isAdmin && person?.status === "failed" && item?.type === "assessment" ? (
                      <ActivityAssessmentReset item={item} person={person} />
                    ) : null}
                  </div>
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
        </Collapse.Panel>
      </Collapse>
    </List.Item>
  )
}

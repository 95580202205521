export const nonHookUploader = async ({
  onError,
  onSuccess,
  file,
  mutator,
}) => {
  try {
    const formData = new FormData()
    formData.append("file", file)
    const upload = await mutator.mutateAsync({
      data: formData,
    })
    if (upload?.data?.url) {
      file.imageUrl = upload?.data?.url
      onSuccess(null, upload?.data?.url)
    }
  } catch (error) {
    onError(error)
  }
}

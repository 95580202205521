import { UserOutlined } from "@ant-design/icons"
import { Avatar, Card, Space, Table, Typography } from "antd"
import { useMemo } from "react"
import { useGetTeamLeaderboard } from "../network/useTeams"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { useTranslation } from "react-i18next"

export const TeamLeaderboardPane = ({ team }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const columns = [
    {
      title: t("Position"),
      dataIndex: "position",
      key: "position",
    },
    {
      title: t("Name"),
      dataIndex: "person",
      key: "person",
      render: (person) => (
        <Space>
          {width > 992 ? (
            <>{person?.imageUrl ? <Avatar src={person?.imageUrl} /> : <Avatar icon={<UserOutlined />} />}</>
          ) : null}
          <Typography.Text>
            {person?.firstName} {person?.lastName}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: t("avgScore"),
      dataIndex: "average",
      key: "average",
    },
    {
      title: t("noModulesCompleted"),
      // title: width > 992 ? "No. of Modules Completed" : "Mods.",
      dataIndex: "moduleCount",
      key: "moduleCount",
    },
  ]
  const teamLeaderboardQuery = useGetTeamLeaderboard({ teamUid: team.uid })

  const dataSource = useMemo(() => {
    if (teamLeaderboardQuery.data && !teamLeaderboardQuery.isLoading) {
      return teamLeaderboardQuery?.data?.map((person, i) => ({
        key: person.person.uid,
        position: i + 1,
        person: person?.person,
        average: person?.average?.averageResult ?? 0,
        moduleCount: person?.moduleCount,
      }))
    }
    return []
  }, [teamLeaderboardQuery.data, teamLeaderboardQuery.isLoading])

  const getPodiumBadge = (index) => {
    if (index === 0) return "/1st_place.png"
    if (index === 1) return "/2nd_place.png"
    if (index === 2) return "/3rd_place.png"
    return ""
  }

  return (
    <div>
      {!teamLeaderboardQuery.isLoading ? (
        <div className="podium">
          {dataSource.slice(0, 3).map((item, index) => (
            <div className={`podium__item podium__item--${index + 1}`} key={item.key}>
              <div className="podium__header">
                <p className="podium__header__standing">
                  {index === 0 && t("1stPlace")}
                  {index === 1 && t("2ndPlace")}
                  {index === 2 && t("3rdPlace")}
                  {/* {`${index + 1}${
                  index === 0 ? "st" : index === 1 ? "nd" : "rd"
                } Place`} */}
                </p>
                <h2 className="podium__header__person">{`${item.person.firstName} ${item.person.lastName}`}</h2>
                <img src={getPodiumBadge(index)} alt="podium badge" />
              </div>
              <div className="podium__body">
                <div className="podium__overlay" />
                <div className="podium__meter">
                  <p className="podium__badge">{`${item.average}% ${t("avgScore")}`}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <Card size="small" style={{ marginTop: "2rem", marginBottom: "10rem", paddingBottom: "2rem" }}>
        <Table
          title={() => <Typography.Title level={3}>{team?.name} Leaderboard</Typography.Title>}
          dataSource={dataSource}
          loading={teamLeaderboardQuery.isLoading}
          columns={columns}
          pagination={false}
        />
      </Card>
    </div>
  )
}
